import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Checkbox from "components/Checkbox";
import ArrowHeading from "components/Icons/Arrowheading";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import { formatPrice } from "helpers/utility";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import Collapsible from "react-collapsible";
import { Trans, withTranslation } from "react-i18next";
import { TucanoModels } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";
class SubscriptionItem extends Component {
  state = {
    objectId: null,
    name: "",
    price: null,
    recurrenceUnit: null,
    recurrencePeriod: 0,
    imgType: null,
    showSynopsis: false,
    checked: false,
    setupFees: 0,
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  onClickOffer() {
    const { subscriptionItem } = this.props;
    const { checked } = this.state;

    if (this.props.onActionButtonClick) {
      this.props.onActionButtonClick(this, subscriptionItem, !checked);
    }

    this.setState({ checked: !checked });
  }

  componentDidMount() {
    const { subscriptionItem, additionalInfoItem, selectedByDefault } =
      this.props;

    let objectId = null;
    let name = "";
    let price = null;
    let recurrenceUnit = null;
    let recurrencePeriod = 0;
    let imgType = null;
    let showSynopsis = false;
    let setupFees = 0;

    if (subscriptionItem) {
      if (
        subscriptionItem instanceof TucanoModels.OptionAvailability ||
        subscriptionItem instanceof TucanoModels.Option
      ) {
        objectId = subscriptionItem.getIdOption();
        name = subscriptionItem.getName();
        price = subscriptionItem.getPriceWithVat();
        setupFees = subscriptionItem.getSetupPrice();
        recurrenceUnit = subscriptionItem.getRecurrenceUnit();
        recurrencePeriod = subscriptionItem.getRecurrencePeriod();
        imgType = consts.imageTypes.subscription;
        showSynopsis = true;
      }
      if (subscriptionItem instanceof TucanoModels.Asset) {
        objectId = subscriptionItem.getId();
        name = subscriptionItem.getTitle();
        price = additionalInfoItem.price;
        imgType = consts.imageTypes.movie_episode_cover;
        showSynopsis = false;
      }
      this.setState({
        objectId: objectId,
        name: name,
        price: price,
        recurrenceUnit: recurrenceUnit,
        recurrencePeriod: recurrencePeriod,
        imgType: imgType,
        showSynopsis: showSynopsis,
        checked: selectedByDefault,
        setupFees: setupFees,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedByDefault !== this.props.selectedByDefault) {
      this.setState({
        checked: this.props.selectedByDefault,
      });
    }
  }

  render() {
    const {
      subscriptionItem,
      optionValidity,
      type,
      onActionButtonClick,
      selectionType,
      rootClassName,
      voucherOnly,
      t,
    } = this.props;

    const {
      objectId,
      name,
      price,
      imgType,
      showSynopsis,
      checked,
      recurrenceUnit,
      recurrencePeriod,
      setupFees,
    } = this.state;

    const statusEnum = {
      ACTIVE: 10,
      PENDING: 20,
      SUSPEND: 30,
      TERMINATED: 40,
      CANCEL: 50,
      ORDERED: 60,
      DISBARRED: 70,
      TERMINATED_D: 80,
    };

    const statusLabelEnum = {
      10: t("Active"),
      20: t("Pending"),
      30: t("Suspended"),
      40: t("Terminated"),
      50: t("Canceled"),
      60: t("Ordered"),
      70: t("Disbarred"),
      80: t("Terminated"),
    };

    let status = optionValidity ? statusLabelEnum[optionValidity.statusId] : "";
    const statusId = optionValidity ? optionValidity.statusId : null;
    //let validFrom = subscriptionItem.getValidFrom() * 1000;
    const validTo =
      subscriptionItem instanceof TucanoModels.OptionAvailability ||
      subscriptionItem instanceof TucanoModels.Option
        ? subscriptionItem.getValidTo() * 1000
        : null;
    const now = new Date().getTime();
    //let formattedValidFrom = subscriptionItem.getFormattedValidFrom();
    const formattedValidTo =
      subscriptionItem instanceof TucanoModels.OptionAvailability ||
      subscriptionItem instanceof TucanoModels.Option
        ? `${moment(validTo).format(consts.subscriptionValidityDateFormat)}`
        : null;

    if (statusId === statusEnum.TERMINATED) {
      status = statusLabelEnum[statusEnum.TERMINATED];
    }
    const unsubscribeButton =
      statusId === statusEnum.ACTIVE ? t("Unsubscribe") : t("Cancel");

    return (
      <Fragment>
        {objectId && (
          <div
            className={classnames(style.offerContainer, rootClassName, {
              [style.cursor]:
                selectionType === SelectionType.TEXT ||
                selectionType === SelectionType.CHECKBOX,
              [style.checked]: checked,
            })}
            onClick={
              selectionType === SelectionType.CHECKBOX
                ? this.onClickOffer.bind(this)
                : selectionType === SelectionType.TEXT
                ? this.props.onActionButtonClick.bind(
                    this,
                    subscriptionItem,
                    "subscribe"
                  )
                : null
            }
          >
            <div
              className={style.imageContainer}
              style={{
                minHeight: responsiveConf[2].subscriptionItem.height,
                minWidth: responsiveConf[2].subscriptionItem.width,
              }}
            >
              <Image
                src={`${consts.image_hostname}${routes.imgdata}?type=${imgType}&objectId=${objectId}&ratio=169&format_w=1280&format_h=720&languageId=${consts.languageId}`}
                rootClassName={style.image}
                width={responsiveConf[2].subscriptionItem.width}
                height={responsiveConf[2].subscriptionItem.height}
                ratio={ImageRatio.COVER}
                placeholder={PlaceholderType.PRIMARY}
              />
            </div>
            <div
              className={style.content}
              style={{ minHeight: responsiveConf[2].subscriptionItem.height }}
            >
              <div className={classnames(style.block, style.mainInfo)}>
                <p className={style.title}>{name}</p>
                {subscriptionItem instanceof TucanoModels.Asset ? (
                  <span className={style.price}>
                    {formatPrice(
                      price,
                      consts.priceAfter,
                      consts.priceDecimals,
                      consts.currency
                    )}{" "}
                    - {consts.rentalPeriod} <Trans>rental</Trans>
                  </span>
                ) : (
                  <>
                    <span className={style.price}>
                      {formatPrice(
                        price,
                        consts.priceAfter,
                        consts.priceDecimals,
                        consts.currency
                      )}
                      {recurrenceUnit && (
                        <span className={style.normalFontWeight}>
                          {" "}
                          /{" "}
                          {recurrencePeriod > 1 && (
                            <span>{recurrencePeriod} </span>
                          )}
                          <Trans>
                            {recurrenceUnit.charAt(0).toUpperCase() +
                              recurrenceUnit.slice(
                                1,
                                recurrencePeriod > 1
                                  ? recurrencePeriod.length
                                  : -1
                              )}
                          </Trans>
                        </span>
                      )}
                    </span>
                    {!!setupFees && (
                      <span className={style.setupPrice}>
                        <Trans>Setup Fee</Trans>
                        <sup>*</sup>&nbsp;
                        {formatPrice(
                          setupFees,
                          0,
                          consts.priceDecimals,
                          consts.currency
                        )}
                      </span>
                    )}
                  </>
                )}
                {showSynopsis === true && (
                  <div onClick={this.stopPropagation}>
                    {subscriptionItem.getSynopsis() && style.synopsis && (
                      <Collapsible
                        trigger={
                          <Fragment>
                            <span>
                              <Trans>Details</Trans>
                            </span>
                            <ArrowHeading className={style.arrow} />
                          </Fragment>
                        }
                      >
                        <p
                          className={style.synopsis}
                          dangerouslySetInnerHTML={{
                            __html: subscriptionItem.getSynopsis(),
                          }}
                        />
                      </Collapsible>
                    )}
                  </div>
                )}
              </div>
              {type === "option" && (
                <div className={style.contentInfo}>
                  <div
                    className={classnames(style.block, style.statusInfo)}
                    style={{
                      height: responsiveConf[2].subscriptionItem.height,
                    }}
                  >
                    <span className={style.status}>{status}</span>
                    {validTo > now &&
                      statusId === statusEnum.ACTIVE &&
                      !!subscriptionItem.getRecurring() && (
                        <div className={style.recurrentFee}>
                          <span>
                            <Trans>Next billing</Trans> :{" "}
                          </span>
                          <span>{formattedValidTo}</span>
                        </div>
                      )}
                    {(statusId === statusEnum.TERMINATED ||
                      !subscriptionItem.getRecurring()) && (
                      <span
                        className={style.red}
                        style={{ whiteSpace: "pre-wrap" }}
                      >{`${t("Expire_on")}\n${formattedValidTo}`}</span>
                    )}
                    {(statusId === statusEnum.SUSPEND ||
                      statusId === statusEnum.ORDERED) && (
                      <span className={style.red}>
                        <Trans>Payment requested</Trans>
                      </span>
                    )}
                    {statusId === statusEnum.PENDING && (
                      <span className={style.highlight}>
                        <Trans>Payment in progress</Trans>
                      </span>
                    )}
                  </div>

                  <div
                    className={classnames(style.block, style.action)}
                    style={{
                      height: responsiveConf[2].subscriptionItem.height,
                    }}
                  >
                    {consts.manageSubscription &&
                      (statusId === statusEnum.SUSPEND ||
                        statusId === statusEnum.ORDERED) && (
                        <Button
                          rootClassName={style.actionButton}
                          type={ButtonType.NORMAL}
                          onClick={
                            voucherOnly
                              ? onActionButtonClick.bind(
                                  this,
                                  subscriptionItem,
                                  "subscribe"
                                )
                              : onActionButtonClick.bind(
                                  this,
                                  subscriptionItem,
                                  "regularize"
                                )
                          }
                        >
                          <Trans>Pay now</Trans>
                        </Button>
                      )}

                    {consts.manageSubscription &&
                      (statusId === statusEnum.ACTIVE ||
                        statusId === statusEnum.ORDERED) && (
                        <Button
                          rootClassName={style.actionButton}
                          type={ButtonType.BORDERED}
                          onClick={onActionButtonClick.bind(
                            this,
                            subscriptionItem,
                            "unsubscribe"
                          )}
                        >
                          {unsubscribeButton}
                        </Button>
                      )}

                    {statusId === statusEnum.TERMINATED &&
                      optionValidity.allowRenewal && (
                        <Button
                          rootClassName={style.actionButton}
                          type={ButtonType.NORMAL}
                          onClick={onActionButtonClick.bind(
                            this,
                            subscriptionItem,
                            "renew"
                          )}
                        >
                          <Trans>Renew</Trans>
                        </Button>
                      )}
                  </div>
                </div>
              )}
              {consts.manageSubscription &&
                type === "offer" &&
                selectionType === SelectionType.BUTTON && (
                  <div
                    className={classnames(style.block, style.action)}
                    style={{
                      height: responsiveConf[2].subscriptionItem.height,
                    }}
                  >
                    <Button
                      rootClassName={style.actionButton}
                      type={ButtonType.NORMAL}
                      onClick={onActionButtonClick.bind(
                        this,
                        subscriptionItem,
                        "subscribe"
                      )}
                    >
                      <Trans>Subscribe</Trans>
                    </Button>
                  </div>
                )}
              {type === "offer" && selectionType === SelectionType.CHECKBOX && (
                <div
                  className={classnames(style.block, style.actionCheckbox)}
                  style={{
                    height: responsiveConf[2].subscriptionItem.height,
                  }}
                >
                  <Checkbox checked={checked} rootClassName={style.checkbox} />
                </div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export const SelectionType = {
  BUTTON: "BUTTON",
  CHECKBOX: "CHECKBOX",
  TEXT: "TEXT",
};

SubscriptionItem.defaultProps = {
  selectionType: SelectionType.BUTTON,
  selectedByDefault: false,
};

SubscriptionItem.propTypes = {
  selectionType: PropTypes.oneOf(Object.values(SelectionType)),
  selectedByDefault: PropTypes.bool,
  onActionButtonClick: PropTypes.func,
};

export default withTranslation()(SubscriptionItem);
