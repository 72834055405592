import "react-tabs/style/react-tabs.css";

import { addToast } from "actions/ui";
import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Checkbox from "components/Checkbox";
import ContentWrapper from "components/ContentWrapper";
import Header from "components/Header";
import ArrowHeading from "components/Icons/Arrowheading";
import CheckIcon from "components/Icons/Check";
import CloseIcon from "components/Icons/Close";
import DownloadIcon from "components/Icons/Download";
import SadIcon from "components/Icons/Sad";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import Loader from "components/Loader";
import Modal from "components/Modal";
import RadioCard from "components/RadioCard";
import SubscriptionItem from "components/SubscriptionItem";
import Table from "components/Table";
import VoucherZone from "components/VoucherZone";
import consts from "consts/consts";
import { ResponsiveType } from "consts/responsive";
import FormChangePurchaseCode from "containers/FormChangePurchaseCode";
import ModalValidatePurchaseCode from "containers/ModalValidatePurchaseCode";
import PageAbstract from "containers/PageAbstract";
import IngenicoModel from "helpers/ingenicoModel";
import { getFromLocal } from "helpers/localStorage";
import {
  checkOrderstatus,
  isIOS,
  isIpadOS,
  subscriptionOffersStatus,
} from "helpers/utility";
import { formatPrice, showToast } from "helpers/utility";
import moment from "moment";
import React, { Fragment } from "react";
import Collapsible from "react-collapsible";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

const MODAL_POPUP_SETTING_VERIFICATION = "MODAL_POPUP_SETTING_VERIFICATION";
const MODAL_NEW_SUBSCRIPTION = "MODAL_NEW_SUBSCRIPTION";
const MODAL_UNSUBSCRIBE = "MODAL_UNSUBSCRIBE";
const MODAL_UNSUBSCRIBE_FROM_MOBILE_DEVICE =
  "MODAL_UNSUBSCRIBE_FROM_MOBILE_DEVICE";
const MODAL_PAYMENT_METHOD = "MODAL_PAYMENT_METHOD";
const MODAL_INFO = "MODAL_INFO";
const MODAL_SUBSCRIBE_SUCCESS = "MODAL_SUBSCRIBE_SUCCESS";
const MODAL_UNSUBSCRIBE_SUCCESS = "MODAL_UNSUBSCRIBE_SUCCESS";
const MODAL_UNSUBSCRIBE_ERROR = "MODAL_UNSUBSCRIBE_ERROR";
const MODAL_UPDATE_PAYMENT = "MODAL_UPDATE_PAYMENT";
class PageSubscription extends PageAbstract {
  state = {
    selectedTab: 0,
    show: false,
    showEditPurchaseCode: undefined,
    showPurchaseControl: false,
    proceed: false,
    isRegularize: false,
    subscriptionPayment: {
      paymentAlias: undefined,
      paymentBrand: undefined,
      paymentMethod: undefined,
    },
    accountPayment: {
      paymentAlias: undefined,
      paymentBrand: undefined,
      paymentMethod: undefined,
    },
    voucherCode: "",
    purchaseCode: "",
    validVoucher: undefined,
    promotion: undefined,
    voucherOnlyOption: false,
    subscriptionStore: "",
    subscriptionName: "",
    isChangingPurchaseCode: false,
  };

  constructor(args) {
    super(args);
    this.subscriptionItem = null;
    this.paymentForm = null;
    this.previousUrl = null;
  }

  errorAPIAddOptions = {
    "-163": "Missing parameter optionIds",
    "-1000": "Wrong parameter format",
    "-1100":
      "Try to add an option depending on another, not yet linked to customer sale",
    "-1101": "Data consistency error",
    "-1102": "A subscription is already active on your account",
    "-1103": "This offer is not compatible with your active subscription",
    "-1106": "Error on option’s availability check",
    "-1112": "Error on option’s orderable check",
    "-2332": "No product add on the sale,Please, addProducts before addOptions",
    "-2333": "You can subscribe to this offer only once",
    "-2336": "You can subscribe to this offer only once",
    "-2345": "This offer is not compatible with your product",
    "-3002": "Parameter optionIds not valid",
  };

  errorAPIAskPayment = {
    "-500": "No payment needed for this offer",
    "-501": "Please accept terms and conditions to continue",
    "-911": "This voucher is not valid",
    "-913": "Voucher validation timeout",
    "-1608": "This offer can only be activated with a valid voucher",
    "-2108": "Missing parameter paymentData",
    "-2109": "paymentData not valid",
    "-2110": "Unable to find payment mode",
    "-2111": "Payment mode not active",
  };

  //! function to dispatch add toast action
  addToast(text, url, className, duration) {
    this.props.dispatch(
      addToast({
        text: text,
        url: url,
        className: className,
        duration: duration,
      })
    );
  }

  addOption = (subscription) => {
    const productId = getFromLocal("productId") || consts.productId;
    this.props
      .dispatch(
        TucanoActions.addOptions([subscription.getIdOption()], productId)
      )
      .then((result) => {
        if (result && result.status) {
          this.componentDidMount();
        } else {
          const { t } = this.props;

          this.props.dispatch(
            addToast({
              text: t(result.message),
              className: "error",
              duration: 6000,
            })
          );
        }
      })
      .catch((_error) => {
        const { dataOnAddOptions, t } = this.props;
        if (dataOnAddOptions && dataOnAddOptions.hasOwnProperty("code")) {
          // if (dataOnAddOptions.code === -2333) {
          //   this.handleOnAskPayment();
          // } else {
          this.props.dispatch(
            addToast({
              text: t(this.getErrorAPI("addOptions", dataOnAddOptions.code)),
              className: "error",
              duration: 6000,
            })
          );
          // }
        }
      });
  };

  closeModalPurchaseCode = () => {
    this.setState((prevState) => ({
      showPurchaseControl: !prevState.showPurchaseControl,
    }));
  };

  confirmModalPurchase = (pin) => {
    this.setState(
      (prevState) => ({
        showPurchaseControl: !prevState.showPurchaseControl,
        purchaseCode: pin,
      }),
      () => {
        if (
          this.props.accountDetail?.hasPurchaseCode &&
          this.state.isChangingPurchaseCode
        ) {
          this.props
            .dispatch(TucanoActions.resetPurchaseCode(pin))
            .then(async (purchaseCodeResult) => {
              this.setState((prevState) => ({
                isChangingPurchaseCode: prevState.isChangingPurchaseCode,
              }));
              if (purchaseCodeResult.newAuthToken) {
                await this.props.dispatch(TucanoActions.getAccountDetails());
              }
            });
        } else if (!this.state.isChangingPurchaseCode) {
          this.setState({ proceed: true }, () => {
            this.handleOnAskPayment();
          });
        }
      }
    );
  };

  static fetch(dispatch, _isConnected) {
    const productId = getFromLocal("productId") || consts.productId;
    dispatch(TucanoActions.getAvailableOffers(getFromLocal("languageId")));
    dispatch(TucanoActions.getListOptions(getFromLocal("languageId")));
    dispatch(TucanoActions.getOptionValidity(productId));
    dispatch(
      TucanoActions.getListInvoices(
        undefined,
        undefined,
        undefined,
        undefined,
        getFromLocal("languageId")
      )
    );
    dispatch(TucanoActions.getListPaymentMethods(getFromLocal("languageId")));
  }

  getErrorAPI(api, code) {
    let error = "An error occured,Please retry later";

    switch (api) {
      case "addOptions":
        if (this.errorAPIAddOptions[code]) {
          error = this.errorAPIAddOptions[code];
        }
        break;
      case "askPayment":
        if (this.errorAPIAskPayment[code]) {
          error = this.errorAPIAskPayment[code];
        }
        break;
      default:
        break;
    }

    return error;
  }

  handleAction(subscription, action) {
    this.subscriptionItem = subscription;
    const isSafari = getFromLocal("isSafari");
    switch (action) {
      case "subscribe":
        this.setState({ voucherCode: "" });
        this.setState({ validVoucher: undefined });
        this.setState({ promotion: undefined });
        this.showModal(
          isSafari && (isIOS() || isIpadOS())
            ? MODAL_POPUP_SETTING_VERIFICATION
            : MODAL_NEW_SUBSCRIPTION,
          null,
          false
        );
        break;
      case "unsubscribe":
        if (
          subscription?.externalId?.store === "Apple" ||
          subscription?.externalId?.store === "Google"
        ) {
          this.setState({
            subscriptionStore: subscription.externalId.store,
            subscriptionName: subscription.name,
          });
          this.showModal(
            MODAL_INFO,
            MODAL_UNSUBSCRIBE_FROM_MOBILE_DEVICE,
            false
          );
        } else {
          this.showModal(MODAL_UNSUBSCRIBE, null, false);
        }
        break;
      case "renew":
        this.handleOnRenewOptions(this.subscriptionItem);
        break;
      case "regularize":
        this.showModal(MODAL_PAYMENT_METHOD, null, true);

        break;
      default:
        break;
    }
  }

  handleOnRenewOptions(subscription) {
    const productId = getFromLocal("productId") || consts.productId;
    // this.handleOnRemoveSubscription(subscription);
    /*const optionAdded = this.props.optionValidity.find(
      (opt) => opt.idOption === subscription.idOption
    );*/
    // if (!optionAdded) {
    this.props
      .dispatch(
        TucanoActions.addOptions([subscription.getIdOption()], productId)
      )
      .then(() => {
        const { dataOnAddOptions, t } = this.props;
        if (dataOnAddOptions && dataOnAddOptions.newAuthToken) {
          // this.handleOnAskPayment();
          this.componentDidMount();
        } else {
          if (dataOnAddOptions && dataOnAddOptions.hasOwnProperty("code")) {
            // if (dataOnAddOptions.code === -2333) {
            //   this.handleOnAskPayment();
            // } else {
            this.props.dispatch(
              addToast({
                text: t(this.getErrorAPI("addOptions", dataOnAddOptions.code)),
                className: "error",
                duration: 6000,
              })
            );
            // }
          }
        }
      })
      .catch((_error) => {
        const { dataOnAddOptions, t } = this.props;
        if (dataOnAddOptions && dataOnAddOptions.hasOwnProperty("code")) {
          // if (dataOnAddOptions.code === -2333) {
          //   this.handleOnAskPayment();
          // } else {
          this.props.dispatch(
            addToast({
              text: t(this.getErrorAPI("addOptions", dataOnAddOptions.code)),
              className: "error",
              duration: 6000,
            })
          );
          // }
        }
      });
    // } else {
    //   this.handleOnAskPayment();
    // }
  }
  handleOnAddOptions(subscription) {
    const productId = getFromLocal("productId") || consts.productId;
    // this.handleOnRemoveSubscription(subscription);
    const optionAdded = this.props.optionValidity?.find(
      (opt) => opt.idOption === subscription.idOption
    );
    if (!optionAdded) {
      this.props
        .dispatch(
          TucanoActions.addOptions([subscription.getIdOption()], productId)
        )
        .then(() => {
          const { dataOnAddOptions, t } = this.props;
          if (dataOnAddOptions && dataOnAddOptions.newAuthToken) {
            this.handleOnAskPayment();
          } else {
            if (dataOnAddOptions && dataOnAddOptions.hasOwnProperty("code")) {
              showToast(this.props, {
                text: t(this.getErrorAPI("addOptions", dataOnAddOptions.code)),
                className: "error",
                duration: 6000,
              });
            }
          }
        })
        .catch((_error) => {
          const { dataOnAddOptions, t } = this.props;
          if (dataOnAddOptions && dataOnAddOptions.hasOwnProperty("code")) {
            showToast(this.props, {
              text: t(this.getErrorAPI("addOptions", dataOnAddOptions.code)),
              className: "error",
              duration: 6000,
            });
          }
        });
    } else {
      this.handleOnAskPayment();
    }
  }

  async handleOnAddSubscription(subscription) {
    if (subscription.type === "voucherOnly") {
      this.setState({ voucherOnlyOption: true });
    } else {
      this.setState({ voucherOnlyOption: false });
      if (subscription.getPriceWithVat() === 0) {
        this.hideModal(MODAL_NEW_SUBSCRIPTION, null, false);
      } else {
        this.hideModal(MODAL_NEW_SUBSCRIPTION, null, false);
        this.showModal(MODAL_PAYMENT_METHOD, null, false);
        return true;
      }
    }

    this.handleOnAddOptions(subscription);
  }

  handlePopUpSettingVerification = () => {
    this.hideModal(MODAL_POPUP_SETTING_VERIFICATION, null, false);
    this.showModal(MODAL_NEW_SUBSCRIPTION, null, false);
  };

  openPaymentPopup(url) {
    const strWindowFeatures =
      "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";
    if (
      typeof window.paymentPopup === "undefined" ||
      window.paymentPopup?.closed
    ) {
      window.paymentPopup = window.open(
        url,
        "Stripe Payment",
        strWindowFeatures
      );
    } else if (this.previousUrl !== url) {
      window.paymentPopup = window.open(
        url,
        "Stripe Payment",
        strWindowFeatures
      );
      window.paymentPopup.focus();
    } else {
      window.paymentPopup.focus();
    }
    // assign the previous URL
    this.previousUrl = url;
  }

  async handleOnAskPayment() {
    const { accountDetail } = this.props;
    if (this.state.voucherOnlyOption) {
      this.selectSubscriptionPaymentMethod("Stripe");
    }
    let paymentCopy = { ...this.state.subscriptionPayment };
    let paymentData;
    let reuseData;
    const paymentExists = this.props.paymentMethods?.filter((p) => {
      return p.paymentBrand === paymentCopy?.paymentBrand;
    });
    if (paymentCopy.paymentBrand) {
      if (accountDetail && !accountDetail?.hasPurchaseCode) {
        if (paymentExists && paymentExists.length > 0) {
          paymentCopy.paymentMode = paymentCopy.paymentBrand;
          paymentCopy.paymentBrand = paymentCopy.paymentBrand;
          paymentCopy.paymentMethod = paymentExists[0].paymentMethod;
        } else {
          paymentCopy.paymentMode = "Stripe";
          paymentCopy.paymentBrand = "Stripe";
          paymentCopy.paymentMethod = "Stripe";
        }
        this.setState({ proceed: true });
        reuseData = {
          reusePaymentInfo: false,
          purchasepin: false,
        };
      }
      if (accountDetail && accountDetail?.hasPurchaseCode) {
        if (!this.state.proceed) {
          await this.toggleModalPurchaseControl();
        } else {
          if (paymentExists && paymentExists.length > 0) {
            paymentCopy.paymentMode = paymentCopy.paymentBrand;
            paymentCopy.paymentBrand = paymentCopy.paymentBrand;
            paymentCopy.paymentMethod = paymentExists[0].paymentMethod;
          } else {
            paymentCopy.paymentMode = "Stripe";
            paymentCopy.paymentBrand = "Stripe";
            paymentCopy.paymentMethod = "Stripe";
          }
          reuseData = {
            reusePaymentInfo: false,
            purchasepin: this.state.purchaseCode,
          };
        }
      }
    } else {
      await this.setState({ proceed: true });
    }
    if (this.state.proceed) {
      paymentData = {
        ...paymentData,
        paymentProvider: paymentCopy.brandBillingProviderId,
        policyValidation: true,
        paymentBrand: paymentCopy.paymentBrand,
        paymentMode: paymentCopy.paymentBrand,
        paymentMethod: paymentCopy.paymentMethod,
        returnUrl: window.location.href,
        successUrl:
          window.location.origin +
          consts.routes.subscription.url +
          "/?mv=transaction_accept",
        cancelUrl:
          window.location.origin +
          consts.routes.subscription.url +
          "/?mv=transaction_cancel",
      };
      this.props
        .dispatch(
          TucanoActions.callAskPayment(
            JSON.stringify(paymentData),
            reuseData ? reuseData.reusePaymentInfo : null,
            reuseData ? reuseData.purchasepin : null,
            this.state.voucherCode
          )
        )
        .then(async (result) => {
          const { t } = this.props;
          if (
            result &&
            result.status &&
            result.payload &&
            result.payload.action !== null
          ) {
            checkOrderstatus(this.props.dispatch);
            this.hideModal(MODAL_PAYMENT_METHOD);
            if (result.payload?.method?.toLowerCase() === "post") {
              const ingenicoModel = new IngenicoModel();
              this.paymentForm.appendChild(
                ingenicoModel.fillForm(result.payload, this.paymentForm)
              );
              this.paymentForm.setAttribute("target", "_blank");
              this.paymentForm.submit();
            } else {
              this.openPaymentPopup(result.payload.action);
            }
          } else {
            if (
              result &&
              result.hasOwnProperty("code") &&
              result.code === -500
            ) {
              this.hideModal(MODAL_PAYMENT_METHOD, null, false);
              await this.showModal(
                MODAL_INFO,
                MODAL_SUBSCRIBE_SUCCESS,
                null,
                false
              );
              this.componentDidMount();
            } else if (
              result &&
              result.hasOwnProperty("code") &&
              result.code !== -500
            ) {
              showToast(this.props, {
                text: t(this.getErrorAPI("askPayment", result.code)),
                className: "error",
                duration: 6000,
              });
              this.componentDidMount();
            } else {
              this.hideModal(MODAL_PAYMENT_METHOD, null, false);
              await this.showModal(
                MODAL_INFO,
                MODAL_SUBSCRIBE_SUCCESS,
                null,
                false
              );
              this.componentDidMount();
            }
          }
        })
        .catch((_error) => {
          const { errorOnAskPayment, t } = this.props;
          if (errorOnAskPayment && errorOnAskPayment.hasOwnProperty("code")) {
            showToast(this.props, {
              text: t(this.getErrorAPI("askPayment", errorOnAskPayment.code)),
              className: "error",
              duration: 6000,
            });
          }
        });
    }
  }

  handleOnDownloadInvoice(invoiceUrl) {
    //const { errorOnDocument, t } = this.props;
    /*  console.log(invoiceUrl);
    const link = document.createElement("a");
    link.href = invoiceUrl;
    //link.setAttribute("download", invoiceId + ".pdf");
    document.body.appendChild(link);
    link.click(); */
    window.open(invoiceUrl, "_blank");
    /* this.props
      .dispatch(TucanoActions.callDocument(1, invoiceId))
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
        // Open the URL on new Window
        // window.open(url);
        // Download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", invoiceId + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.props.dispatch(
          addToast({
            text: t(this.getErrorAPI("document", errorOnDocument)),
            className: "error",
            duration: 6000,
          })
        );
      }); */
  }

  handleOnRemoveSubscription(subscription) {
    const productId = getFromLocal("productId") || consts.productId;
    this.props
      .dispatch(
        TucanoActions.removeOptions([subscription.getIdOption()], productId, 1)
      )
      .then((result) => {
        if (result && result.status) {
          this.componentDidMount();
          this.showModal(MODAL_INFO, MODAL_UNSUBSCRIBE_SUCCESS, false);
        } else {
          this.showModal(MODAL_INFO, MODAL_UNSUBSCRIBE_ERROR, false);
        }
      });
  }

  handleOnUpdatePayment() {
    const { t } = this.props;
    let paymentCopy = { ...this.state.subscriptionPayment };

    if (this.state.voucherOnlyOption) {
      this.selectSubscriptionPaymentMethod("Stripe");
    }

    const paymentExists = this.props.paymentMethods.filter((p) => {
      return p.paymentBrand === this.state.accountPayment.paymentMethod;
    });

    let paymentData = {
      paymentProvider: paymentCopy.brandBillingProviderId,
      policyValidation: true,
      paymentBrand: this.state.accountPayment.paymentBrand,
      paymentMode: this.state.accountPayment.paymentBrand,
      paymentMethod:
        paymentExists && paymentExists.length > 0
          ? paymentExists[0].paymentMethod
          : this.state.accountPayment.paymentMethod,
      returnUrl: window.location.origin + consts.routes.subscription.url,
      successUrl:
        window.location.origin +
        consts.routes.subscription.url +
        "/?mv=transaction_accept",
      cancelUrl:
        window.location.origin +
        consts.routes.subscription.url +
        "/?mv=transaction_cancel",
    };

    this.props
      .dispatch(
        TucanoActions.callAskChangeBankInformation(JSON.stringify(paymentData))
      )
      .then((result) => {
        if (result) {
          switch (paymentCopy.paymentMethod.toLowerCase()) {
            case "paypal": {
              const ingenicoModel = new IngenicoModel();
              this.paymentForm.appendChild(
                ingenicoModel.fillForm(result.payload, this.paymentForm)
              );
              this.paymentForm.submit();
              break;
            }
            case "creditcard": {
              const ingenicoModel = new IngenicoModel();
              this.paymentForm.appendChild(
                ingenicoModel.fillForm(result, this.paymentForm)
              );
              this.paymentForm.submit();
              break;
            }
            case "stripe":
            case "str": {
              const stripe = window.Stripe(result.data.apiKey);

              const { error } = stripe.redirectToCheckout({
                sessionId: result.data.idSession,
              });

              if (error) {
                this.props.dispatch(
                  addToast({
                    text: t(this.getErrorAPI("askPayment", null)),
                    className: "error",
                    duration: 6000,
                  })
                );
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      });
  }

  hideModal(modalType) {
    this.setState({ show: false, modalType: modalType }, () => {
      this.componentDidMount();
    });
  }

  selectSubscriptionPaymentBrand(paymentMethod) {
    this.setState({
      subscriptionPayment: Object.assign({}, paymentMethod),
    });
  }

  selectSubscriptionPaymentMethod(paymentMethod) {
    let paymentCopy = { ...this.state.subscriptionPayment };
    paymentCopy.paymentMethod = paymentMethod;
    paymentCopy.paymentBrand = paymentMethod;

    this.setState({
      subscriptionPayment: Object.assign([], paymentCopy),
    });
  }

  selectAccountPaymentBrand(paymentMethod) {
    this.setState({
      accountPayment: Object.assign({}, paymentMethod),
    });
  }

  setVoucherCode(voucherCode, validVoucher, optionVoucher) {
    if (validVoucher && optionVoucher) {
      this.setState({ voucherCode: voucherCode });
      this.setState({ validVoucher: validVoucher });
    }
  }

  showModal(modalType, modalSubType, isRegularize) {
    this.setState({
      show: true,
      modalType: modalType,
      modalSubType: modalSubType,
      isRegularize: isRegularize,
    });
  }

  toggleModalPurchaseControl = () => {
    this.setState({ showPurchaseControl: !this.state.showPurchaseControl });
  };

  togglePurchaseCodeModal = () => {
    this.setState((prevState) => ({
      showEditPurchaseCode: !prevState.showEditPurchaseCode,
    }));
  };

  toggleUseCode = async () => {
    if (this.props.accountDetail?.hasPurchaseCode) {
      this.setState(
        (prevState) => ({
          isChangingPurchaseCode: !prevState.isChangingPurchaseCode,
        }),
        () => {
          this.toggleModalPurchaseControl();
        }
      );
    } else {
      this.togglePurchaseCodeModal();
    }
  };

  async componentDidMount() {
    const { isConnected, t } = this.props;
    this.paymentForm = document.querySelector(".form-payment");
    if (
      consts?.["features-availability"]?.["offers-subscription"] !==
      subscriptionOffersStatus.Enabled
    ) {
      this.props.history.replace("/");
    } else {
      document.title = t(this.props.route.title);
      if (consts.appTitle) {
        document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
      }
      this.props.dispatch(TucanoActions.resetVoucherValidation());
      if (!isConnected) {
        this.props.history.push("?login=1");
      } else {
        PageSubscription.fetch(this.props.dispatch, this.props.isConnected);
        let urlSearchParams = new URLSearchParams(this.props.location.search);
        if (
          urlSearchParams &&
          urlSearchParams.get("TRANSACTIONTYPE") === "60"
        ) {
          this.setState({ selectedTab: 1 });
        }
        await this.props.dispatch(TucanoActions.getAccountDetails());
      }
    }
  }

  componentWillUnmount() {
    this.paymentForm.removeAttribute("action");
    this.paymentForm.removeAttribute("method");
    this.paymentForm.removeAttribute("target");
    this.paymentForm.innerHTML = "";
    this.props.dispatch(TucanoActions.resetOrderStatus());
  }

  requestBillingDashboardAccess(paymentMethod) {
    if (typeof paymentMethod !== "undefined") {
      this.props
        .dispatch(
          TucanoActions.getBillingDashboardAccess(
            paymentMethod.brandBillingProviderId
          )
        )
        .then((result) => {
          if (result.dashboardUrl !== "")
            // window.location.href = result.dashboardUrl;
            window.open(result.dashboardUrl, "_blank");
        });
    }
  }

  render() {
    const {
      isLoadingListOptions,
      isLoadingAvailableOffers,
      isLoadingOptionValidity,
      isLoadingListPaymentMethods,
      isLoadingInvoices,
      isLoadingUser,
      availableOffers,
      listOptions,
      accountDetail,
      optionValidity,
      paymentMethods,
      invoices,
      userInfo,
      viewport,
      t,
      isAskPaymentLoading,
      isAddOptionsLoading,
    } = this.props;
    const { subscriptionStore, subscriptionName } = this.state;

    const invoiceStatusLabelEnum = {
      INPROGRESS: t("In Progress"),
      INVOICED: t("Invoiced"),
      CANCELLED: t("Canceled"),
      PAID: t("Paid"),
      OPEN: t("Open"),
    };

    let listOptionsToShow = null;
    let availableOffersToShow = null;
    let invoicesTableData = [];

    const statusEnum = {
      ACTIVE: 10,
      PENDING: 20,
      SUSPEND: 30,
      TERMINATED: 40,
      CANCEL: 50,
      ORDERED: 60,
      DISBARRED: 70,
      TERMINATED_D: 80,
    };
    if (listOptions && optionValidity) {
      listOptionsToShow = [];
      optionValidity.forEach((item) => {
        let exist = listOptions.find((option) => {
          return option.idOption === item.idOption;
        });
        if (
          (exist &&
            (item.statusId === statusEnum.ACTIVE ||
              item.statusId === statusEnum.ORDERED)) ||
          item.statusId === statusEnum.PENDING ||
          item.statusId === statusEnum.SUSPEND ||
          (item.statusId === statusEnum.TERMINATED &&
            new Date(item.validTo * 1000) > new Date())
        ) {
          exist.validTo = item.validTo;
          listOptionsToShow.push(exist);
        }
      });
    }

    const columns = consts.download
      ? [
          { path: "invoiceDate", label: t("Date"), style: "bold" },
          { path: "name", label: t("Subscription") },
          // { path: "validity", label: t("Validity") },
          { path: "amountWithVat", label: t("Total price") },
          { path: "status", label: t("Status") },
          {
            actionButton: [
              {
                path: "invoiceUrl",
                icon: <DownloadIcon className={style.downloadIcon} />,
                action: this.handleOnDownloadInvoice.bind(this),
              },
            ],
          },
        ]
      : [
          { path: "invoiceDate", label: t("Date"), style: "bold" },
          { path: "name", label: t("Subscription") },
          //{ path: "validity", label: t("Validity") },
          { path: "amountWithVat", label: t("Total price") },
          { path: "status", label: t("Status") },
        ];

    if (availableOffers && optionValidity) {
      availableOffersToShow = [];

      availableOffers.forEach((item) => {
        let exist = optionValidity.find((elem) => {
          return elem.idOption === item.idOption;
        });

        if (!exist) {
          availableOffersToShow.push(item);
        }
      });
    }

    if (!isLoadingInvoices && invoices) {
      invoices.forEach((invoice) => {
        let data = {};
        data.invoiceDate = `${moment
          .unix(invoice.invoiceDate)
          .format(consts.optionValidityDateFormat)}`;
        data.name = invoice.getName();
        data.type = invoice.getType();
        data.invoiceUrl = invoice.getInvoiceUrl();
        data.validity = `${moment
          .unix(invoice.startdate)
          .format(consts.optionValidityDateFormat)} - ${moment
          .unix(invoice.enddate)
          .format(consts.optionValidityDateFormat)}`;
        // the API returns the amount already formatted
        data.amountWithVat = invoice.amountWithVat; // formatPrice(invoice.amountWithVat, consts.priceAfter, consts.priceDecimals, consts.currency)
        data.status = invoice.getStatus();
        if (data.status !== "PAID" || data.invoiceUrl == null)
          data.showActionBtn = false;
        else data.showActionBtn = true;
        data.status = invoiceStatusLabelEnum[invoice.getStatus()];
        if (consts.download) data.invoiceId = invoice.getInvoiceId();
        invoicesTableData.push(data);
      });
    }
    if (!isLoadingUser && userInfo && paymentMethods) {
      if (!this.state.accountPayment.paymentAlias) {
        let exist = paymentMethods.find((payment) => {
          return userInfo.getPaymentType() === payment.paymentAlias;
        });
        if (exist) {
          this.state.accountPayment = exist;
        }
      }

      if (!this.state.subscriptionPayment.paymentAlias) {
        let exist = paymentMethods.find((payment) => {
          return userInfo.getPaymentType() === payment.paymentAlias;
        });
        if (exist) {
          this.state.subscriptionPayment = exist;
        }
      }
    }
    const width = viewport.type === ResponsiveType.PHONE ? "100%" : 210;
    const height = viewport.type === ResponsiveType.PHONE ? "100%" : 118;
    return (
      <Fragment>
        <ContentWrapper>
          <div className={style.root}>
            {this.state.show &&
              this.state.modalType === MODAL_POPUP_SETTING_VERIFICATION && (
                <Modal
                  leftContent={{ title: "" }}
                  rightContent={{
                    canDismiss: this.hideModal.bind(
                      this,
                      MODAL_POPUP_SETTING_VERIFICATION
                    ),
                  }}
                  rootClassName={style.modal}
                  overlay={true}
                  buttons={
                    <>
                      <Button
                        rootClassName={style.confirmButton}
                        type={ButtonType.NORMAL}
                        onClick={this.handlePopUpSettingVerification.bind(this)}
                      >
                        <Trans>Next</Trans>
                      </Button>
                    </>
                  }
                >
                  <span className={style.contentMessage}>
                    <Trans>
                      Before proceeding, Verify that your Safari pop-up settings
                      are disabled,{" "}
                    </Trans>
                    <a
                      style={{ textDecoration: "underline" }}
                      href="App-prefs://prefs:root=SAFARI"
                    >
                      <Trans>SAFARI_SETTINGS_PATH</Trans>
                    </a>
                  </span>
                </Modal>
              )}

            {this.state.show &&
              this.state.modalType === MODAL_NEW_SUBSCRIPTION && (
                <Modal
                  leftContent={{ title: t("New subscription") }}
                  rightContent={{
                    canDismiss: this.hideModal.bind(
                      this,
                      MODAL_NEW_SUBSCRIPTION
                    ),
                  }}
                  rootClassName={style.modal}
                  overlay={true}
                  buttons={
                    <Button
                      rootClassName={style.confirmButton}
                      type={
                        this.state.voucherOnlyOption
                          ? this.state.voucherCode
                            ? this.state.validVoucher
                              ? ButtonType.NORMAL
                              : ButtonType.DISABLED
                            : this.state.voucherOnlyOption &&
                              this.state.voucherCode &&
                              this.state.validVoucher
                            ? ButtonType.NORMAL
                            : ButtonType.DISABLED
                          : ButtonType.NORMAL
                      }
                      onClick={this.handleOnAddSubscription.bind(
                        this,
                        this.subscriptionItem
                      )}
                    >
                      <Trans>Confirm</Trans>
                    </Button>
                  }
                >
                  <div className={style.imageContainer}>
                    <Image
                      src={`${consts.image_hostname}${routes.imgdata}?type=${
                        consts.imageTypes.subscription
                      }&objectId=${this.subscriptionItem.getIdOption()}&ratio=169&format_w=400&format_h=222&languageId=${getFromLocal(
                        "languageId"
                      )}`}
                      rootClassName={style.image}
                      ratio={ImageRatio.COVER}
                      placeholder={PlaceholderType.PRIMARY}
                    />
                  </div>

                  <h2 className={style.modalSubscriptionName}>
                    {this.subscriptionItem.getName()}
                  </h2>
                  <span className={style.modalPrice}>
                    {formatPrice(
                      this.subscriptionItem.getPriceWithVat(),
                      consts.priceAfter,
                      consts.priceDecimals,
                      consts.currency
                    )}
                  </span>

                  <div className={style.separator} />
                  {/* {this.subscriptionItem.getPriceWithoutVat() > 0 && ( */}
                  <VoucherZone
                    subscriptionItems={[this.subscriptionItem]}
                    callback={this.setVoucherCode.bind(this)}
                  />
                  {/* )} */}
                </Modal>
              )}

            {this.state.show && this.state.modalType === MODAL_UNSUBSCRIBE && (
              <Modal
                leftContent={{ title: t("Unsubscribe") }}
                rightContent={{
                  canDismiss: this.hideModal.bind(this, MODAL_UNSUBSCRIBE),
                }}
                rootClassName={style.modal}
                overlay={true}
                buttons={
                  <>
                    <Button
                      rootClassName={style.confirmButton}
                      type={ButtonType.GHOST}
                      onClick={this.hideModal.bind(this, MODAL_UNSUBSCRIBE)}
                    >
                      <Trans>No thanks</Trans>
                    </Button>
                    <Button
                      rootClassName={style.confirmButton}
                      type={ButtonType.NORMAL}
                      onClick={this.handleOnRemoveSubscription.bind(
                        this,
                        this.subscriptionItem
                      )}
                    >
                      <Trans>Yes please</Trans>
                    </Button>
                  </>
                }
              >
                <SadIcon className={style.icon} />

                <span className={style.contentMessage}>
                  <Trans>Are you sure you want to unsubscribe from</Trans>{" "}
                  {this.subscriptionItem.getName()} ?
                </span>
              </Modal>
            )}
            {this.state.show && this.state.modalType === MODAL_UPDATE_PAYMENT && (
              <Modal
                leftContent={{ title: t("Choose your payment method") }}
                rightContent={{
                  canDismiss: this.hideModal.bind(this, MODAL_UPDATE_PAYMENT),
                }}
                rootClassName={style.modal}
                overlay={true}
                buttons={
                  <Button
                    rootClassName={style.confirmButton}
                    type={
                      this.state.accountPayment.paymentAlias
                        ? ButtonType.NORMAL
                        : ButtonType.DISABLED
                    }
                    onClick={this.handleOnUpdatePayment.bind(this)}
                  >
                    <Trans>Continue</Trans>
                  </Button>
                }
              >
                <>
                  {isLoadingListPaymentMethods && <Loader centered={true} />}
                  {!isLoadingListPaymentMethods && (
                    <>
                      <RadioCard
                        type={"vertical"}
                        items={paymentMethods}
                        selected={this.state.accountPayment.paymentAlias}
                        callback={this.selectAccountPaymentBrand.bind(this)}
                        classNameByKey={"paymentBrand"}
                      />
                      {consts.purchasePin && (
                        <div className={style.purchaseCodeModalArea}>
                          <div>
                            <Checkbox
                              change={() => {}}
                              checked={true}
                              label={t(
                                "Save your payment method for later use"
                              )}
                              rootClassName={style.switch}
                            />
                            <div className={style.purchaseCodeModalLabel}>
                              {t("savingCode")}
                            </div>
                          </div>
                          <div>
                            <Checkbox
                              change={this.toggleUseCode}
                              checked={accountDetail?.hasPurchaseCode || false}
                              label={<Trans>Use a purchase code</Trans>}
                              rootClassName={style.switch}
                            />
                            <div className={style.purchaseCodeModalLabel}>
                              <Trans>
                                The purchase code won't be requested for future
                                payment with your last used payment method if
                                you uncheck,Your default code is 1234
                              </Trans>
                            </div>
                          </div>
                          {accountDetail?.hasPurchaseCode && (
                            <div
                              className={style.purchaseCodeModalElement}
                              onClick={this.togglePurchaseCodeModal}
                            >
                              <Trans>Change your purchase code</Trans>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {this.state.showPurchaseControl === "true" && (
                    <ModalValidatePurchaseCode
                      onCloseClick={this.closeModalPurchaseCode}
                      onConfirmClick={this.confirmModalPurchase}
                      requiresPayment={true}
                    />
                  )}
                  {this.state.showEditPurchaseCode && (
                    <Modal
                      leftContent={{
                        title: t(
                          `${
                            accountDetail?.hasPurchaseCode ? "Change" : "Add"
                          } purchase code`
                        ),
                      }}
                      rightContent={{
                        canDismiss: this.togglePurchaseCodeModal,
                      }}
                      rootClassName={style.modal}
                      overlay={true}
                    >
                      <FormChangePurchaseCode
                        closeModal={this.togglePurchaseCodeModal}
                        rootClassName={style.modal}
                        hasPurchaseCode={accountDetail?.hasPurchaseCode}
                      />
                    </Modal>
                  )}
                </>
              </Modal>
            )}
            {this.state.show && this.state.modalType === MODAL_PAYMENT_METHOD && (
              <Modal
                leftContent={{ title: t("Choose your payment method") }}
                rightContent={{
                  canDismiss: this.hideModal.bind(this, MODAL_PAYMENT_METHOD),
                }}
                rootClassName={style.modal}
                overlay={true}
                buttons={
                  <Button
                    rootClassName={style.confirmButton}
                    type={
                      this.state.subscriptionPayment.paymentAlias ||
                      this.state.voucherCode !== ""
                        ? ButtonType.NORMAL
                        : ButtonType.DISABLED
                    }
                    onClick={
                      isAskPaymentLoading || isAddOptionsLoading
                        ? undefined
                        : this.handleOnAddOptions.bind(
                            this,
                            this.subscriptionItem
                          )
                    }
                  >
                    {isAskPaymentLoading || isAddOptionsLoading ? (
                      <Loader />
                    ) : (
                      <Trans>Go to payment</Trans>
                    )}
                  </Button>
                }
              >
                <>
                  {accountDetail &&
                    accountDetail.paymentType &&
                    accountDetail.paymentHolder &&
                    accountDetail.paymentCardNumber &&
                    accountDetail.paymentExpirationDate && (
                      <RadioCard
                        type={"vertical"}
                        items={[
                          {
                            change: true,
                            paymentAlias: `${accountDetail.paymentType}Old`,
                            paymentBrand: `${accountDetail.paymentType}Old`,
                            paymentMethod:
                              accountDetail.paymentType !== "Visa" &&
                              accountDetail.paymentType !== "Mastercard"
                                ? `${accountDetail.paymentType}Old`
                                : "CreditCardOld",
                            paymentType: accountDetail.paymentType,
                            paymentHolder: accountDetail.paymentHolder,
                            paymentCardNumber: accountDetail.paymentCardNumber,
                            paymentExpirationDate:
                              accountDetail.paymentExpirationDate,
                          },
                        ]}
                        selected={this.state.subscriptionPayment.paymentAlias}
                        callback={this.selectSubscriptionPaymentBrand.bind(
                          this
                        )}
                        classNameByKey={"paymentBrand"}
                      />
                    )}
                  {isLoadingListPaymentMethods && <Loader centered={true} />}
                  {!isLoadingListPaymentMethods && (
                    <>
                      {accountDetail &&
                      accountDetail.paymentType &&
                      accountDetail.paymentHolder &&
                      accountDetail.paymentCardNumber &&
                      accountDetail.paymentExpirationDate &&
                      consts.purchasePin ? (
                        <Collapsible
                          open={true}
                          trigger={
                            <div className={style.arrowContainer}>
                              <span className={style.arrowTitle}>
                                <Trans>Other payment methods</Trans>
                              </span>
                              <ArrowHeading className={style.arrow} />
                            </div>
                          }
                        >
                          <RadioCard
                            type={"vertical"}
                            items={paymentMethods}
                            selected={
                              this.state.subscriptionPayment.paymentAlias
                            }
                            callback={this.selectSubscriptionPaymentBrand.bind(
                              this
                            )}
                            classNameByKey={"paymentBrand"}
                          />
                        </Collapsible>
                      ) : (
                        <>
                          {this.subscriptionItem.getPriceWithVat() > 0 && (
                            <RadioCard
                              type={"vertical"}
                              items={paymentMethods}
                              selected={
                                this.state.subscriptionPayment.paymentAlias
                              }
                              callback={this.selectSubscriptionPaymentBrand.bind(
                                this
                              )}
                              classNameByKey={"paymentBrand"}
                            />
                          )}
                          {this.state.isRegularize && (
                            <VoucherZone
                              subscriptionItems={[this.subscriptionItem]}
                              callback={this.setVoucherCode.bind(this)}
                            />
                          )}
                          {consts.purchasePin && (
                            <div className={style.purchaseCodeModalArea}>
                              <div>
                                <Checkbox
                                  change={this.toggleUseCode}
                                  checked={
                                    accountDetail?.hasPurchaseCode || false
                                  }
                                  label={<Trans>Use a purchase code</Trans>}
                                  rootClassName={style.switch}
                                />
                                <div className={style.purchaseCodeModalLabel}>
                                  <Trans>
                                    The purchase code won't be requested for
                                    future payment with your last used payment
                                    method if you uncheck,Your default code is
                                    1234
                                  </Trans>
                                </div>
                              </div>
                              {accountDetail?.hasPurchaseCode && (
                                <div
                                  className={style.purchaseCodeModalElement}
                                  onClick={this.togglePurchaseCodeModal}
                                >
                                  <Trans>Change your purchase code</Trans>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {this.state.showPurchaseControl === true && (
                    <ModalValidatePurchaseCode
                      onCloseClick={this.closeModalPurchaseCode}
                      onConfirmClick={this.confirmModalPurchase}
                      requiresPayment={true}
                    />
                  )}
                  {this.state.showEditPurchaseCode && (
                    <Modal
                      leftContent={{
                        title: t(
                          `${
                            accountDetail?.hasPurchaseCode ? "Change" : "Add"
                          } purchase code`
                        ),
                      }}
                      rightContent={{
                        canDismiss: this.togglePurchaseCodeModal,
                      }}
                      rootClassName={style.modal}
                      overlay={true}
                    >
                      <FormChangePurchaseCode
                        closeModal={this.togglePurchaseCodeModal}
                        rootClassName={style.modal}
                        hasPurchaseCode={accountDetail?.hasPurchaseCode}
                      />
                    </Modal>
                  )}
                </>
              </Modal>
            )}

            {this.state.show &&
              this.state.modalType === MODAL_INFO &&
              this.state.modalSubType === MODAL_SUBSCRIBE_SUCCESS && (
                <Modal
                  rightContent={{
                    canDismiss: this.hideModal.bind(this, MODAL_INFO),
                  }}
                  rootClassName={style.modal}
                  overlay={true}
                  buttons={
                    <Button
                      rootClassName={style.confirmButton}
                      type={ButtonType.NORMAL}
                      onClick={this.hideModal.bind(this, MODAL_INFO)}
                    >
                      <Trans>Close</Trans>
                    </Button>
                  }
                >
                  <>
                    <h1 className={style.infoTitle}>
                      <Trans>Awesome</Trans> !
                    </h1>

                    <span className={style.contentMessage}>
                      <Trans>You can now enjoy all the package content</Trans>
                    </span>
                  </>
                </Modal>
              )}
            {this.state.show &&
              this.state.modalType === MODAL_INFO &&
              this.state.modalSubType === MODAL_UNSUBSCRIBE_SUCCESS && (
                <Modal
                  leftContent={{ title: t("Unsubscribe") }}
                  rightContent={{
                    canDismiss: this.hideModal.bind(this, MODAL_INFO),
                  }}
                  rootClassName={style.modal}
                  overlay={true}
                  buttons={
                    <Button
                      rootClassName={style.confirmButton}
                      type={ButtonType.NORMAL}
                      onClick={this.hideModal.bind(this, MODAL_INFO)}
                    >
                      <Trans>Close</Trans>
                    </Button>
                  }
                >
                  <>
                    <CheckIcon className={style.icon} />

                    <span className={style.contentMessage}>
                      <Trans>You have successfully unsubscribe from</Trans>{" "}
                      {this.subscriptionItem.getName()}
                    </span>
                  </>
                </Modal>
              )}
            {this.state.show &&
              this.state.modalType === MODAL_INFO &&
              this.state.modalSubType === MODAL_UNSUBSCRIBE_ERROR && (
                <Modal
                  rightContent={{
                    canDismiss: this.hideModal.bind(this, MODAL_INFO),
                  }}
                  rootClassName={style.modal}
                  overlay={true}
                  buttons={
                    <Button
                      rootClassName={style.confirmButton}
                      type={ButtonType.NORMAL}
                      onClick={this.hideModal.bind(this, MODAL_INFO)}
                    >
                      <Trans>Close</Trans>
                    </Button>
                  }
                >
                  <>
                    <h1 className={style.infoTitle}>
                      <Trans>Something went wrong !</Trans>
                    </h1>

                    <span className={style.contentMessage}>
                      <Trans>
                        A problem occurred when want to unsubscribe
                        option,Please try again
                      </Trans>
                    </span>
                  </>
                </Modal>
              )}
            {this.state.show &&
              this.state.modalType === MODAL_INFO &&
              this.state.modalSubType ===
                MODAL_UNSUBSCRIBE_FROM_MOBILE_DEVICE && (
                <Modal
                  leftContent={{ title: t("Unsubscribe") }}
                  rightContent={{
                    canDismiss: this.hideModal.bind(this, MODAL_INFO),
                  }}
                  rootClassName={style.modal}
                  overlay={true}
                  buttons={
                    <Button
                      rootClassName={style.confirmButton}
                      type={ButtonType.NORMAL}
                      onClick={this.hideModal.bind(this, MODAL_INFO)}
                    >
                      <Trans>Ok</Trans>
                    </Button>
                  }
                >
                  <CloseIcon className={style.icon} />
                  <span className={style.contentMessage}>
                    {t("CAN_NOT_UNSUBSCRIBED_FROM_WEB", { subscriptionName })}{" "}
                    <br />
                    {t("UNSUBSCRIBED_ONLY_FROM_MOBILE", {
                      device: subscriptionStore,
                    })}{" "}
                    <br />
                    <Trans>account</Trans>
                  </span>
                </Modal>
              )}
            <div className={style.billingDashboardContainer}>
              <Header>
                <Trans>{this.props.route.title}</Trans>
              </Header>
              {!isLoadingInvoices &&
                typeof invoices !== "undefined" &&
                invoices.length > 0 && (
                  <Button
                    rootClassName={classnames(
                      style.actionButton,
                      style.btnBillingDashboard
                    )}
                    type={ButtonType.NORMAL}
                    onClick={this.requestBillingDashboardAccess.bind(
                      this,
                      this.props.lastUsedPayementMethod
                    )}
                  >
                    <Trans>Billing Dashboard</Trans>
                  </Button>
                )}
            </div>
            <Tabs
              className={style.tabsContainer}
              selectedIndex={this.state.selectedTab}
              onSelect={(tabIndex) => this.setState({ selectedTab: tabIndex })}
            >
              <TabList>
                <Tab>{t("Subscription plans")}</Tab>
                {consts.manageSubscription &&
                  !isLoadingInvoices &&
                  typeof invoices !== "undefined" &&
                  invoices.length > 0 && <Tab>{t("Billing details")}</Tab>}
              </TabList>

              <TabPanel>
                {isLoadingListOptions &&
                  isLoadingOptionValidity &&
                  isLoadingAvailableOffers &&
                  isLoadingInvoices && <Loader centered={true} />}
                {!isLoadingListOptions &&
                  !isLoadingOptionValidity &&
                  !isLoadingAvailableOffers && (
                    <div>
                      <p className={style.setupFeeDescription}>
                        *
                        <Trans>
                          Setup fees could be applied for some subscriptions if
                          it is mentioned with the offer price, only once at the
                          first subscription or at the subscription renewal
                          after cancellation.
                        </Trans>
                      </p>
                      {!listOptionsToShow ||
                        (listOptionsToShow.length === 0 && (
                          <p className={style.title}>
                            <Trans>There is no active option</Trans>
                          </p>
                        ))}
                      <Fragment>
                        {listOptionsToShow &&
                          listOptionsToShow.map((item, index) => {
                            let optionVal = null;
                            if (optionValidity) {
                              optionVal = optionValidity.find((elem) => {
                                return elem.idOption === item.idOption;
                              });
                            }

                            return (
                              <SubscriptionItem
                                key={index}
                                subscriptionItem={item}
                                optionValidity={optionVal}
                                type={"option"}
                                width={width}
                                height={height}
                                onActionButtonClick={this.handleAction.bind(
                                  this
                                )}
                                voucherOnly={
                                  item.type === "voucherOnly" ? true : false
                                }
                              />
                            );
                          })}
                      </Fragment>
                      {!availableOffersToShow ||
                      availableOffersToShow.length === 0 ? (
                        <p className={style.title}>
                          <Trans>There is no available offer</Trans>
                        </p>
                      ) : (
                        <p className={style.title}>
                          <Trans>More offers</Trans>
                        </p>
                      )}
                      <Fragment>
                        {availableOffersToShow &&
                          availableOffersToShow.map((item, index) => {
                            return (
                              <SubscriptionItem
                                key={index}
                                subscriptionItem={item}
                                type={"offer"}
                                width={width}
                                height={height}
                                onActionButtonClick={this.handleAction.bind(
                                  this
                                )}
                              />
                            );
                          })}
                      </Fragment>
                    </div>
                  )}
              </TabPanel>

              {isLoadingListOptions &&
              isLoadingOptionValidity &&
              isLoadingAvailableOffers &&
              isLoadingInvoices ? (
                <Loader centered={true} />
              ) : (
                !isLoadingListOptions &&
                !isLoadingOptionValidity &&
                !isLoadingAvailableOffers &&
                typeof invoices !== "undefined" &&
                invoices.length > 0 && (
                  <TabPanel>
                    <p className={style.title}>
                      <Trans>Billing history</Trans>
                    </p>
                    <Table column={columns} data={invoicesTableData} />
                  </TabPanel>
                )
              )}
            </Tabs>
          </div>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      toasts: state.ui.toasts,
      isLoadingListOptions: state.subscription.listOptions.loading,
      isLoadingAvailableOffers: state.subscription.availableOffers.loading,
      isLoadingOptionValidity: state.subscription.optionValidity.loading,
      isLoadingListPaymentMethods:
        state.subscription.listPaymentMethods.loading,
      isLoadingInvoices: state.account.invoices.loading,
      isLoadingUser: state.account.user.loading,
      isLoadingValidateCode: state.account.validatePurchaseCode.loading,
      purchaseCodeValidity: state.account.validatePurchaseCode.data,
      availableOffers: TucanoSelectors.getAvailableOffers(state),
      listOptions: TucanoSelectors.getListOptions(state),
      optionValidity: TucanoSelectors.getOptionValidity(state),
      userInfo: TucanoSelectors.getUserInfo(state),
      accountDetail: state.account.user.data,
      purchaseCode: state.account.purchaseCode.data,
      paymentMethods: TucanoSelectors.getListPaymentMethods(state),
      lastUsedPayementMethod: TucanoSelectors.getLastUsedPaymentMethod(state),
      invoices: TucanoSelectors.getListInvoices(state),
      dataOnAddOptions: state.subscription.addOptions.data || undefined,
      errorOnAskPayment: state.subscription.askPayment.error || undefined,
      isAskPaymentLoading: state.subscription.askPayment.loading,
      isAddOptionsLoading: state.subscription.addOptions.loading,
      viewport: state.ui.viewport,
    };
  }),
  withTranslation()
)(PageSubscription);
