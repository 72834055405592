import { setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import AssetHeader from "components/AssetHeader";
import AssetHeaderBackground from "components/AssetHeaderBackground";
import Button, { ButtonType } from "components/Button";
import CastLabel from "components/CastLabel";
import CatchupIcon from "components/Icons/CatchUp";
import Subscription from "components/Icons/Subscription";
import LinkBack from "components/LinkBack";
import Loader from "components/Loader";
import ModalNPVRRecord from "components/ModalNPVRRecord";
import RecordActions from "components/RecordActions";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import PageAbstract from "containers/PageAbstract";
import { isConnectedFromLocalISP } from "helpers/localIsp";
import { getFromLocal } from "helpers/localStorage";
import { isLiveOrUpComing } from "helpers/time";
import { subscriptionOffersStatus } from "helpers/utility";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageAssetEPG extends PageAbstract {
  state = {
    activeSeason: 0,
  };

  static fetch(dispatch, isConnected, params) {
    const id = params.id;
    const languageId = getFromLocal("languageId") ?? consts.languageId;
    dispatch(TucanoActions.getChannels(undefined, undefined, languageId));
    dispatch(TucanoActions.getEPGDetail(id, languageId));
    if (isConnected) {
      if (consts?.["features-availability"]?.["is-npvr-enabled"]) {
        dispatch(TucanoActions.getNPVRRecords());
      }
      dispatch(TucanoActions.getFavorites(languageId));
    }
  }

  componentDidMount() {
    if (consts.appModules && consts.appModules.epg !== true) {
      this.props.history.push("/");
    }
    PageAssetEPG.fetch(
      this.props.dispatch,
      this.props.isConnected,
      this.props.match.params
    );
  }

  componentDidUpdate() {
    const { epg } = this.props;
    if (epg) {
      document.title = `${epg.title} - ${epg.channelName} - ${consts.name}`;
      const synopsis = epg.synopsis;
      if (synopsis) {
        document.getElementsByTagName("meta")[3].content = epg.synopsis;
      } else {
        document.getElementsByTagName(
          "meta"
        )[3].content = `${epg.title} - ${epg.channelName} - ${consts.name}`;
      }
      document.getElementsByTagName("meta")[4].content = `${epg.title}, ${
        epg.genre
      }, ${epg.channelName}, ${epg.getBroadcastTime()}`;
      document.getElementsByTagName("meta")[5].content = consts.name;
    }
  }

  componentWillUnmount() {
    this.props.dispatch(TucanoActions.resetEpgDetail());
    this.props.dispatch(TucanoActions.resetEPG());
  }

  subscriptionModal(_asset) {
    const { accountStatus, dispatch } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(this.props.epg);
    }
  }

  openPlayer = (channelID) => {
    const { dispatch } = this.props;
    dispatch(TucanoActions.restartPlayerLive());
    this.props.history.push(`/playerlive/${channelID}`);
  };

  render() {
    const {
      isLoading,
      epg,
      isConnected,
      viewport,
      channel,
      modalNPVRInfo,
      accountStatus,
    } = this.props;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    const direction = getFromLocal("layoutDirection");

    const now = new Date();
    let epgInfos = [],
      isStartoverAllowed = false,
      isCurrentProgram = false;
    if (epg) {
      epgInfos.push(epg.getReleaseYear());
      epgInfos.push(epg.getHumanDuration());
      epgInfos.push(epg.getGenre());
      if (epg.getRating() > 0) {
        epgInfos.push("{{moralityLevel}} +", {
          moralityLevel: epg.getRating(),
        });
      }
      epgInfos = epgInfos.filter(Boolean);
      // check if startover enabled
      if (channel) {
        isStartoverAllowed =
          +now - epg.getStartTime().getTime() < channel.timeshift;
        isCurrentProgram =
          +now > epg.getStartTime().getTime() &&
          +now < epg.getEndTime().getTime();
      }
    }

    let isPhoneSize;
    if (thumbnailSize.name === "PHONE") {
      isPhoneSize = true;
    }
    const isLocalIsp = isConnectedFromLocalISP();

    return (
      <Fragment>
        {modalNPVRInfo?.showModalNPVR && <ModalNPVRRecord />}
        {isLoading && <Loader centered={true} />}
        {!isLoading && epg && (
          <div className={style.root}>
            <LinkBack
              rootClassName={classnames(style.linkBack, {
                [style.linkBackRTL]: direction.toLocaleLowerCase() === "rtl",
              })}
            />
            <AssetHeaderBackground
              item={epg}
              link={consts.routes.playerlive.url.replace(
                ":channelId",
                epg.getChannelId()
              )}
              url={epg.getPosterImage(consts.image_hostname, {
                width: 1914,
                height: 1077,
                languageId: getFromLocal("languageId"),
              })}
              isPhoneSize={isPhoneSize}
              subscriptionModal={this.subscriptionModal.bind(this)}
              rootClassName={
                !consts.showSliderGradient
                  ? style.assetHeaderWithoutGradient
                  : undefined
              }
            />
            <div className={style.contentContainer}>
              <AssetHeader
                title={epg.getTitle()}
                subtitle={epgInfos.join(" | ")}
                thumbnailSize={thumbnailSize}
                isPhoneSize={isPhoneSize}
                item={epg}
                subscriptionModal={this.subscriptionModal.bind(this)}
                buttons={
                  <>
                    {epg &&
                      channel &&
                      ((isLiveOrUpComing(
                        epg.getStartTime().getTime(),
                        epg.getEndTime().getTime()
                      ) &&
                        channel.npvr >= -1) ||
                        epg.getRecordStatus() === "Recorded") && (
                        <div className={style.actionNpvr}>
                          <RecordActions
                            type={epg.getRecordStatus()}
                            withTitle={true}
                            epg={epg}
                            channel={channel}
                          />
                        </div>
                      )}
                    {epg &&
                      channel &&
                      channel.startOver === true &&
                      isStartoverAllowed &&
                      isCurrentProgram && (
                        <div className={style.startoverLink}>
                          {!isPhoneSize && (
                            <>
                              <Button
                                rootClassName={style.startoverButton}
                                type={ButtonType.BORDERED}
                                icon={
                                  <CatchupIcon
                                    className={style.startoverIcon}
                                  />
                                }
                                onClick={() =>
                                  this.openPlayer(epg.getChannelId())
                                }
                              >
                                <span className={style.textButton}>
                                  <Trans>Restart</Trans>
                                </span>
                              </Button>
                            </>
                          )}
                          {isPhoneSize && (
                            <div className={style.round}>
                              <Button
                                rootClassName={style.roundButton}
                                type={ButtonType.BORDERED}
                                icon={
                                  <CatchupIcon
                                    className={style.startoverIcon}
                                  />
                                }
                                onClick={() =>
                                  this.openPlayer(epg.getChannelId())
                                }
                              />
                              <div className={style.textButton}>
                                <Trans>Restart</Trans>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    {consts?.["features-availability"]?.[
                      "offers-subscription"
                    ] === subscriptionOffersStatus.Enabled &&
                      ((consts.padlocks === true &&
                        isConnected === true &&
                        !epg.userHasAccess) ||
                        (accountStatus &&
                          accountStatus.toLowerCase() === "suspended")) && (
                        <>
                          {!isPhoneSize && (
                            <Button
                              rootClassName={style.subscriptionButton}
                              type={ButtonType.NORMAL}
                              icon={
                                <Subscription
                                  className={style.subscriptionIcon}
                                />
                              }
                              onClick={this.subscriptionModal.bind(this)}
                            >
                              <Trans>Subscription options</Trans>
                            </Button>
                          )}
                          {isPhoneSize && (
                            <div className={style.round}>
                              <Button
                                rootClassName={style.roundButton}
                                type={ButtonType.NORMAL}
                                icon={
                                  <Subscription
                                    className={style.subscriptionIcon}
                                  />
                                }
                                onClick={this.subscriptionModal.bind(this)}
                              />
                              <div>
                                <Trans>Subscription options</Trans>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                  </>
                }
              />
              <div
                className={classnames(style.descriptionContainer, {
                  [style.withFooter]: isLocalIsp,
                  [style.withoutFooter]: !isLocalIsp,
                })}
              >
                {epg.getSynopsis() !== null && (
                  <div className={style.synopsisContainer}>
                    <h2 className={style.containerTitle}>
                      <Trans>SYNOPSIS</Trans>
                    </h2>
                    <p className={style.containerInformation}>
                      {epg.getSynopsis()}
                    </p>
                  </div>
                )}
                {epg && epg.getCast() && epg.getCast().length > 0 && (
                  <Fragment>
                    <div className={style.horizontalLine} />
                    <div className={style.castContainer}>
                      <Fragment>
                        <h2 className={style.containerTitle}>
                          <Trans>CAST</Trans>
                        </h2>
                        {epg.getCastFirstRoles() &&
                          epg.getCastFirstRoles().map((item, index) => (
                            <CastLabel key={index} asset={epg}>
                              {item}
                            </CastLabel>
                          ))}
                      </Fragment>
                      <div className={style.castContainer}>
                        {epg.getCastSecondRoles()}
                      </div>
                    </div>
                  </Fragment>
                )}
                {epg &&
                  epg.getDirectors() &&
                  epg.getDirectors().length > 0 &&
                  epg.getDirectors() !== "N/A" && (
                    <Fragment>
                      <div className={style.horizontalLine} />
                      <div className={style.directorContainer}>
                        <h2 className={style.containerTitle}>
                          <Trans>DIRECTOR(S)</Trans>
                        </h2>
                        <p className={style.containerInformation}>
                          {epg.getDirectors()}
                        </p>
                      </div>
                    </Fragment>
                  )}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state, _props) => {
    const epg = TucanoSelectors.getEPGDetail(state);
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      viewport: state.ui.viewport,
      modalNPVRInfo: state.ui.modalNPVRInfo,
      isloading: state.content.assetEPG.loading,
      epg: epg,
      channel: epg && TucanoSelectors.getChannelById(state, epg.getChannelId()),
      accountStatus: state.account.user?.data?.status,
    };
  }),
  withTranslation()
)(PageAssetEPG);
