import { apiGatewayUrls } from "./web-api/consts/routes";

const appConfig = window.appConfig || {};

const getConfig = async () => {
  try {
    return await fetch(
      `${appConfig.graphBaseUrl}${apiGatewayUrls.configuration.replace(
        "API_KEY",
        `${appConfig.apiKey}`
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/x-www-form-urlencoded",
        },
      }
    );
  } catch (err) {
    throw new Error(`Failed to load app configuration, error : ${err}`);
  }
};
(async () => {
  const rawConfig = await getConfig();

  if (rawConfig.ok) window.externalConfig = await rawConfig.json();

  require("./index.js");
})();
