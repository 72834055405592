import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import consts from "consts/consts";
import routesConf from "consts/routes";
import rootReducers from "reducers";
import { compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { setAuthTokens, setDiscoverMode } from "actions/session";
import { initAppleIdAuth } from "helpers/Apple";
import { initFacebookSdk } from "helpers/Facebook";
import { initGoogleApi } from "helpers/Google";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { checkLanguageSupport } from "helpers/utility";
import i18n from "i18n";
import rootMiddlewares from "middlewares";

import * as serviceWorker from "./serviceWorker";

import "./style.css";

const initialState = {};

const isProduction = process.env.NODE_ENV === "production";

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(
  rootReducers,
  initialState,
  composeEnhancers(rootMiddlewares())
);
let persistor = persistStore(store);
const tokens = getFromLocal(consts.storageKeys.tokens);
const layoutDirection = getFromLocal("layoutDirection");
let currentLang = getFromLocal("languageId");
const isDiscoverMode = getFromLocal("isDiscoverMode");

// Check if the profile prefered language is available in the website
currentLang = checkLanguageSupport(currentLang);

if (tokens) {
  store.dispatch(setAuthTokens(tokens));
}
if (isDiscoverMode) {
  store.dispatch(setDiscoverMode(isDiscoverMode));
}
if (!layoutDirection) {
  saveInLocal("layoutDirection", consts.layoutDirection);
}
saveInLocal("layoutDirection", currentLang === "ara" ? "rtl" : "ltr");

if (consts.loginWithSsoSocial) {
  initGoogleApi();
  initFacebookSdk();
  initAppleIdAuth();
}

// init sentry
if (consts.sentry && consts.sentry.dsn && isProduction) {
  Sentry.init({
    enabled: Boolean(consts.sentry.dsn),
    dsn: consts.sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: consts.sentry.tracesSampleRate ?? 1.0,
    environment: process.env.PLATFORM_NAMESPACE ?? process.env.NODE_ENV,
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      "Script error.",
      // Avast extension error
      "_avast_submit",
      // This seems to get thrown when we get errors in the error handler that are not constructed right
      "Non-Error exception captured",
      // This is firebase connection error, I cannot see a way to handle this gracefully globally
      "Messaging: The notification permission was not granted and blocked instead. (messaging/permission-blocked).",
      // Safari does not yet support firebase and old versions of firefox can kick off
      "Messaging: This browser doesn't support the API's required to use the firebase SDK. (messaging/unsupported-browser).",

      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
      "TypeError: cancelado",
      "Non-Error promise rejection captured",
      "NotSupportedError",
      "/operation is not supported/",
      "/Loading chunk [d]+ failed/",
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });

  Sentry.configureScope((scope) => {
    scope.setTag("platform_namespace", process.env.PLATFORM_NAMESPACE);
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback="">
          <BrowserRouter>{renderRoutes(routesConf)}</BrowserRouter>
        </Suspense>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
document
  .getElementsByTagName("html")[0]
  .setAttribute("dir", getFromLocal("layoutDirection"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
