const appConfig = window.appConfig || {};
const externalConfig = window.externalConfig || {};

const skinConfig = Object.assign(
  {
    // Global
    name: "Beemix",
    appTitle: "",
    baseUrl: "https://proxies.staging-tucano-main.labs.alphanetworks.eu",
    graphBaseUrl: "https://api.staging-tucano-main.labs.alphanetworks.eu",
    image_hostname: "",
    apiKey: "StuswAChlbaprIb8FANetRaNoDAp97pl",
    safariApiKey: "ejaygDulsOasyotghaciagMuHud2Ogby",
    veygoKey: "",
    veygoAnalytics: false,
    languageId: "eng",
    languageIdMapping: {
      eng: "en",
      fra: "fr",
      por: "pt",
      ita: "it",
      spa: "es",
      ara: "ar",
    },
    guestModeEnabled: true,
    dateFormat: "yyyy/mm/dd",
    metaData: [],
    interalLinkPrefix: "beemix://",
    // Asset
    hidePosterOnAssetPage: false,
    vodFullscreenNoText: true,
    displayVodData: true,
    padlocks: true,

    // Local storage
    storageKeys: {
      prefix: "BEEMIX",
      tokens: "tokens",
      device: "device",
      player: "player",
      playerTrailer: "playerTrailer",
      localISP: "localISP",
    },

    //carousel
    carousel: {
      autoplay: true,
      interval: 5000,
    },

    layoutDirection: "ltr",

    // thumbnails (small,medium,large)
    thumbnailBorderRadius: "small",
    thumbnailShadow: "small",
    thumbnailStyle: [
      {
        small: "smallBorderRadius",
        medium: "mediumBorderRadius",
        large: "largeBorderRadius",
      },
      {
        small: "smallShadow",
        medium: "mediumShadow",
        large: "largeShadow",
      },
    ],

    imageTypes: {
      catchupCover: 511,
      movie_episode_Poster: 510,
      movie_episode_cover: 512,
      seriePoster: 540,
      serieCover: 550,
      epg: 500,
      tvChannel: 100,
      subscription: 110,
    },

    // Live
    liveMetaData: [],

    // EPG
    epgMetaData: [],
    epgDateFormat: "ddd , MMM DD" /* moment format */,
    pastNumberDaysEPG: 7 /* day number */,
    futurNumberDaysEPG: 7 /* day number */,
    epgTonightHour: "20:00" /* XH:XM or XH */,
    durationEPGDisplayImage: 1 /* hour number */,

    // Player Live
    playerLive: {
      canPause: true, // play/payse
      canSeekForward: true, // -15s
      canSeekBackward: true, // +15s
      canSeek: true,
      canStartover: true,
      canRecord: false, // Enregristrement
      audio: {}, // audio
      quality: {}, // Qualité
      canPictureInPicture: true, // Picture in Picture
    },

    // Player
    offsetBingeValue: 60000,
    providerDrm: "irdeto" /* castlabs, irdeto */,
    videoTypes: {
      1: "transport stream",
      2: "HLS",
      3: "HSS",
      4: "HDS",
      5: "DASH",
      6: "CMAF",
      7: "FLV",
      8: "MKV",
      9: "OTHER",
    },
    drmNames: {
      1: "fairplay",
      2: "widevine",
      3: "playReady",
      4: "proprietary",
    },
    player: {
      canPause: true, // play/payse
      canSeekForward: true, // -15s
      canSeekBackward: true, // +15s
      canSeekProgress: true,
      audio: {}, // audio
      quality: {}, // Qualité
      canPictureInPicture: true, // Picture in Picture
      resetPlayerOnPercent: 95,
    },

    // Tracking
    tagManagerId: "",

    // VOD
    vodMetaData: [],
    mainCastMax: 10,
    subCastMax: 20,

    // Catchup
    catchupMetaData: [],
    catchupDateFormat: "ddd, MMM DD" /* moment format */,
    timeCatchupFormat: "HH:mm", // moment format 24H
    broadcastDateFormat: "MM/DD/YYYY" /* moment format */,
    broadcastTimeFormat: "HH:mm" /* moment format */,

    // Subscription
    productId: null,
    currency: "$",
    priceAfter: false,
    priceDecimals: 2,
    rentalPeriod: "48h",
    paymentProviders: {
      ingenico: 1,
      stripe: 12,
    },
    paymentProvider: "stripe",
    freePaymentOption: false,
    optionValidityDateFormat: "MM/DD/YYYY, h:mm" /* moment format */,
    subscriptionValidityDateFormat: "DD/MM/YYYY" /* moment format */,
    download: true,
    purchasePin: true,

    // Signup and Account
    country: "BE",
    privacyPolicyUrl: "",
    termsOfServicesUrl: "",
    externalSignupLink: "",
    confirmEmail: true,
    enum: {
      title: [],
      languageId: [],
      addressCountry: [],
    },
    specificTitleLabel: "",
    specificEmailLabel: "",
    specificCityLabel: "",
    specificStateLabel: "",
    specificPaymentMethodLabel: "",
    specificPrivacyPolicy: false,
    steps: [
      {
        name: "Account creation",
        nameAlias: "account",
        subSteps: [
          {
            name: "Profile",
            data: [
              {
                key: "title",
                value: "",
              },
              {
                key: "firstName",
                value: "",
              },
              {
                key: "lastName",
                value: "",
              },
              {
                key: "dateofbirth",
                value: "",
              },
              {
                key: "languageId",
                value: "",
              },
              {
                key: "_label_1_",
                value: "Contact",
              },
              {
                key: "email",
                value: "",
              },
              {
                key: "emailConfirm",
                value: "",
              },
              {
                key: "phoneNumber",
                value: "",
              },
              {
                key: "acceptTerms",
                value: false,
              },
              {
                key: "allowNews",
                value: false,
              },
            ],
            buttons: {
              back: {
                visible: false,
              },
            },
          },
          {
            name: "Choose your password",
            data: [
              {
                key: "password",
                value: "",
              },
              {
                key: "passwordConfirm",
                value: "",
              },
            ],
            buttons: {
              back: {
                visible: true,
              },
              next: {
                action: "createAccount",
              },
            },
          },
          {
            name: "Enter your address",
            data: [
              {
                key: "addressNumber",
                value: "",
              },
              {
                key: "addressStreet",
                value: "",
              },
              {
                key: "addressSecondStreet",
                value: "",
              },
              {
                key: "addressThirdStreet",
                value: "",
              },
              {
                key: "addressCity",
                value: "",
              },
              {
                key: "addressZip",
                value: "",
              },
              {
                key: "addressState",
                value: "",
              },
              {
                key: "addressCountry",
                value: "",
              },
            ],
            buttons: {
              back: {
                visible: false,
              },
              next: {
                action: "changeAddress",
                label: "Choose your subscription",
              },
              skip: {
                visible: "true",
                action: "subscription",
              },
            },
          },
        ],
      },
      {
        name: "Subscription",
        nameAlias: "subscription",
        data: [],
        buttons: {
          back: {
            visible: false,
          },
          next: {
            label: "Confirm",
          },
          skip: {
            visible: true,
          },
        },
      },
      {
        name: "Payment method",
        nameAlias: "payment",
        data: {
          paymentAlias: "",
          paymentBrand: "",
          paymentMethod: "",
          voucherCode: "",
          validVoucher: undefined,
        },
        required: {
          paymentAlias: true,
        },
        buttons: {
          back: {
            visible: true,
          },
          next: {
            action: "askPayment",
            label: "Pay now",
          },
        },
      },
      {
        name: "Confirmation",
        nameAlias: "confirmation",
        data: {},
        buttons: {
          back: {
            visible: false,
          },
          next: {
            visible: false,
          },
        },
      },
    ],
    pageMyAccount: {
      title: true,
      birthday: true,
      phone: true,
      adresses: true,
      newsletters: true,
    },
    createAccount: {
      required: {
        title: false,
        username: false,
        firstName: false,
        lastName: false,
        dateofbirth: false,
        languageId: false,
        email: false,
        emailConfirm: false,
        phoneNumber: false,
        acceptTerms: false,
        allowNews: false,
        password: false,
        passwordConfirm: false,
        addressNumber: false,
        addressStreet: false,
        addressSecondStreet: false,
        addressThirdStreet: false,
        addressCity: false,
        addressZip: false,
        addressState: false,
        addressCountry: false,
      },
    },
    changeAddress: {
      required: {
        name: false,
        addressTitle: false,
        addressType: false,
        addressStreet: false,
        addressSecondStreet: false,
        addressThirdStreet: false,
        addressNumber: false,
        addressBox: false,
        addressZip: false,
        addressCity: false,
        addressState: false,
        addressCountry: false,
      },
    },
    changeDetails: {
      required: {
        firstName: false,
        middleName: false,
        lastName: false,
        title: false,
        email: false,
        emailConfirm: false,
        languageId: false,
        dateofbirth: false,
        mobile: false,
        allowNews: false,
        partner: false,
        phoneNumber: false,
        additionalInformation: false,
      },
    },
    loginWithCredentials: true,
    loginWithCredentialsAsSingleButton: false,
    loginWithSSO: true,
    loginWithSsoSocial: true,
    loginWithSsosAsSingleButton: false,
    loginWithSsoSocialAsSingleButton: false,
    SSORedirectURI: "http://127.0.0.1:8000",
    SSOConfig: [],
    maxSocialLoginToShow: 3,
    SSOLinkUnlinkSection: true,
    SSOSocials: [
      {
        name: "google",
        clientID:
          "216062883635-hae8vgv3ig1v942tk0rvl9hhsvh45ova.apps.googleusercontent.com",
        logoName: "brisanet_logo.png",
        redirectURI: "https://localhost:8000",
        oauthUri: "https://accounts.google.com/o/oauth2/v2/auth",
        scope: "profile email openid",
        responseType: "code",
        callbackAPI: "https://localhost:8000",
      },
      {
        name: "facebook",
      },
      {
        name: "apple",
      },
      {
        name: "twitter",
      },
      {
        name: "github",
      },
      {
        name: "linkedin",
      },
    ],

    // Routes
    routes: {
      home: {
        url: "/",
        title: "Home",
      },
      signup: {
        url: "/signup",
        title: "Sign Up",
      },
      signIn: {
        url: "/sign-in",
        title: "Sign In",
      },
      confirmAccount: {
        url: "/confirm-account",
        title: "Confirm Account",
      },
      resetpassword: {
        url: "/resetpassword",
        title: "Reset password",
      },
      movieAsset: {
        url: "/asset/:id",
        title: "Asset",
      },
      suggest: {
        url: "/asset/:id/suggest",
        title: "Beemix - Suggestions",
      },
      tvshowAsset: {
        url: "/tvshow/:id",
        title: "TVShow",
      },
      epgAsset: {
        url: "/epg/:id",
        title: "EPG",
      },
      catchup: {
        url: "/catchup",
        title: "Catch Up",
      },
      catchupchannel: {
        url: "/catchup/:slug",
        title: "Catch Up Channel",
      },
      catchupdetail: {
        url: "/catchup/:slug/:date",
        title: "Catch Up Detail",
      },
      cgu: {
        url: "/cgu",
        title: "About",
      },
      devices: {
        url: "/devices",
        title: "Devices",
      },
      epg: {
        url: "/epg",
        title: "EPG",
      },
      faq: {
        url: "/faq",
        title: "Support",
      },
      favorites: {
        url: "/favorites",
        title: "Favorites",
      },
      history: {
        url: "/history",
        title: "History",
      },
      live: {
        url: "/live",
        title: "Live",
      },
      profiles: {
        url: "/profiles",
        title: "Profiles",
      },
      profile: {
        url: "/profiles/:id",
        title: "Edit Profile",
      },
      account: {
        url: "/account",
        title: "Account",
      },
      player: {
        url: "/player/:assetId",
        title: "Player",
      },
      playerlive: {
        url: "/playerlive/:channelId",
        title: "PlayerLive",
      },
      playerTrailer: {
        url: "/playertrailer/:assetId",
        title: "Player Trailer",
      },
      search: {
        url: "/search/:term?",
        title: "Search results",
      },
      settings: {
        url: "/settings",
        title: "Settings",
      },
      privacy: {
        url: "/privacy-policy",
        title: "Privacy policy",
      },
      subscription: {
        url: "/subscription",
        title: "Subscription",
      },
      vod: {
        url: "/vod",
        detailUrl: "/vod/:id",
        title: "On demand",
      },
      library: {
        url: "/library",
        title: "Library",
      },
      logout: {
        url: "/logout",
        title: "Logout",
      },
      link: {
        url: "/link",
        title: "Link device",
      },
      notfound: {
        url: "*",
        title: "Erreur 404",
      },
    },
    continueWatching: {
      contentTypeFilter: "movie+series",
      allAssets: 0,
      watchingMin: 1,
      watchingMax: 95,
    },
    menuLogo: {
      logoTypeWhenOpen: "SQUARE",
      logoTypeWhenClosed: "SQUARE",
    },
    showSliderGradient: false,
    graphQLGrantType: "password",
    implicitSsoProviderLogo: "",
    implicitSsoProviderName: "",
    implicitSsoAccountActions: [],
    isResetPasswordEnabled: true,
    externalResetPasswordLink: "",
    minimumAgeToRegister: 18,
    sentry: {
      dsn: "https://2762727af94b4b18a0131b97862711ec@o921547.ingest.sentry.io/4504763694841856",
      tracesSampleRate: 1.0,
    },
    sleepForSync: 0,
    "features-availability": {
      "is-npvr-enabled": false,
      "is-tvod-enabled": false,
      "is-download-enabled": false,
      "offers-subscription": "Enabled", //it could be one of these values ["Disabled", "External", "Enabled"]
      "offers-transactional": "Enabled", //it could be one of these values ["Disabled", "Enabled"]
    },
  },
  appConfig,
  externalConfig
);

const prefix = skinConfig.storageKeys.prefix;
skinConfig.image_hostname = skinConfig.image_hostname
  ? skinConfig.image_hostname
  : skinConfig.baseUrl;
window.localStorage.setItem(
  `${prefix}_${"Config"}`,
  JSON.stringify(skinConfig)
);

export default skinConfig;
