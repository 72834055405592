import { addToast, setModalNPVRInfo } from "actions/ui";
import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Dropdown from "components/Dropdown";
import Failed from "components/Icons/Failed";
import Record from "components/Icons/Record";
import Recorded from "components/Icons/Recorded";
import Recording from "components/Icons/Recording";
import Scheduled from "components/Icons/Scheduled";
import consts from "consts/consts";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";

const statusEnum = {
  Failed: "Failed",
  Planned: "Planned",
  Played: "Played",
  Playing: "Playing",
  Record: "Record",
  Recorded: "Recorded",
  Recording: "Recording",
  "Recording + Playing": "Recording + Playing",
};

class RecordActions extends Component {
  recordOptions(type) {
    const { t } = this.props;
    let options = [];
    switch (type) {
      case statusEnum.Recording:
      case statusEnum.Planned:
        options = [{ label: t("Cancel"), actionName: "delete" }];
        break;
      case statusEnum.Recorded:
        options = [{ label: t("Delete"), actionName: "delete" }];
        break;
      case statusEnum.Failed:
        options = [
          { label: t("Delete"), actionName: "delete" },
          { label: t("Retry"), actionName: "retry" },
        ];
        break;
      default:
        break;
    }
    if (
      consts.routes.library.url !== location.pathname &&
      consts.appModules.library
    ) {
      options.push({ label: t("Show Library"), actionName: "library" });
    }
    if (
      consts.routes.epg.url === location.pathname &&
      type === statusEnum.Recorded
    ) {
      options.unshift({ label: t("Play"), actionName: "play" });
    }
    return options;
  }

  getErrorAPI(code) {
    let error = "An error occured,Please retry later";

    switch (code) {
      case -4005:
        error = "Record not found";
        break;
      default:
        break;
    }

    return error;
  }

  buttonsAction(type, event) {
    const { epg, channel, idRecord } = this.props;
    if (
      event &&
      (typeof event.preventDefault === "function" ||
        typeof event.stopPropagation === "function")
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (type === statusEnum.Record) {
      this.createNPVRRecord();
    } else {
      switch (event.actionName) {
        case "delete":
          this.props.dispatch(
            setModalNPVRInfo({
              showModalNPVR: true,
              idRecord: idRecord || epg?.getRecord()?.getIdRecord(),
              npvrChannel: channel?.npvr || 0,
              typeRecordButton:
                (type || epg?.getRecordStatus()) === "Planned"
                  ? "Schedule"
                  : type || epg?.getRecordStatus(),
              isQuotaExceeded: false,
            })
          );
          break;
        case "retry":
          this.retryNPVRRecord();
          break;
        case "library":
          this.props.history.push("/library");
          break;
        case "play":
          this.props.history.push(
            consts.routes.player.url.replace(
              ":assetId",
              idRecord || epg?.getRecord()?.getIdRecord()
            ) + "?source_type=npvr"
          );
          break;
        default:
          break;
      }
    }
  }

  createNPVRRecord() {
    const { dispatch, channel, epg, t } = this.props;
    const currentDate = new Date();
    // Check if channel has npvr equal -1
    if (channel.npvr == -1) {
      this.props.dispatch(
        setModalNPVRInfo({
          showModalNPVR: true,
          idRecord: "",
          npvrChannel: channel.npvr,
          typeRecordButton: "",
          isQuotaExceeded: false,
        })
      );
      return false;
    }
    if (
      !(
        (epg.getStartTime().getTime() <= currentDate.getTime() &&
          currentDate.getTime() <= epg.getEndTime().getTime()) ||
        currentDate.getTime() < epg.getEndTime().getTime()
      )
    ) {
      this.props.dispatch(
        addToast({
          text: t("The program has already finished and cannot be recorded."),
          className: "error",
          duration: 6000,
        })
      );
      return false;
    }
    dispatch(
      TucanoActions.createNPVRRecord({
        idEvent: epg.getEpgId() || epg.getIdEvent(),
        idChannel: channel.getId(),
        context: JSON.stringify(epg.getRawData()),
      })
    )
      .then(async (result) => {
        if (result.records) {
          this.props.dispatch(
            addToast({
              text:
                epg.getStartTime().getTime() <= currentDate.getTime() &&
                currentDate.getTime() <= epg.getEndTime().getTime()
                  ? t("Your record is currently being recorded")
                  : t("Your record has been successfully scheduled"),
              className: "primary",
              duration: 6000,
            })
          );
          await dispatch(TucanoActions.getNPVRRecords());
          await dispatch(TucanoActions.getNpvrQuota());
          if (this.props.refreshPrograms) {
            this.props.refreshPrograms();
          }
        } else if (result.code === -4004) {
          this.props.dispatch(
            setModalNPVRInfo({
              showModalNPVR: true,
              npvrChannel: null,
              idRecord: "",
              typeRecordButton: "",
              isQuotaExceeded: true,
            })
          );
          return false;
        } else {
          this.props.dispatch(
            addToast({
              text: t(this.getErrorAPI(result.code)),
              className: "error",
              duration: 6000,
            })
          );
          return false;
        }
      })
      .catch((_err) => {
        this.props.dispatch(
          addToast({
            text: t(this.getErrorAPI()),
            className: "error",
            duration: 6000,
          })
        );
      });
  }

  retryNPVRRecord() {
    const { epg, dispatch, t } = this.props;
    dispatch(TucanoActions.deleteRecord(epg.getRecord().getIdRecord()))
      .then(async (result) => {
        if (result.newAuthToken) {
          const isRecordCreated = this.createNPVRRecord();
          if (isRecordCreated === false) {
            await dispatch(TucanoActions.getNPVRRecords());
            await dispatch(TucanoActions.getNpvrQuota());
            if (this.props.refreshPrograms) {
              this.props.refreshPrograms();
            }
          }
        } else if (result.code) {
          this.closeModal();
          this.props.dispatch(
            addToast({
              text: t(this.getErrorAPI(result.code)),
              className: "error",
              duration: 6000,
            })
          );
        }
      })
      .catch((_err) => {
        this.closeModal();
        this.props.dispatch(
          addToast({
            text: t(this.getErrorAPI()),
            className: "error",
            duration: 6000,
          })
        );
      });
  }

  generateButton(icon) {
    const { typePage, withTitle, type } = this.props;
    if (withTitle) {
      return (
        <div className={style.buttonContainer}>
          {type === statusEnum.Record ? (
            <Button
              rootClassName={style.roundButton}
              type={ButtonType.BORDERED}
              icon={icon}
              onClick={this.buttonsAction.bind(this, type)}
            >
              <span className={style.textButton}>
                <Trans>{type}</Trans>
              </span>
            </Button>
          ) : (
            <Dropdown
              selfClosing={true}
              label={<Trans>{type}</Trans>}
              iconOnClick={icon}
              handleClick={(event) => this.buttonsAction(type, event)}
              data={this.recordOptions(type)}
              stayOpenOnSelect={true}
              typePage={typePage}
            />
          )}
        </div>
      );
    } else if (!withTitle && type !== statusEnum.Record) {
      return (
        <div
          className={classnames(
            style.buttonContainer,
            style.buttoContainerNoLabel
          )}
        >
          <Dropdown
            iconOnClick={icon}
            selfClosing={true}
            handleClick={(event) => this.buttonsAction(type, event)}
            data={this.recordOptions(type)}
            showContextNPVR={true}
            stayOpenOnSelect={true}
            typePage={typePage}
          />
        </div>
      );
    }
    return (
      <div
        className={classnames(
          style.buttonContainer,
          style.buttoContainerNoLabel
        )}
        onClick={this.buttonsAction.bind(this, type)}
      >
        {icon}
      </div>
    );
  }

  render() {
    const { type, epg, accountStatus } = this.props;

    if (
      !epg ||
      !consts?.["features-availability"]?.["is-npvr-enabled"] ||
      (accountStatus && accountStatus.toLowerCase() === "suspended")
    ) {
      return null;
    }

    switch (type) {
      case statusEnum.Record:
        return this.generateButton(<Record />);
      case statusEnum.Recording:
        return this.generateButton(<Recording />);
      case statusEnum.Recorded:
        return this.generateButton(<Recorded />);
      case statusEnum.Planned:
        return this.generateButton(<Scheduled />);
      case statusEnum.Failed:
        return this.generateButton(<Failed />);
      default:
        return this.generateButton(<Record />);
    }
  }
}

RecordActions.propTypes = {
  type: PropTypes.string,
  withTitle: PropTypes.bool,
  programID: PropTypes.number,
  idRecord: PropTypes.number,
  params: PropTypes.array,
  epg: PropTypes.object,
  channel: PropTypes.object,
  typePage: PropTypes.string,
};

RecordActions.defaultProps = {
  type: statusEnum.Record,
  withTitle: false,
  typePage: "",
};

export default withRouter(
  compose(
    connect((state, _props) => ({
      accountStatus: state.account.user?.data?.status,
    })),
    withTranslation()
  )(RecordActions)
);
