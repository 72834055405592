import { setLoginShow } from "actions/ui";
import BackArrow from "components/Icons/Arrowheading";
import Loader from "components/Loader";
import Player from "components/Player";
import PlayerErrorMessage from "components/PlayerErrorMessage";
import consts from "consts/consts";
import ModalValidateParentalCode from "containers/ModalValidateParentalControl";
import { format } from "date-fns";
import { detect } from "detect-browser";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { generateDeviceId } from "helpers/utility";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

const MODAL_VALIDATE_PARENTAL_CODE = "MODAL_VALIDATE_PARENTAL_CODE";
const MODAL_NEW_SUBSCRIPTION = "MODAL_NEW_SUBSCRIPTION";

class PagePlayer extends Component {
  state = {
    show: false,
    error: null,
    withError: false,
  };

  async checkDevice() {
    // Get Device ID From LocalStorage
    let deviceId = getFromLocal(consts.storageKeys.device);
    let device = { id: deviceId, ...detect() };

    if (this.props.deviceAuthToken) {
      return device;
    }

    // Generate One
    deviceId =
      device.id &&
      device.id !== "" &&
      device.id !== null &&
      device.id !== undefined
        ? device.id
        : generateDeviceId();
    saveInLocal(consts.storageKeys.device, deviceId);

    // Add Device
    await this.props.dispatch(TucanoActions.addDevice(deviceId));

    if (!this.props.deviceAuthToken) {
      throw new Error("Cant Add Device");
    }

    //Get New device stored
    device = { id: deviceId, ...detect() };

    // Auth Device
    await this.props.dispatch(TucanoActions.authDevice(device));

    return device;
  }

  checkMoralityLevel() {
    if (consts.appModules.profiles === true) {
      let activeEPG = this.getActiveEPG();

      if (activeEPG) {
        const moralityLevel = activeEPG.moralityLevel;

        const { activeProfile } = this.props;
        if (
          activeProfile &&
          activeProfile.getMaximumMoralityLevel() >= 1 &&
          activeProfile.getMaximumMoralityLevel() <= 21 &&
          activeProfile.getMaximumMoralityLevel() < moralityLevel
        ) {
          return false;
        }
      }
    }
    return true;
  }

  async fetch() {
    try {
      if (this.props.isConnected) {
        await this.checkDevice();
      }
    } catch (e) {
      return;
    }
    const channelId = parseInt(this.props.match.params.channelId, 10);

    const languageId = getFromLocal("languageId") ?? consts.languageId;
    if (this.props.activeChannel && !this.props.activeChannel.fta) {
      await this.props.dispatch(TucanoActions.loginWebPlayer());
    }
    try {
      await this.props.dispatch(
        TucanoActions.getChannels(undefined, undefined, languageId)
      );
      await this.props.dispatch(
        TucanoActions.getEPG(new Date(), languageId, true)
      );

      if (this.checkMoralityLevel() === false) {
        this.showModal(MODAL_VALIDATE_PARENTAL_CODE);
      } else {
        await this.props.dispatch(
          TucanoActions.getPlayerLiveInfos(
            channelId,
            null,
            null,
            this.props.activeChannel?.fta
          )
        );
      }
    } catch (error) {
      const { dataPlayer, errorTestAsset, errorReadAsset } = this.props;
      if (dataPlayer && dataPlayer.code && dataPlayer.code === -11) {
        this.showModal(MODAL_NEW_SUBSCRIPTION);
      }
      if (dataPlayer && dataPlayer.code && dataPlayer.code !== -11) {
        this.setState({ withError: true, error: dataPlayer.message });
      }
      if (
        errorTestAsset &&
        errorTestAsset.code &&
        errorTestAsset.code !== -11
      ) {
        this.setState({ withError: true, error: errorTestAsset.message });
      }
      if (errorReadAsset) {
        this.setState({ withError: true, error: errorReadAsset.message });
      }
    }
  }

  getActiveEPG() {
    const channelId = parseInt(this.props.match.params.channelId, 10);
    const { liveEPG } = this.props;
    let activeEPG;
    if (liveEPG) {
      activeEPG = liveEPG.find((epg) => {
        return epg.getChannelId() === channelId;
      });
    }

    return activeEPG;
  }

  getPlayerLiveInfos() {
    this.setState({ show: false });
    const channelId = parseInt(this.props.match.params.channelId, 10);
    this.props.dispatch(
      TucanoActions.getPlayerLiveInfos(
        channelId,
        null,
        null,
        this.props.activeChannel?.fta
      )
    );
  }

  goBack() {
    if (this.props.handleclickonback) {
      this.props.handleclickonback();
    } else {
      this.props.history.goBack();
    }
  }

  showModal(modalType, modalSubType) {
    this.setState({
      show: true,
      modalType: modalType,
      modalSubType: modalSubType,
    });

    if (modalType === MODAL_NEW_SUBSCRIPTION) {
      if (this.props.subscriptionModal) {
        this.props.subscriptionModal(this.props.activeChannel);
      }
    }
  }

  componentDidMount() {
    // Player can't be launch if module LIVE is not ENABLED
    if (consts.appModules && consts.appModules.live !== true) {
      this.props.history.push("/");
    }
    if (
      this.props.isConnected ||
      (this.props.activeChannel && this.props.activeChannel.fta)
    ) {
      this.fetch();
    } else {
      this.props.dispatch(setLoginShow(true));
    }
  }

  componentDidUpdate(prevProps, _prevState) {
    // this causes two asynchronous function calls which must respect the sequence API call
    // if (
    //   prevProps.deviceAuthToken !== this.props.deviceAuthToken &&
    //   prevProps.deviceAuthToken === false &&
    //   this.props.deviceAuthToken === true
    // ) {
    //   this.fetch();
    // }
    if (
      this.props.accountStatus &&
      this.props.accountStatus.toLowerCase() === "suspended"
    ) {
      this.props.history.push("/");
    }

    const channelId = parseInt(this.props.match.params.channelId, 10);
    const prevChannelId = parseInt(prevProps.match.params.channelId, 10);

    if (prevChannelId !== channelId) {
      // We only need to refresh player informations
      this.props.dispatch(TucanoActions.resetPlayerInfos());

      if (this.checkMoralityLevel() === false) {
        this.showModal(MODAL_VALIDATE_PARENTAL_CODE);
      }
      this.fetch();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(TucanoActions.resetPlayerInfos());
    this.props.dispatch(TucanoActions.resetEPG());
  }

  render() {
    const {
      isConnected,
      liveEPG,
      channels,
      isLoading,
      casAddDevice,
      playerInfos,
      activeChannel,
      nextEPG,
      previousRoute,
      t,
      viewport,
      accountDetail,
      isLoadingLicense,
    } = this.props;
    let activeEPG = this.getActiveEPG();
    if (casAddDevice && casAddDevice.code && casAddDevice.code === -30) {
      return (
        <PlayerErrorMessage
          title="maxSessionTitle"
          message="maxSessionMessage"
          closeAction={() => {
            this.props.history.replace(previousRoute ? previousRoute : "/");
          }}
        />
      );
    }
    if (accountDetail && accountDetail.status === "INACTIVE") {
      return (
        <PlayerErrorMessage
          title="Account suspended"
          message="Your account has been suspended,Please contact our customer service"
          closeAction={() => {
            this.props.history.replace(previousRoute ? previousRoute : "/");
          }}
        />
      );
    }

    const width = 160;
    const height = 90;
    return (
      <div className={style.root}>
        {this.state.show &&
          this.state.modalType === MODAL_VALIDATE_PARENTAL_CODE && (
            <ModalValidateParentalCode
              onCloseClick={this.goBack.bind(this)}
              onCancelClick={this.goBack.bind(this)}
              onConfirmClick={this.getPlayerLiveInfos.bind(this)}
            />
          )}
        {(isLoading || isLoadingLicense) &&
          !this.state.withError &&
          activeChannel && (
            <Fragment>
              <div className={style.headerContainer}>
                <BackArrow
                  className={style.headerBack}
                  onClick={() => {
                    this.props.history.replace(
                      previousRoute
                        ? previousRoute?.pathname ===
                          this.props.location.pathname
                          ? "/"
                          : previousRoute
                        : "/"
                    );
                  }}
                />
                {/* {activeChannel && (
                <div className={style.headerLogo}>
                  <Image
                    src={activeChannel.getImage(consts.image_hostname, {
                      // width: 80,
                      // height: 80,
                      languageId: getFromLocal("languageId"),
                    })}
                    alt="Channel logo"
                    placeholder={PlaceholderType.TRANSPARENT}
                  />
                </div>
              )}
              <div className={style.headerTexts}>
                {activeEPG && (
                  <p className={style.headerTitle}>{activeEPG.getTitle()}</p>
                )}
                {activeEPG && (
                  <p className={style.headerSubtitle}>
                    {format(activeEPG.getStartTime(), "HH:mm")} -{" "}
                    {format(activeEPG.getEndTime(), "HH:mm")} (
                    {activeEPG.getHumanDuration()}){" "}
                  </p>
                )}
                {activeEPG && activeEPG.getRating() !== 0 && (
                  <p className={style.headerRating}>
                    {" "}
                    | {activeEPG.getRating()}
                  </p>
                )}
              </div> */}
              </div>
              <Loader centered={true} />
            </Fragment>
          )}
        {this.state.withError && (
          <PlayerErrorMessage
            closeAction={() => {
              this.props.history.replace(previousRoute ? previousRoute : "/");
            }}
            message={this.state.error ? this.state.error : null}
          />
        )}
        {!isLoading && !isLoadingLicense && (
          <div className={style.root}>
            {playerInfos && (
              <Player
                isFullscreen={this.props.location.state?.fullScreen || null}
                isConnected={isConnected}
                isLive={true}
                width={width}
                height={height}
                assetInfo={{
                  title: activeEPG
                    ? activeEPG.getTitle()
                    : activeChannel && activeChannel.getSynopsis()
                    ? activeChannel.getSynopsis()
                    : t("No information available"),
                  subtitle:
                    activeEPG &&
                    `${format(activeEPG.getStartTime(), "HH:mm")} - ${format(
                      activeEPG.getEndTime(),
                      "HH:mm"
                    )} (${activeEPG.getHumanDuration()})`,
                  rating: activeEPG ? activeEPG.getRating() !== 0 : "",
                  channel: activeChannel,
                  startTime: activeEPG && activeEPG.getStartTime(),
                  endTime: activeEPG && activeEPG.getEndTime(),
                  startoverEnabled: activeEPG && activeEPG.startoverEnabled,
                  nextEPG: {
                    imageUrl:
                      nextEPG &&
                      nextEPG.getImage(consts.image_hostname, {
                        width: 764,
                        height: 430,
                        languageId: getFromLocal("languageId"),
                      }),
                    title: nextEPG && nextEPG.getTitle(),
                    timeslot:
                      nextEPG &&
                      `${format(nextEPG.getStartTime(), "HH:mm")} - ${format(
                        nextEPG.getEndTime(),
                        "HH:mm"
                      )}`,
                  },
                }}
                activeEPG={activeEPG}
                drm={playerInfos.drm}
                url={playerInfos.url}
                authToken={this.props.authToken}
                liveEPG={liveEPG}
                channels={channels}
                previousRoute={previousRoute}
                viewport={viewport}
                timeshift={activeChannel && activeChannel.timeshift}
                t={t}
              />
            )}
            {!playerInfos.url && (
              <p className={style.errorMessage}>
                <Trans>ERROR</Trans>
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect((state, props) => {
    const channelId = props.match.params.channelId;
    return {
      isLoading: state.player.loading,
      isLoadingLicense: state.player.loadingLicense,
      previousRoute: state.ui.previousRoute,
      dataPlayer: state.player.data,
      accountDetail: state.account.user.data,
      activeChannel: TucanoSelectors.getChannelById(state, channelId),
      liveEPG: TucanoSelectors.getLive(state),
      channels: TucanoSelectors.getEPG(state),
      playerInfos: TucanoSelectors.getPlayerInfos(state),
      nextEPG: TucanoSelectors.getNextEPGForChannelId(state, channelId),
      isConnected: state.session.customerAuthToken !== undefined,
      deviceAuthToken: state.session.deviceAuthToken !== undefined,
      viewport: state.ui.viewport,
      activeProfile: TucanoSelectors.getActiveProfile(state),
      casAddDevice: state.account.casAddDevice.data,
      errorBuyAsset: state.player.errorBuyAsset,
      errorTestAsset: state.player.errorTestAsset,
      errorReadAsset: state.player.errorReadAsset,
      isTestAssetLoading: state.player.testAsset.loading,
      accountStatus: state.account.user?.data?.status,
    };
  })
)(PagePlayer);
