import classnames from "classnames";
import Checkbox from "components/Checkbox";
import ContentWrapper from "components/ContentWrapper";
import Header from "components/Header";
import ArrowHeading from "components/Icons/Arrowheading";
import Modal from "components/Modal";
import consts from "consts/consts";
import FormChangePurchaseCode from "containers/FormChangePurchaseCode";
import ModalValidatePurchaseCode from "containers/ModalValidatePurchaseCode";
import PageConsent from "containers/PageConsent";
import React, { Component } from "react";
import Collapsible from "react-collapsible";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";
class PageSettings extends Component {
  state = {
    showEditPurchaseCode: undefined,
    showPurchaseControl: false,
    useCode: null,
    manageGpdr: false,
  };

  async componentDidMount() {
    const { isConnected } = this.props;

    if (isConnected) {
      this.props.dispatch(TucanoActions.getAccountDetails());
      const { accountDetail } = this.props;
      if (accountDetail) {
        await this.setState({ useCode: accountDetail?.hasPurchaseCode });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.accountDetail?.hasPurchaseCode !==
      this.props?.accountDetail?.hasPurchaseCode
    ) {
      this.setState({ useCode: this.props.accountDetail?.hasPurchaseCode });
    }
  }

  togglePurchaseCodeModal = () => {
    this.setState({
      showEditPurchaseCode: !this.state.showEditPurchaseCode,
      useCode: this.props.accountDetail?.hasPurchaseCode,
    });
  };

  toggleUseCode = async () => {
    if (this.props.accountDetail?.hasPurchaseCode) {
      this.toggleModalPurchaseControl();
    } else {
      this.togglePurchaseCodeModal();
    }
  };

  toggleModalPurchaseControl = (pin) => {
    this.setState(
      (prevState) => ({
        showPurchaseControl: !prevState.showPurchaseControl,
      }),
      () => {
        if (
          this.props.accountDetail?.hasPurchaseCode &&
          !this.state.showPurchaseControl
        ) {
          this.props
            .dispatch(TucanoActions.resetPurchaseCode(pin))
            .then(async (purchaseCodeResult) => {
              if (purchaseCodeResult.newAuthToken) {
                await this.props.dispatch(TucanoActions.getAccountDetails());
              }
              this.setState({
                useCode: this.props.accountDetail?.hasPurchaseCode,
              });
            });
        }
      }
    );
  };

  closeModalPurchaseCode = () => {
    this.setState(
      { showPurchaseControl: !this.state.showPurchaseControl },
      () => {
        this.setState({ useCode: true }, () => {
          // FIXME: setPurchaseCode
        });
      }
    );
  };

  showManageGpdrModal = (show) => {
    this.setState({ manageGpdr: show });
  };

  render() {
    const { t, isConnected, history, accountDetail } = this.props;
    const { manageGpdr, showEditPurchaseCode, showPurchaseControl } =
      this.state;

    return (
      <ContentWrapper>
        <div className={style.root}>
          <div className={style.setContainer}>
            <Header rootClassName={style.header}>
              <Trans>Settings</Trans>
            </Header>
            {isConnected && consts.purchasePin && (
              <Collapsible
                open={true}
                trigger={
                  <div className={style.arrowContainer}>
                    <span className={style.categoryTitle}>
                      <Trans>Payment</Trans>
                    </span>
                    <ArrowHeading className={style.arrow} />
                  </div>
                }
              >
                <div className={style.switchElement}>
                  <Checkbox
                    change={this.toggleUseCode}
                    checked={this.state.useCode}
                    type={"SWITCH"}
                    label={<Trans>Use a purchase code</Trans>}
                    rootClassName={style.switch}
                  />
                  <p className={style.subTitle}>
                    <Trans>
                      Your purchase code will be requested to confirm your
                      purchase
                    </Trans>
                  </p>
                </div>
                {accountDetail?.hasPurchaseCode && (
                  <div className={style.switchElement}>
                    <div className={style.element}>
                      <p className={style.textContent}>
                        <Trans>Change purchase code</Trans>
                      </p>
                      <p
                        className={classnames(style.change, style.cursor)}
                        onClick={this.togglePurchaseCodeModal}
                      >
                        Change
                      </p>
                    </div>
                  </div>
                )}
              </Collapsible>
            )}
            {consts.appModules.gdpr &&
              consts.gdprConfig &&
              (typeof consts.gdprConfig === "string"
                ? JSON.parse(consts.gdprConfig)
                : JSON.parse(JSON.stringify(consts.gdprConfig))) && (
                <Collapsible
                  open={true}
                  trigger={
                    <div className={style.arrowContainer}>
                      <span className={style.categoryTitle}>
                        <Trans>Cookies and other technologies</Trans>
                      </span>
                      <ArrowHeading className={style.arrow} />
                    </div>
                  }
                >
                  <div className={style.switchElement}>
                    <div className={style.gdprDesc}>
                      <p>
                        <Trans>
                          The privacy and the security of your information is
                          important to us.
                        </Trans>
                      </p>

                      <p>
                        <Trans>
                          When you interact with our service, we and our
                          partners may collect certain information and some of
                          them might be collected automatically as your
                          interactions with activities.
                        </Trans>{" "}
                        <Trans>
                          We use this information to improve our services and to
                          customise the experience according to your interests.
                        </Trans>
                      </p>

                      <p>
                        <Trans>You can manage your consent at any time.</Trans>
                      </p>

                      <p>
                        <Trans>
                          Please note, however, that if you refuse to accept
                          cookies and our other technologies, you might not be
                          able to use all of the features we offer and some of
                          our pages might not display properly.
                        </Trans>
                      </p>
                    </div>
                    <div>
                      <p
                        className={classnames(style.change, style.cursor)}
                        onClick={() => this.showManageGpdrModal(true)}
                      >
                        <Trans>Manage</Trans>
                      </p>
                    </div>
                  </div>
                </Collapsible>
              )}
            <Collapsible
              open={true}
              trigger={
                <div className={style.arrowContainer}>
                  <span className={style.categoryTitle}>
                    <Trans>Build information</Trans>
                  </span>
                  <ArrowHeading className={style.arrow} />
                </div>
              }
            >
              <div className={style.switchElement}>
                <div className={style.element}>
                  <p className={style.textContent}>
                    <Trans>Version</Trans>
                  </p>
                  <p className={style.change}>{consts.VERSION}</p>
                </div>
              </div>
            </Collapsible>
          </div>
          {showEditPurchaseCode && (
            <Modal
              size={"sm"}
              leftContent={{
                title: t(
                  `${
                    accountDetail?.hasPurchaseCode ? "Change" : "Add"
                  } purchase code`
                ),
              }}
              rightContent={{ canDismiss: this.togglePurchaseCodeModal }}
              rootClassName={style.modal}
              overlay={true}
            >
              <FormChangePurchaseCode
                closeModal={this.togglePurchaseCodeModal}
                rootClassName={style.modal}
                hasPurchaseCode={accountDetail?.hasPurchaseCode}
              />
            </Modal>
          )}
          {showPurchaseControl === true && (
            <ModalValidatePurchaseCode
              onCloseClick={this.closeModalPurchaseCode}
              onConfirmClick={this.toggleModalPurchaseControl}
            />
          )}
          {manageGpdr && (
            <Modal
              rootClassName={style.modalGdpr}
              leftContent={{ title: t("Cookies and other technologies") }}
              rightContent={{
                canDismiss: this.showManageGpdrModal.bind(this, false),
              }}
              overlay={true}
            >
              <PageConsent
                handleActions={this.showManageGpdrModal.bind(this, false)}
                isManage={true}
                onlyGdprList={true}
                history={history}
              />
            </Modal>
          )}
        </div>
      </ContentWrapper>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      accountDetail: state.account.user.data,
      isConnected: state.session.customerAuthToken !== undefined,
    };
  }),
  withTranslation()
)(PageSettings);
