import { TucanoActions } from "web-api/main";

import { addToast, removeToast } from "../actions/ui";
import consts from "../consts/consts";
import { getFromLocal, removeFromLocal } from "./localStorage";

const CHECK_ORDER_STATUS_TIMER = 15000;

export function generateDeviceId() {
  const u =
    Date.now().toString(16) + Math.random().toString(16) + "0".repeat(16);
  const guid = [
    u.substr(0, 8),
    u.substr(8, 4),
    "4000-8" + u.substr(13, 3),
    u.substr(16, 12),
  ].join("-");
  return guid;
}

export const CapitalizeFirstLetterObject = (str) => {
  return str.props.children.length
    ? str.props.children.charAt(0).toUpperCase() + str.props.children.slice(1)
    : str.props.children;
};
export const CapitalizeObject = (str) => {
  return str.props.children.toUpperCase();
};
export const CapitalizeFirstLetter = (str) => {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};
export const Capitalize = (str) => {
  return str.toUpperCase();
};

const objectsEqual = (o1, o2) => {
  return typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;
};

/**
 * @desc Compare two arrays and return if they are identical or not
 * @param {Array} arrA `First array to compare`
 * @param {Array} arrB `Seccond array to compare`
 * @returns Boolean
 */
export const compareTwoArrays = (arrA = [], arrB = []) => {
  const sortedArrayA = arrA.sort();
  const sortedArrayB = arrB.sort();
  return (
    sortedArrayA.length === sortedArrayB.length &&
    sortedArrayA.every((o, idx) => objectsEqual(o, sortedArrayB[idx]))
  );
};

export const formatPrice = (price, priceAfter, priceDecimals, currency) => {
  const formattedPrice = parseFloat(price).toFixed(priceDecimals);
  if (priceAfter) {
    return `${currency} ${formattedPrice}`;
  }
  return `${formattedPrice}${currency}`;
};
export const renameObjectKey = (o, old_key, new_key) => {
  if (
    old_key !== new_key &&
    typeof o === "object" &&
    // eslint-disable-next-line no-prototype-builtins
    !o.hasOwnProperty(new_key)
  ) {
    Object.defineProperty(
      o,
      new_key,
      Object.getOwnPropertyDescriptor(o, old_key)
    );
    delete o[old_key];
  }
  return o;
};

export const Translate = (props) => {
  const { t, children } = props;
  return t(children);
};

export const TranslateDynamic = (props) => {
  const { t, message, value } = props;
  return t(message.replace("{{value}} ", ""), { value: value });
};

export const showToast = (props, toastParams) => {
  for (var i = 0; i < props.toasts.length; i++) {
    if (props.toasts[i].text.includes(toastParams.text)) {
      props.dispatch(removeToast(props.toasts[i].id));
    }
  }
  // Wait componentDidMount for all other page
  let found = props.toasts.find((element) =>
    element.text.includes(toastParams.text)
  )
    ? true
    : false;
  if (!found) {
    props.dispatch(addToast(toastParams));
  }
};

export const redirectTo = (props) => {
  if (new RegExp("W*(profiles)/(.{0,})W*").test(props.location.pathname)) {
    props.history.replace("/profiles");
  } else if (new RegExp("W*(vod)/(.{0,})W*").test(props.location.pathname)) {
    props.history.replace("/vod");
  } else if (
    new RegExp("W*(catchup)/(.{0,})W*").test(props.location.pathname)
  ) {
    props.history.replace("/catchup");
  } else if (
    new RegExp("W*(playerlive)/(.{0,})W*").test(props.location.pathname)
  ) {
    props.history.replace("/live");
  } else {
    props.history.replace(props.previousStableRoute.pathname);
  }
};

export const checkForNullEmptyAndUndefinedValues = (object) =>
  Object.values(object).map((object) => {
    if (
      object === null ||
      object === undefined ||
      (typeof value === "string" && !(object || false)) ||
      object == false
    ) {
      return true;
    }
    return false;
  });

export const checker = (arr) => arr.every((v) => v === false);

// subscription utility
let timer = null;
const callCheckOrderEndpoint = (dispatcher, tucanoOrderId) => {
  dispatcher(TucanoActions.checkOrderStatus(tucanoOrderId)).then(
    async (result) => {
      if (
        result.orderStatus === "executed" ||
        result.orderStatus === "cancelled"
      ) {
        if (typeof window.paymentPopup !== "undefined") {
          window.paymentPopup.close();
        }
        clearInterval(timer);
        removeFromLocal("TucanoOrderId");
        await dispatcher(
          TucanoActions.getListOptions(getFromLocal("languageId"))
        );
        const productId = getFromLocal("productId") || consts.productId;
        dispatcher(TucanoActions.getOptionValidity(productId));
        if (
          consts?.["features-availability"]?.["offers-transactional"] ===
          transactionalOffersStatus.Enabled
        ) {
          dispatcher(
            TucanoActions.getActiveAssets({
              languageId: getFromLocal("languageId"),
              allAssets: 0,
              commercialModel: "TVOD",
              contentTypeFilter: "movie+series",
            })
          );
        }

        dispatcher(
          TucanoActions.getActiveAssets({
            languageId: getFromLocal("languageId"),
            allAssets: 1,
            count: 1000,
            watchingMax: 95,
            contentTypeFilter: "movie+series",
          })
        );
      }
    }
  );
};

export const checkOrderstatus = (dispatcher) => {
  var tucanoOrderId = getFromLocal("TucanoOrderId");
  if (typeof tucanoOrderId !== "undefined" || tucanoOrderId !== null) {
    if (tucanoOrderId !== null) {
      timer = setInterval(() => {
        callCheckOrderEndpoint(dispatcher, tucanoOrderId);
      }, CHECK_ORDER_STATUS_TIMER);
    }
  }
};

export function isIOS() {
  return /\b(iPad|iPhone|iPod)\b/.test(navigator.userAgent);
}

export function isIpadOS() {
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform)
  );
}

export function checkLanguageSupport(languageId) {
  const isLanguageSupported = consts?.enum?.languageId?.some(
    (l) => l.value === languageId
  );
  if (!isLanguageSupported) {
    return consts.languageId;
  }
  return languageId;
}

export const subscriptionOffersStatus = {
  Disabled: "Disabled",
  External: "External",
  Enabled: "Enabled",
};
export const transactionalOffersStatus = {
  Disabled: "Disabled",
  Enabled: "Enabled",
};
