import { formatFullDateAndTime } from "../helpers/timeHelper";

export default class OptionAvailability {
  constructor(data) {
    let item = data;

    if (item) {
      this.idOption = item.idOption;
      this.name = item.name;
      this.synopsis = item.synopsis;
      this.validFrom = item.validFrom;
      this.validTo = item.validTo;
      this.displayFrom = item.displayFrom;
      this.displayUntil = item.displayUntil;
      this.priceWithoutVat = item.priceWithoutVat;
      this.priceWithVat = item.priceWithVat;
      this.vatRate = item.vatRate;
      this.vatAmount = item.vatAmount;
      this.recurrenceUnit = item.priceDetail.recurrenceUnit;
      this.recurrencePeriod = item.priceDetail.recurrencePeriod;
      this.isPerPersonUnique = item.isPerPersonUnique;
      this.type = item.type;
      this.objects = item.objectIds;
      this.promotions = item.promotions;
      this.setupPrice = item?.priceDetail?.setup || 0;
    }
  }

  getIdOption() {
    return this.idOption;
  }

  getName() {
    return this.name;
  }

  getSynopsis() {
    return this.synopsis;
  }

  getDisplayFrom() {
    return this.displayFrom;
  }

  getDisplayUntil() {
    return this.displayUntil;
  }

  getValidFrom() {
    return this.validFrom;
  }

  getValidTo() {
    return this.validTo;
  }

  getFormattedDisplayFrom() {
    return formatFullDateAndTime(new Date(this.displayFrom * 1000));
  }

  getFormattedDisplayUntil() {
    return formatFullDateAndTime(new Date(this.displayUntil * 1000));
  }

  getFormattedValidFrom() {
    return formatFullDateAndTime(new Date(this.validFrom * 1000));
  }

  getFormattedValidTo() {
    return formatFullDateAndTime(new Date(this.validTo * 1000));
  }

  getPriceWithoutVat() {
    return this.priceWithoutVat;
  }

  getPriceWithVat() {
    return this.priceWithVat;
  }

  getSetupPrice() {
    return this.setupPrice;
  }

  getVatRate() {
    return this.vatRate;
  }

  getVatAmount() {
    return this.vatAmount;
  }

  getRecurrenceUnit() {
    return this.recurrenceUnit;
  }

  getRecurrencePeriod() {
    return this.recurrencePeriod;
  }

  isUnique() {
    return this.isPerPersonUnique;
  }

  getType() {
    return this.type;
  }

  getLinkObjects() {
    return this.objects;
  }

  getPromotions() {
    return this.promotions;
  }
}
