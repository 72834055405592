import React, { Component } from "react";

class AutoClassification extends Component {
  render() {
    const { morality, top = 30 } = this.props;
    return (
      <img
        style={{
          width: "18px",
          height: "18px",
          position: "relative",
          transform: `translateY(${top}%)`,
        }}
        src={`/assets/${morality}.png`}
      />
    );
  }
}

export default AutoClassification;
