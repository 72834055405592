import { setLivePosition, setNewPosition } from "actions/ui";
import classnames from "classnames";
import { CapitalizeObject } from "helpers/utility";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import style from "./style.module.css";
class ProgressBar extends Component {
  constructor() {
    super();
    this.container = React.createRef();
    this.draggable = React.createRef();
    this.liveIndicatorRef = React.createRef();
    this.dragging = undefined;
    this.initialPosition = undefined;
    this.state = {
      isDragging: false,
      newProgressPosition: null,
    };
  }

  async componentDidMount() {
    const knob = this.draggable.current;
    const liveIndicatorInst = this.liveIndicatorRef.current;
    knob.addEventListener("mousedown", this.mouseDownHandle.bind(this));
    liveIndicatorInst.addEventListener(
      "mousedown",
      this.mouseDownHandle.bind(this)
    );
    document.addEventListener("mousemove", this.mouseMoveHandle.bind(this));
    document.addEventListener("mouseup", this.mouseUpHandle.bind(this));
    knob.addEventListener("touchstart", this.mouseDownHandle.bind(this));
    liveIndicatorInst.addEventListener(
      "touchstart",
      this.mouseDownHandle.bind(this)
    );
    document.addEventListener("touchend", this.mouseUpHandle.bind(this));
    document.addEventListener("touchmove", this.mouseMoveHandle.bind(this));
  }

  componentWillUnmount() {
    const knob = this.draggable.current;
    const liveIndicatorInst = this.liveIndicatorRef.current;
    if (document) {
      document.removeEventListener(
        "mousemove",
        this.mouseMoveHandle.bind(this)
      );
      document.removeEventListener("mouseup", this.mouseUpHandle.bind(this));
      document.removeEventListener("touchend", this.mouseUpHandle.bind(this));
      document.removeEventListener(
        "touchmove",
        this.mouseMoveHandle.bind(this)
      );
    }
    if (knob) {
      knob.removeEventListener("mousedown", this.mouseDownHandle.bind(this));
      knob.removeEventListener("touchstart", this.mouseDownHandle.bind(this));
    }
    if (liveIndicatorInst) {
      liveIndicatorInst.removeEventListener(
        "mousedown",
        this.mouseDownHandle.bind(this)
      );
      liveIndicatorInst.removeEventListener(
        "touchstart",
        this.mouseDownHandle.bind(this)
      );
    }
  }

  resetDragState = () => {
    setTimeout(() => {
      this.setState({
        isDragging: false,
        newProgressPosition: null,
      });
    }, 500);
  };

  mouseDownHandle(e) {
    if (!this.dragging) {
      this.dragging = true;
      this.initialPosition = e.x || e.touches[0].screenX;
    }
  }

  mouseUpHandle(event) {
    event.stopPropagation();
    if (this.dragging) {
      // Handle click behaviour
      this.handleOnClick(event);
      // Cancel drag behaviour
      this.dragging = false;
    }
  }

  mouseMoveHandle(e) {
    if (!this.dragging || !this.draggable) {
      return;
    }
    const { position } = this.props;
    const containerPosition = this.container.current.getBoundingClientRect();
    const dragPosition = e.x || e.touches[0].screenX;
    const relativeDragPosition = dragPosition - containerPosition.x;
    const newPosition = (relativeDragPosition / containerPosition.width) * 100;
    this.setState({
      isDragging: true,
      newProgressPosition:
        newPosition < 0 ? 0 : newPosition > position ? position : newPosition,
    });
  }

  async handleOnClick(event) {
    event.stopPropagation();
    const { onClick, position, timeshift } = this.props;
    if (onClick && timeshift > 0) {
      const containerPosition = this.container.current.getBoundingClientRect();
      const clickPosition = event.clientX - containerPosition.x;
      const newPosition = (clickPosition / containerPosition.width) * 100;
      if (newPosition <= position) {
        await this.props.dispatch(setNewPosition(newPosition));
        await this.props.dispatch(setLivePosition(position));
        onClick(newPosition, true, position, this.resetDragState);
      } else if (newPosition > position) {
        this.handleOnClickLiveIcon(event);
      }
    }
  }
  handleOnClickLiveIcon(event) {
    event.stopPropagation();
    const { onClick, info, timeshift } = this.props;
    if (onClick && timeshift > 0) {
      const duration =
        new Date(info.endTime).getTime() - new Date(info.startTime).getTime();
      const position =
        ((new Date().getTime() - new Date(info.startTime).getTime()) /
          duration) *
        100;
      this.props.dispatch(setNewPosition(position));
      this.props.dispatch(setLivePosition(position));
      onClick(position, true, position, this.resetDragState);
    }
  }

  render() {
    const {
      rootClassName,
      info,
      draggable,
      pausePosition,
      timeshift,
      newPosition,
      livePosition,
    } = this.props;
    const { isDragging, newProgressPosition } = this.state;
    let currentProgress;
    const duration =
      new Date(info.endTime).getTime() - new Date(info.startTime).getTime();
    const position =
      ((new Date().getTime() - new Date(info.startTime).getTime()) / duration) *
      100;
    if (pausePosition) {
      currentProgress =
        newPosition + (position - livePosition) - (position - pausePosition);
    } else {
      currentProgress = newPosition + (position - livePosition);
    }
    currentProgress = currentProgress < 0 ? 0 : currentProgress;
    let progressBarWidth =
      (isDragging ? newProgressPosition : currentProgress) * (100 / position);
    let draggableWidth =
      (position - (isDragging ? newProgressPosition : currentProgress)) *
      (100 / position);
    return (
      <div
        className={classnames(style.container, rootClassName, {
          [style.draggable]: draggable,
        })}
        ref={this.container}
      >
        {currentProgress >= 0 ? (
          <>
            <div
              className={style.progressBarContainer}
              style={{ width: `${position > 100 ? 100 : position}%` }}
              onClick={this.handleOnClick.bind(this)}
            >
              <div
                className={style.progressBar}
                style={{
                  width: `${progressBarWidth > 100 ? 100 : progressBarWidth}%`,
                  float: "left",
                }}
              />
              {draggable && (
                <>
                  <div
                    className={style.progressBarWhite}
                    style={{
                      width: `${draggableWidth > 100 ? 100 : draggableWidth}%`,
                      float: "right",
                    }}
                  />
                  <div style={{ clear: "both" }} />
                </>
              )}
            </div>
          </>
        ) : (
          <div
            className={style.progressBar}
            style={{
              width: `${
                isDragging
                  ? newProgressPosition
                  : position > 100
                  ? 100
                  : position
              }%`,
            }}
          />
        )}
        <div
          ref={this.liveIndicatorRef}
          className={
            draggable && currentProgress < position
              ? style.liveProgressWhite
              : style.liveProgress
          }
          style={{
            left: `${position > 100 ? 100 : position}%`,
            userSelect: "none",
            zIndex: 9,
          }}
          onClick={this.handleOnClickLiveIcon.bind(this)}
        >
          {CapitalizeObject(<Trans>live</Trans>)}
        </div>
        <div
          ref={this.draggable}
          className={style.draggableHandler}
          style={{
            left: `${isDragging ? newProgressPosition : currentProgress}%`,
            userSelect: "none",
            display:
              (draggable && currentProgress + 1 <= position && timeshift > 0) ||
              isDragging
                ? "block"
                : "none",
          }}
        />
      </div>
    );
  }
}
export default compose(
  connect((state) => {
    return {
      newPosition: state.ui.newPosition,
      pausePosition: state.ui.pausePosition,
      livePosition: state.ui.livePosition,
    };
  })
)(ProgressBar);

ProgressBar.defaultProps = {
  position: 0,
  draggable: false,
};

ProgressBar.propTypes = {
  position: PropTypes.number,
  draggable: PropTypes.bool,
};
