import { getFromLocal } from "helpers/localStorage";
import React, { Component } from "react";

import style from "./style.module.css";

export default class Table extends Component {
  render() {
    const { column, data } = this.props;
    const direction = getFromLocal("layoutDirection");
    let tableData = [];

    if (column && data) {
      data.forEach((item) => {
        let value = [];
        let param = null;

        column.forEach((elem) => {
          param = {
            value: item[elem.path],
            style: elem.style ? elem.style : "",
          };

          if (elem.hasOwnProperty("actionButton")) {
            param.value = item;
            param.actionButton = elem.actionButton;
          }

          value.push(param);
        });

        tableData.push(value);
      });
    }

    return (
      <div className={style.container}>
        <table className={style.table}>
          <thead>
            <tr>
              {column &&
                column.map((item, index) => {
                  return (
                    <th
                      key={index}
                      className={
                        direction.toLocaleLowerCase() === "rtl"
                          ? style.reverseTh
                          : ""
                      }
                    >
                      {item.label}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    {item.map((elem, idx) => {
                      if (elem.hasOwnProperty("actionButton")) {
                        return (
                          <td className={style[elem.style]} key={idx}>
                            <div className={style.actionButton}>
                              {elem.actionButton &&
                                (elem.value.showActionBtn ||
                                  !elem.value.hasOwnProperty(
                                    "showActionBtn"
                                  )) &&
                                elem.actionButton.map((action, idAction) => {
                                  return (
                                    <span
                                      key={idAction}
                                      onClick={action.action.bind(
                                        this,
                                        elem.value[action.path]
                                      )}
                                    >
                                      {action.icon}
                                    </span>
                                  );
                                })}
                            </div>
                          </td>
                        );
                      } else {
                        return (
                          <td className={style[elem.style]} key={idx}>
                            {elem.value}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}
