import { addToast, setLoginShow } from "actions/ui";
import classNames from "classnames";
import Button, { ButtonType } from "components/Button";
import Checkbox from "components/Checkbox";
import ContentWrapper from "components/ContentWrapper";
import Dropdown from "components/Dropdown";
import Header from "components/Header";
import About from "components/Icons/About";
import CheckIcon from "components/Icons/Check";
import CloseIcon from "components/Icons/Close";
import InfoIcon from "components/Icons/Info";
import Input, { InputType } from "components/Input";
import { LinkDuo } from "components/LinkDuo";
import Logo, { LogoTypes } from "components/LogoNav";
import Modal from "components/Modal";
import RadioCard from "components/RadioCard";
import StepProgressBar from "components/StepProgressBar";
import SubscriptionItem from "components/SubscriptionItem";
import SwitchLanguage from "components/SwitchLanguage";
import VoucherZone from "components/VoucherZone";
import consts from "consts/consts";
import FormChangePurchaseCode from "containers/FormChangePurchaseCode";
import ModalValidatePurchaseCode from "containers/ModalValidatePurchaseCode";
import IngenicoModel from "helpers/ingenicoModel";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { checkPolicy, isValidPolicy } from "helpers/Password";
import {
  checkOrderstatus,
  isIOS,
  isIpadOS,
  TranslateDynamic,
} from "helpers/utility";
import { CapitalizeFirstLetter, Translate } from "helpers/utility";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

/*const Select = (props) => (
  <SelectCustom {...props} SelectComponent={BaseSelect} />
);*/

class PageSignUp extends Component {
  constructor(props) {
    super(props);
    this.paymentForm = null;
    this.previousUrl = null;
    const config = getFromLocal("Config");
    let form = Object.values(config.steps).reduce((start, current) => {
      let step = current;
      if (current.subSteps && current.subSteps.length > 0) {
        for (let i = 0; i < current.subSteps.length; i++) {
          let data = {};
          if (current.subSteps[i].data && current.subSteps[i].data.length > 0) {
            Object.entries(current.subSteps[i].data).map(([_key, value]) => {
              return (data[value.key] = value.value);
            });
          }
          step.subSteps[i].data = data;
        }
        start.push(step);
      } else {
        start.push(current);
      }
      return start;
    }, []);

    this.state = {
      currentStep: 0,
      currentSubStep: 0,
      steps: form,
      productId: getFromLocal("productId") || consts.productId,
      isNextWithError: false,
      languageIdMapping: consts.languageIdMapping,
      language: getFromLocal("languageId"),
      optionWithVoucher: false,
      saveMethod: true,
      useCode: false,
      validPasswordPolicies: [],
      showEditPurchaseCode: false,
      showPurchaseControl: false,
      showConfirmEmail: false,
      errorFields: [],
      firstDropDown: false,
      autoComplete: {
        title: "honorific-prefix",
        firstName: "given-name",
        lastName: "family-name",
        dateofbirth: "bday",
        languageId: "language",
        email: "email",
        emailConfirm: "email",
        phoneNumber: "tel",
        acceptTerms: "none",
        allowNews: "none",
        password: "password",
        passwordConfirm: "password",
        addressNumber: "none",
        addressStreet: "address-line1",
        addressSecondStreet: "address-line2",
        addressThirdStreet: "address-line3",
        addressCity: "locality",
        addressZip: "postal-code",
        addressState: "region",
        addressCountry: "country",
        name: "none",
        addressTitle: "none",
        addressType: "none",
        addressBox: "address-box",
        voucherCode: "",
      },
      purchaseCode: "",
      isChangingPurchaseCode: false,
    };

    this.handleChangeAccount = this.handleChangeAccount.bind(this);

    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }

  togglePurchaseCodeModal = () => {
    this.setState({ showEditPurchaseCode: !this.state.showEditPurchaseCode });
  };

  closeModalPurchaseCode = () => {
    this.setState(
      { showPurchaseControl: !this.state.showPurchaseControl },
      () => {
        this.setState({ useCode: true }, () => {
          //FIXME: TO BE DEFINED
        });
      }
    );
  };

  toggleUseCode = async () => {
    if (this.props.accountDetail?.hasPurchaseCode) {
      this.setState(
        (prevState) => ({
          isChangingPurchaseCode: !prevState.isChangingPurchaseCode,
        }),
        () => {
          this.toggleModalPurchaseControl();
        }
      );
    } else {
      this.togglePurchaseCodeModal();
    }
  };

  toggleModalPurchaseControl = () => {
    this.setState((prevState) => ({
      showPurchaseControl: !prevState.showPurchaseControl,
    }));
  };

  confirmModalPurchase = (pin) => {
    this.setState(
      (prevState) => ({
        showPurchaseControl: !prevState.showPurchaseControl,
        purchaseCode: pin,
      }),
      () => {
        if (
          this.props.accountDetail?.hasPurchaseCode &&
          this.state.isChangingPurchaseCode
        ) {
          this.props
            .dispatch(TucanoActions.resetPurchaseCode(pin))
            .then(async (purchaseCodeResult) => {
              this.setState((prevState) => ({
                isChangingPurchaseCode: prevState.isChangingPurchaseCode,
              }));
              if (purchaseCodeResult.newAuthToken) {
                await this.props.dispatch(TucanoActions.getAccountDetails());
              }
            });
        } else if (!this.state.isChangingPurchaseCode) {
          this.setState({ proceed: true }, () => {
            this.handleOnAskPayment();
          });
        }
      }
    );
  };

  changeSaveMethod = () => {
    this.setState({ saveMethod: true });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.paymentStatus !== this.props.paymentStatus) {
      if (this.props.paymentStatus === "executed") {
        this.setState({
          currentStep: 3,
          currentSubStep: 0,
        });
      }
    }
  }

  componentDidMount() {
    const { t, accountDetail, isPopup } = this.props;
    let urlSearchParams = false;
    // Make sure the login popup is removed
    this.props.dispatch(setLoginShow(false));

    if (!isPopup) {
      document.title = t(this.props.route.title);
    }
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    if (consts.appModules.signup !== true || consts.externalSignupLink) {
      this.props.history.replace("/");
    }
    if (!isPopup) {
      urlSearchParams = new URLSearchParams(this.props.location.search);
    }

    if (this.props.isConnected) {
      if (urlSearchParams && urlSearchParams.toString() === "") {
        this.props.history.push(consts.routes.account.url);
      } else {
        if (
          urlSearchParams &&
          (urlSearchParams.get("orderID") || urlSearchParams.get("ORDERID"))
        ) {
          this.setState({
            currentStep: 3,
            currentSubStep: 0,
          });
        }
      }
    }
    document.addEventListener("keydown", this.handleKeyPressed, false);
    if (accountDetail)
      this.setState({ useCode: accountDetail?.hasPurchaseCode });

    this.paymentForm = document.querySelector(".form-payment");
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPressed, false);
    this.paymentForm.removeAttribute("action");
    this.paymentForm.removeAttribute("method");
    this.paymentForm.removeAttribute("target");
    this.paymentForm.innerHTML = "";
    this.props.dispatch(TucanoActions.resetOrderStatus());
  }

  handleKeyPressed(event) {
    if (event.key === "Enter") {
      const { steps, currentStep, currentSubStep } = this.state;
      let currentStepNext = null;
      if (
        steps[currentStep] &&
        steps[currentStep]["subSteps"] &&
        steps[currentStep]["subSteps"][currentSubStep] &&
        steps[currentStep]["subSteps"][currentSubStep]["buttons"] &&
        steps[currentStep]["subSteps"][currentSubStep]["buttons"]["next"]
      ) {
        currentStepNext =
          steps[currentStep]["subSteps"][currentSubStep]["buttons"]["next"];
      } else {
        if (
          steps[currentStep] &&
          steps[currentStep]["buttons"] &&
          steps[currentStep]["buttons"]["next"]
        ) {
          currentStepNext = steps[currentStep]["buttons"]["next"];
        }
      }

      const buttonAction =
        currentStepNext && currentStepNext["action"]
          ? currentStepNext["action"]
          : null;

      switch (buttonAction) {
        case "createAccount": {
          this.handleCreateAccount();
          break;
        }
        case "changeAddress": {
          this.handleChangeAddress();
          break;
        }
        case "askPayment": {
          this.handleOnAskPayment();
          break;
        }
        default: {
          this._next(false);
        }
      }
    }
  }

  errorAPIAddOptions = {
    "-163": "Missing parameter optionIds",
    "-1000": "Wrong parameter format",
    "-1100":
      "Try to add an option depending on another, not yet linked to customer sale",
    "-1101": "Data consistency error",
    "-1102": "A subscription is already active on your account",
    "-1103": "This offer is not compatible with your active subscription",
    "-1106": "Error on option’s availability check",
    "-1112": "Error on option’s orderable check",
    "-2332": "No product add on the sale,Please, addProducts before addOptions",
    "-2333": "You can subscribe to this offer only once",
    "-2336": "You can subscribe to this offer only once",
    "-2345": "This offer is not compatible with your product",
    "-3002": "Parameter optionIds not valid",
  };

  errorAPICreateAccount = {
    0: "Unable to convert language from ISO 639-2 to ISO 639-1,ISO 639-2 code not found",
    "-1": "Not valid",
    "-10": "Password too short",
    "-11": "Passwords mismatch",
    "-12": "Invalid character",
    "-13": "Password is required",
    "-14": "Password confirm is required",
    "-15": "Username or email is required",
    "-16": "Username not valid",
    "-21": "Must accept terms",
    "-30": "Email is not valid",
    "-31": "Email too short",
    "-32": "Emails mismatch",
    "-33": "Email is required",
    "-34": "Email confirm is required",
    "-40": "First name is required",
    "-41": "Last name is required",
    "-45": "Legal Age 18 year",
    "-46": "Date of birth is required",
    "-47": "Invalid date of birth",
    "-48": "allowNews is required",
    "-50": "Street is required",
    "-51": "Address number is required",
    "-52": "Zip is required",
    "-53": "City is required",
    "-54": "Country is required",
    "-60": "Language is required",
    "-77": "Invalid date format (not an array)",
    "-177": "FirstName/LastName is not correct",
    "-179": "This language is not supported",
    "-181": "This country is not supported",
    "-500": "Missing parameters configuration",
    "-1000": "Already in use",
    "-1701": "Verification smartcard failed",
    "-2028": "Phone number is required",
    "-2311": "Phone number validation failed",
  };

  errorAPIAskPayment = {
    "-500": "No payment needed for this offer",
    "-501": "Please accept terms and conditions to continue",
    "-911": "This voucher is not valid",
    "-913": "Voucher validation timeout",
    "-1608": "This offer can only be activated with a valid voucher",
    "-2108": "Missing parameter paymentData",
    "-2109": "paymentData not valid",
    "-2110": "Unable to find payment mode",
    "-2111": "Payment mode not active",
  };

  errorAPIChangeAddress = {
    "-31": "Email too short",
    "-40": "First name is required",
    "-41": "Last name is required",
    "-50": "Street is required",
    "-51": "Address number is required",
    "-52": "Zip is required",
    "-53": "City is required",
    "-54": "Country is required",
    "-168": "Param box is too long",
    "-3001":
      "Invalid addressType / types allowed are: 1 - Official / 2 - Delivery / 3 - Billing / 4 - Installation",
  };

  errorField = {
    email: "email",
    emailConfirm: "confirm email",
    password: "password",
    passwordConfirm: "confirm password",
    firstName: "first name",
    lastName: "last name",
    dateofbirth: "date of birth",
    title: "title",
    languageId: "language",
    addressStreet: "address street",
    addressNumber: "address number",
    addressCity: "city",
    addressCountry: "country",
    addressZip: "postal code",
    acceptTerms: "terms of services",
    allowNews: "newsletter",
  };

  getErrorAPI(api, code) {
    let error = "An error occured,Please retry later";

    switch (api) {
      case "createAccount":
        if (this.errorAPICreateAccount[code]) {
          error = this.errorAPICreateAccount[code];
        }
        break;
      case "addOptions":
        if (this.errorAPIAddOptions[code]) {
          error = this.errorAPIAddOptions[code];
        }
        break;
      case "askPayment":
        if (this.errorAPIAskPayment[code]) {
          error = this.errorAPIAskPayment[code];
        }
        break;
      case "changeAddress":
        if (this.errorAPIChangeAddress[code]) {
          error = this.errorAPIChangeAddress[code];
        }
        break;
      default:
        break;
    }

    return error;
  }

  getErrorField(code) {
    if (this.errorField[code]) {
      return this.errorField[code];
    } else {
      return code;
    }
  }
  openPaymentPopup(url) {
    const strWindowFeatures =
      "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";
    if (
      typeof window.paymentPopup === "undefined" ||
      window.paymentPopup?.closed
    ) {
      window.paymentPopup = window.open(
        url,
        "Stripe Payment",
        strWindowFeatures
      );
    } else if (this.previousUrl !== url) {
      window.paymentPopup = window.open(
        url,
        "Stripe Payment",
        strWindowFeatures
      );
      window.paymentPopup.focus();
    } else {
      window.paymentPopup.focus();
    }
    // assign the previous URL
    this.previousUrl = url;
  }

  _next(skip) {
    const { currentStep, currentSubStep, steps, optionWithVoucher } =
      this.state;
    const { t } = this.props;
    const currentStepNameAlias = steps[currentStep]
      ? steps[currentStep].nameAlias
      : null;
    let voucherError = false;
    if (currentStepNameAlias === "subscription") {
      this.setState({ showConfirmEmail: false });
    }
    if (currentStepNameAlias === "account" && !skip) {
      this.handleCreateAccount(true);
    } else {
      if (optionWithVoucher) {
        const paymentStep = this.findStep(steps, "payment");
        if (
          paymentStep[0].data["voucherCode"] === "" ||
          paymentStep[0].data["voucherCode"] === null ||
          paymentStep[0].data["voucherCode"] === undefined
        ) {
          voucherError = true;
        }
        if (paymentStep[0].data["validVoucher"] !== true) {
          voucherError = true;
        }
      }
      const fieldValid = this.fieldValid(currentStep, currentSubStep);
      if (fieldValid && fieldValid.length === 0 && !voucherError) {
        if (this.ifSubStep(currentStep, currentSubStep, "next")) {
          if (optionWithVoucher && !voucherError) {
            this.handleOnAskPayment();
            this.setState({
              currentStep: currentStep,
              currentSubStep: currentSubStep + 2,
            });
          } else {
            this.setState({
              currentStep: currentStep,
              currentSubStep: currentSubStep + 1,
            });
          }
        } else {
          if (optionWithVoucher && !voucherError) {
            this.handleOnAskPayment();
            this.setState({
              currentStep: currentStep + 2,
              currentSubStep: 0,
            });
          } else {
            if (currentStep === 0) {
              this.setState({
                currentStep: currentStep + 1,
                currentSubStep: 0,
              });
            } else {
              if (
                currentStep === 1 &&
                steps[currentStep]?.data[0]?.priceWithVat === 0
              ) {
                this.handleOnAskPayment();
              } else {
                this.setState({
                  currentStep: currentStep + 1,
                  currentSubStep: 0,
                });
              }
            }
          }
        }
      } else {
        if (voucherError) {
          fieldValid.push("voucherCode");
        }
        this.setState(
          { isNextWithError: true, errorFields: fieldValid },
          () => {}
        );
        let toastTextError = [];
        let dateOfBirthError = "";
        fieldValid.forEach((elem) => {
          if (elem === "dateofbirth") {
            dateOfBirthError = t(
              "Sorry, you are not eligible to use this service because you do not have the minimum age requirement of {{ age }}",
              {
                age: consts.minimumAgeToRegister,
              }
            );
          } else {
            toastTextError.push(t(this.getErrorField(elem)));
          }
        });
        if (dateOfBirthError) {
          this.props.dispatch(
            addToast({
              text: dateOfBirthError,
              className: "error",
              duration: 6000,
            })
          );
        }
        if (toastTextError.length > 0) {
          this.props.dispatch(
            addToast({
              text:
                t("Please to check the following field(s)") +
                " : " +
                toastTextError.join(", "),
              className: "error",
              duration: 6000,
            })
          );
        }
      }

      window.scrollTo(0, 0);
    }
  }

  _prev() {
    const { currentStep, currentSubStep } = this.state;

    if (this.ifSubStep(currentStep, currentSubStep, "back")) {
      this.setState({
        currentStep: currentStep,
        currentSubStep: currentSubStep - 1,
      });
    } else {
      this.setState({
        currentStep: currentStep - 1,
        currentSubStep: 0,
      });
    }
  }

  skip(buttonAction) {
    const steps = { ...this.state.steps };
    let goToStep = this.state.steps.length - 1;

    if (buttonAction) {
      let stepKey = this.findStepKey(steps, buttonAction);

      if (stepKey) {
        goToStep = stepKey;
      }
    }

    this.setState({
      currentStep: parseInt(goToStep),
      currentSubStep: 0,
      skip: true,
    });
  }

  get previousButton() {
    const { currentStep, currentSubStep, steps } = this.state;
    const { t } = this.props;

    let currentStepBack = null;
    if (
      steps[currentStep] &&
      steps[currentStep]["subSteps"] &&
      steps[currentStep]["subSteps"][currentSubStep] &&
      steps[currentStep]["subSteps"][currentSubStep]["buttons"] &&
      steps[currentStep]["subSteps"][currentSubStep]["buttons"]["back"]
    ) {
      currentStepBack =
        steps[currentStep]["subSteps"][currentSubStep]["buttons"]["back"];
    } else {
      if (
        steps[currentStep] &&
        steps[currentStep]["buttons"] &&
        steps[currentStep]["buttons"]["back"]
      ) {
        currentStepBack = steps[currentStep]["buttons"]["back"];
      }
    }

    if (currentStepBack && currentStepBack["visible"]) {
      return (
        <Button
          type={ButtonType.GHOST}
          rootClassName={style.backButton}
          onClick={this._prev}
          nameBtn="previousButton"
        >
          <Translate t={t}>Back</Translate>
        </Button>
      );
    } else {
      return null;
    }
  }

  get skipButton() {
    const { currentStep, currentSubStep, steps } = this.state;
    const { t } = this.props;

    let currentStepSkip = null;
    if (
      steps[currentStep] &&
      steps[currentStep]["subSteps"] &&
      steps[currentStep]["subSteps"][currentSubStep] &&
      steps[currentStep]["subSteps"][currentSubStep]["buttons"] &&
      steps[currentStep]["subSteps"][currentSubStep]["buttons"]["skip"]
    ) {
      currentStepSkip =
        steps[currentStep]["subSteps"][currentSubStep]["buttons"]["skip"];
    } else {
      if (
        steps[currentStep] &&
        steps[currentStep]["buttons"] &&
        steps[currentStep]["buttons"]["skip"]
      ) {
        currentStepSkip = steps[currentStep]["buttons"]["skip"];
      }
    }

    if (currentStepSkip && currentStepSkip["visible"]) {
      const buttonAction = currentStepSkip["action"]
        ? currentStepSkip["action"]
        : null;

      return (
        <Button
          type={ButtonType.GHOST}
          rootClassName={style.skipButton}
          onClick={this.skip.bind(this, buttonAction)}
          nameBtn="skipButton"
        >
          <Translate t={t}>Skip</Translate>
        </Button>
      );
    } else {
      return null;
    }
  }

  get nextButton() {
    const {
      isLoadingCreateAccount,
      isLoadingAddOptions,
      isLoadingAskPayment,
      t,
    } = this.props;
    const { currentStep, currentSubStep, steps } = this.state;

    let currentStepData = null;
    let currentStepNext = null;

    if (
      steps[currentStep] &&
      steps[currentStep]["subSteps"] &&
      steps[currentStep]["subSteps"][currentSubStep]
    ) {
      currentStepData = steps[currentStep]["subSteps"][currentSubStep];
    } else {
      currentStepData = steps[currentStep];
    }

    currentStepNext =
      currentStepData &&
      currentStepData["buttons"] &&
      currentStepData["buttons"]["next"]
        ? currentStepData["buttons"]["next"]
        : undefined;
    const buttonLabel =
      currentStepNext && currentStepNext["label"]
        ? currentStepNext["label"]
        : "Next";
    const buttonAction =
      currentStepNext && currentStepNext["action"]
        ? currentStepNext["action"]
        : null;

    let buttonType =
      isLoadingCreateAccount ||
      isLoadingAddOptions ||
      isLoadingAskPayment ||
      this.props.paymentStatus === "executed" ||
      this.props.paymentStatus === "cancelled"
        ? ButtonType.LOADING
        : ButtonType.NORMAL;

    let data = currentStepData.data;
    let errorField = [];

    let required = this.getRequired(currentStepData, buttonAction);

    for (let i in data) {
      if (required && required[i] === true) {
        if (
          data[i] === "" ||
          data[i] === false ||
          data[i] === undefined ||
          data[i] === null
        ) {
          errorField.push(i);
        }
      }
    }

    if (errorField.length > 0 || !data || data.length === 0) {
      buttonType = ButtonType.DISABLED;
    }

    //delete data.allowNews;
    let copyData = Object.assign({}, data);
    delete copyData.allowNews;
    switch (buttonAction) {
      case "createAccount": {
        return (
          <Button
            type={buttonType}
            rootClassName={style.nextButton}
            onClick={() => this.handleCreateAccount(false)}
            name="nextButton_createAccount"
          >
            <Translate t={t}>{buttonLabel}</Translate>
          </Button>
        );
      }
      case "changeAddress": {
        return (
          <Button
            type={buttonType}
            rootClassName={style.nextButton}
            onClick={this.handleChangeAddress.bind(this)}
            nameBtn="nextButton_changeAddress"
          >
            <Translate t={t}>{buttonLabel}</Translate>
          </Button>
        );
      }
      case "askPayment": {
        return (
          <Button
            type={buttonType}
            rootClassName={style.nextButton}
            onClick={this.handleOnAskPayment.bind(this)}
            nameBtn="nextButton_askPayment"
          >
            <Translate t={t}>{buttonLabel}</Translate>
          </Button>
        );
      }
      default: {
        return (
          <Button
            type={buttonType}
            rootClassName={style.nextButton}
            onClick={() => this._next(false)}
            nameBtn="nextButton_default"
          >
            <Translate t={t}>{buttonLabel}</Translate>
          </Button>
        );
      }
    }
  }

  getRequired(currentStep, buttonAction) {
    let required = undefined;
    switch (buttonAction) {
      case "createAccount":
        required = consts.createAccount.required;
        break;
      case "changeAddress":
        required = consts.changeAddress.required;
        break;
      default:
        required =
          currentStep && currentStep.hasOwnProperty("required")
            ? currentStep.required
            : consts.createAccount.required;
        break;
    }

    return required;
  }

  findStep(steps, currentStepNameAlias) {
    let step = [];

    Object.values(steps).forEach((value) => {
      if (value.nameAlias === currentStepNameAlias) {
        step.push(value);
        return step;
      }
    });

    return step;
  }

  findStepKey(steps, currentStepNameAlias) {
    let stepKey = null;

    Object.entries(steps).filter(([key, value]) => {
      if (value.nameAlias === currentStepNameAlias) {
        stepKey = key;
      }
      return undefined;
    });

    return stepKey;
  }

  handleOnFocus(inputId, value) {
    let validatePolicy = [];
    if (inputId === "password") {
      const { passwordPolicy } = this.props;
      validatePolicy = checkPolicy(passwordPolicy, value);
    }
    this.setState({ validPasswordPolicies: validatePolicy });
  }

  async handleChangeAccount(inputId, value) {
    const { passwordPolicy } = this.props;
    const { currentStep, currentSubStep, steps } = this.state;

    // validate password based on passwordPolicy
    if (inputId === "password") {
      let validated = checkPolicy(passwordPolicy, value);
      this.setState({ validPasswordPolicies: validated });
    }

    if (this.ifSubStep(currentStep)) {
      if (
        inputId === "addressCountry" ||
        inputId === "languageId" ||
        inputId === "title"
      ) {
        steps[currentStep].subSteps[currentSubStep].data[inputId] = value.value;
      } else if (
        steps[currentStep].subSteps[currentSubStep].data[inputId].isExtra
      ) {
        steps[currentStep].subSteps[currentSubStep].data[inputId].value = value;
      } else {
        steps[currentStep].subSteps[currentSubStep].data[inputId] = value;
      }
    } else {
      if (steps[currentStep].data[inputId].isExtra) {
        steps[currentStep].data[inputId].value = value;
      }
      steps[currentStep].data[inputId] = value;
    }

    if (inputId === "languageId") {
      await this.setState({
        language: value.value,
      });
    }
    await this.setState({
      steps: steps,
    });
  }
  handleSubscription(context, value, action) {
    const steps = { ...this.state.steps };

    let step = this.findStep(steps, "subscription");
    if (value.type === "voucherOnly") {
      this.setState({ optionWithVoucher: true });
    }
    if (action) {
      // uncheck all selected option when voucher is used
      if (context === null) {
        step[0].data = [];
      }
      const exist = step[0].data.find(
        (option) => option.idOption === value.idOption
      );
      if (!exist) {
        step[0].data.push(value);
      }
    } else {
      step[0].data = step[0].data.filter(
        (option) => option.idOption !== value.idOption
      );
    }

    this.setState({
      steps: Object.assign([], steps),
    });
  }

  setVoucherCode(voucherCode, validVoucher, optionVoucher) {
    var steps = { ...this.state.steps };

    this.setState({
      steps: Object.assign([], steps),
      voucherCode: voucherCode,
    });

    if (validVoucher && optionVoucher) {
      this.handleSubscription(null, optionVoucher, true);
    }
  }

  selectPaymentBrand(payment) {
    var steps = { ...this.state.steps };

    let step = this.findStep(steps, "payment");

    let paymentCopy = step[0].data;

    paymentCopy.paymentAlias = payment.paymentBrand;
    paymentCopy.paymentBrand = payment.paymentBrand;
    paymentCopy.paymentMethod = payment.paymentMethod;

    this.setState({
      steps: Object.assign([], steps),
    });
  }
  selectSubscriptionPaymentMethod(paymentMethod) {
    var steps = { ...this.state.steps };

    let step = this.findStep(steps, "payment");

    let paymentCopy = step[0].data;

    paymentCopy.paymentMethod = paymentMethod;
    paymentCopy.paymentBrand = paymentMethod;
    steps[step] = paymentCopy;
    this.setState({
      steps: Object.assign([], steps),
      skip: false,
    });
  }

  askPayment(paymentData, paymentCopy) {
    this.props
      .dispatch(
        TucanoActions.callAskPayment(
          JSON.stringify(paymentData),
          null,
          this.state.purchaseCode || null,
          this.state.voucherCode
        )
      )
      .then((result) => {
        checkOrderstatus(this.props.dispatch);
        if (result && result.status) {
          if (result.payload?.method?.toLowerCase() === "post") {
            const ingenicoModel = new IngenicoModel();
            this.paymentForm.appendChild(
              ingenicoModel.fillForm(result.payload, this.paymentForm)
            );
            this.paymentForm.setAttribute("target", "_blank");
            this.paymentForm.submit();
          } else {
            this.openPaymentPopup(result.payload.action);
          }
        } else {
          if (result?.code === -1608) {
            // block user inscription and show error message
            const { t } = this.props;
            this.props.dispatch(
              addToast({
                text: t(this.getErrorAPI("askPayment", result?.code)),
                className: "error",
                duration: 6000,
              })
            );
          } else if (result?.code === -500) {
            // nothing to pay
            this.setState({
              currentStep: 3,
              currentSubStep: 0,
              skip: true,
            });
          } else {
            // show payment methods
            this.setState({
              currentStep: 2,
              currentSubStep: 0,
            });
          }
        }
      });
  }

  addOptionsResult() {
    const { dataOnAddOptions, accountDetail } = this.props;
    const { purchaseCode } = this.state;
    let steps = { ...this.state.steps };
    // let stepSub = this.findStep(steps, "subscription");
    let stepPay = this.findStep(steps, "payment");
    let paymentCopy = stepPay[0].data;
    if (accountDetail && accountDetail?.hasPurchaseCode && !purchaseCode) {
      this.toggleModalPurchaseControl();
      return;
    }
    let paymentData = {
      paymentProvider: paymentCopy.brandBillingProviderId,
      policyValidation: true,
      paymentBrand: paymentCopy.paymentBrand,
      paymentMode: paymentCopy.paymentBrand,
      paymentMethod: paymentCopy.paymentMethod,
      returnUrl: window.location.origin + consts.routes.signup.url,
      successUrl:
        window.location.origin +
        consts.routes.signup.url +
        "?mv=transaction_accept",
      cancelUrl:
        window.location.origin +
        consts.routes.signup.url +
        "?mv=transaction_cancel",
    };

    if (dataOnAddOptions && dataOnAddOptions.newAuthToken) {
      if (
        !paymentCopy.voucherCode ||
        (paymentCopy.voucherCode && paymentCopy.validVoucher)
      ) {
        this.setState(
          {
            skip: false,
          },
          () => {
            this.askPayment(paymentData, paymentCopy);
          }
        );
      } else {
        this.setState({
          currentStep: 3,
          currentSubStep: 0,
        });
      }
    } else {
      if (dataOnAddOptions && dataOnAddOptions.hasOwnProperty("code")) {
        this.setState(
          {
            skip: false,
          },
          () => {
            this.askPayment(paymentData, paymentCopy);
          }
        );
        // this.props.dispatch(
        //   addToast({
        //     text: t(this.getErrorAPI("addOptions", dataOnAddOptions.code)),
        //     className: "error",
        //     duration: 6000,
        //   })
        // );
      }
    }
  }

  handleOnAskPayment() {
    if (this.state.optionWithVoucher) {
      this.selectSubscriptionPaymentMethod("Stripe");
    }
    var steps = { ...this.state.steps };
    let stepSub = this.findStep(steps, "subscription");
    let subscriptionCopy = stepSub[0].data;
    var subscriptionIdOption = subscriptionCopy.map(function (item) {
      return item.idOption;
    });

    this.props
      .dispatch(
        TucanoActions.addOptions(subscriptionIdOption, this.state.productId)
      )
      .then(this.addOptionsResult.bind(this))
      .catch((_error) => {
        const { dataOnAddOptions, t } = this.props;
        if (dataOnAddOptions && dataOnAddOptions.hasOwnProperty("code")) {
          this.props.dispatch(
            addToast({
              text: t(this.getErrorAPI("addOptions", dataOnAddOptions.code)),
              className: "error",
              duration: 6000,
            })
          );
        }
      });
  }

  getAddressInfo() {
    let addressObject = { data: {} };
    const { steps } = this.state;

    Object.values(steps).forEach((step) => {
      if (step.data) {
        Object.entries(step.data).forEach(([key, value]) => {
          if (key.indexOf("address") !== -1) {
            if (value !== "") {
              addressObject.data[key] = value;
            }
          }
        });
      }
      if (step.subSteps) {
        Object.values(step.subSteps).forEach((subStep) => {
          Object.entries(subStep.data).forEach(([key, value]) => {
            if (key.indexOf("address") !== -1) {
              if (value !== "") {
                addressObject.data[key] = value;
              }
            }
          });
        });
      }
    });

    if (Object.entries(addressObject.data).length > 0) {
      return addressObject;
    }

    return null;
  }
  handleCreateAccount = async (skiptToLogin, noError) => {
    const { steps, currentStep, currentSubStep } = this.state;
    const currentStepNameAlias = steps[currentStep]
      ? steps[currentStep].nameAlias
      : null;
    const { t } = this.props;
    let dateFormat = t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }
    let step = this.findStep(steps, currentStepNameAlias);
    let accountData = Object.values(step[0]["subSteps"]).reduce(
      (start, current) => {
        Object.entries(current.data).map(([key, val]) => {
          let pair;
          if (key === "dateofbirth") {
            if (
              moment(val, dateFormat.toUpperCase()).format("YYYY-MM-DD") !==
              "Invalid date"
            ) {
              pair = {
                [key]: moment(val, dateFormat.toUpperCase()).format(
                  "YYYY-MM-DD"
                ),
              };
              start = { ...start, ...pair };
            }
            return true;
          } else {
            if (val.isExtra) {
              if (!start["additionalInformation"]) {
                start["additionalInformation"] = {};
              }
              start["additionalInformation"][key] = val.value;
            } else {
              pair = { [key]: val };
              start = { ...start, ...pair };
            }
            return true;
          }
        });
        return start;
      },
      {}
    );
    accountData["additionalInformation"] =
      typeof accountData["additionalInformation"] === "undefined"
        ? ""
        : JSON.stringify(accountData["additionalInformation"]);
    // accountData["additionalInformation"] = JSON.stringify(accountData["additionalInformation"]);
    const fieldValid = this.fieldValid(currentStep, currentSubStep);
    const addressInfo = this.getAddressInfo();

    if (fieldValid && fieldValid.length === 0) {
      if (addressInfo) {
        accountData = {
          ...accountData,
          ...{
            productId: this.state.productId,
            addressStreet: addressInfo.data.addressStreet
              ? addressInfo.data.addressStreet
              : "",
            addressSecondStreet: addressInfo.data.addressSecondStreet
              ? addressInfo.data.addressSecondStreet
              : "",
            addressThirdStreet: addressInfo.data.addressThirdStreet
              ? addressInfo.data.addressThirdStreet
              : "",
            addressNumber: addressInfo.data.addressNumber
              ? addressInfo.data.addressNumber
              : "",
            addressCity: addressInfo.data.addressCity
              ? addressInfo.data.addressCity
              : "",
            addressCountry: addressInfo.data.addressCountry
              ? addressInfo.data.addressCountry
              : consts.country,
            addressZip: addressInfo.data.addressZip
              ? addressInfo.data.addressZip
              : "",
            addressState: addressInfo.data.addressState
              ? addressInfo.data.addressState
              : "",
          },
        };
      } else {
        accountData = {
          ...accountData,
          ...{
            productId: this.state.productId,
            addressStreet: "-",
            addressSecondStreet: "-",
            addressThirdStreet: "-",
            addressNumber: "-",
            addressCity: "-",
            addressCountry: consts.country,
            addressZip: "-",
            addressState: "-",
          },
        };
      }
      let errorcreateAccount = true;
      if (skiptToLogin) {
        this.setState({ showConfirmEmail: true });
        let login = accountData.email;
        if (
          (accountData.email && accountData.username) ||
          (accountData.username && !accountData.email)
        ) {
          login = accountData.username;
        }
        const isSafari = getFromLocal("isSafari");
        await this.props
          .dispatch(
            TucanoActions.login(
              login,
              accountData.password,
              isSafari ? consts.safariApiKey : consts.apiKey,
              consts.graphQLGrantType
            )
          )
          .then((_res) => {
            if (!this.props.loginError) {
              this.setState({ showConfirmEmail: false });
              this.props.dispatch(TucanoActions.getAccountInfos());
              this._next(true);
              this.props.dispatch(
                TucanoActions.addProducts([accountData.productId])
              );
              this.props
                .dispatch(
                  TucanoActions.getListPaymentMethods(
                    getFromLocal("languageId")
                  )
                )
                .then(() => {});
              this.props
                .dispatch(
                  TucanoActions.getAvailableOffers(getFromLocal("languageId"))
                )
                .then(() => {});
            } else {
              this.props.dispatch(
                addToast({
                  text: t("Please validate your account"),
                  className: "error",
                  duration: 6000,
                })
              );
            }
          })
          .catch((_err) => {
            if (this.props.loginError) {
              this.props.dispatch(
                addToast({
                  text: t("Please validate your account"),
                  className: "error",
                  duration: 6000,
                })
              );
            }
          });
      } else {
        await this.props
          .dispatch(TucanoActions.createAccount(accountData))
          .then(async () => {
            const { dataOnCreateAccount } = this.props;
            if (
              dataOnCreateAccount &&
              dataOnCreateAccount.hasOwnProperty("confirmEmailSent") &&
              !dataOnCreateAccount.confirmEmailSent
            ) {
              this._next(false);
              this.props
                .dispatch(
                  TucanoActions.getListPaymentMethods(
                    getFromLocal("languageId")
                  )
                )
                .then(() => {});
              this.props
                .dispatch(
                  TucanoActions.getAvailableOffers(getFromLocal("languageId"))
                )
                .then(() => {});
            }
            if (
              dataOnCreateAccount &&
              dataOnCreateAccount.hasOwnProperty("code")
            ) {
              if (!noError) {
                await this.props.dispatch(
                  addToast({
                    text: t(
                      this.getErrorAPI(
                        "createAccount",
                        dataOnCreateAccount.code
                      )
                    ),
                    className: "error",
                    duration: 6000,
                  })
                );
              }
            } else {
              errorcreateAccount = false;
            }

            // await saveInLocal("languageId", language);
            // await this.props.i18n.changeLanguage(
            //   this.state.languageIdMapping[language]
            // );
          })
          .catch(async (_error) => {
            const { dataOnCreateAccount } = this.props;
            if (
              dataOnCreateAccount &&
              dataOnCreateAccount.hasOwnProperty("code")
            ) {
              if (!noError) {
                await this.props.dispatch(
                  addToast({
                    text: t(
                      this.getErrorAPI(
                        "createAccount",
                        dataOnCreateAccount.code
                      )
                    ),
                    className: "error",
                    duration: 6000,
                  })
                );
                errorcreateAccount = true;
              }
            }
            // });
          });
        if (errorcreateAccount === false) {
          await this.setState({ showConfirmEmail: true });
        }
      }
    } else {
      this.setState(
        { isNextWithError: true, errorFields: fieldValid },
        () => {}
      );
      let toastTextError = [];
      let dateOfBirthError = "";
      fieldValid.forEach((elem) => {
        if (elem === "dateofbirth") {
          dateOfBirthError = t(
            "Sorry, you are not eligible to use this service because you do not have the minimum age requirement of {{ age }}",
            {
              age: consts.minimumAgeToRegister,
            }
          );
        } else {
          toastTextError.push(t(this.getErrorField(elem)));
        }
      });
      if (dateOfBirthError) {
        this.props.dispatch(
          addToast({
            text: dateOfBirthError,
            className: "error",
            duration: 6000,
          })
        );
      }
      if (toastTextError.length > 0) {
        this.props.dispatch(
          addToast({
            text:
              t("Please to check the following field(s)") +
              " : " +
              toastTextError.join(", "),
            className: "error",
            duration: 6000,
          })
        );
      }
    }
  };
  handleChangeAddress() {
    const { currentStep, currentSubStep } = this.state;
    const { t } = this.props;
    const fieldValid = this.fieldValid(currentStep, currentSubStep);
    let addressInfo = this.getAddressInfo();
    if (fieldValid && fieldValid.length === 0) {
      const addressData = {
        addressNumber: addressInfo.data.addressNumber || "",
        addressStreet: addressInfo.data.addressStreet || "",
        addressSecondStreet: addressInfo.data.addressSecondStreet || "",
        addressThirdStreet: addressInfo.data.addressThirdStreet || "",
        addressCity: addressInfo.data.addressCity || "",
        addressZip: addressInfo.data.addressZip || "",
        addressState: addressInfo.data.addressState || "",
        addressCountry: addressInfo.data.addressCountry || "",
      };

      this.props
        .dispatch(TucanoActions.changeAddress(addressData))
        .then(() => {
          const { dataOnChangeAddress } = this.props;
          if (
            dataOnChangeAddress &&
            dataOnChangeAddress.hasOwnProperty("newAuthToken")
          ) {
            this.props.dispatch(TucanoActions.getAccountInfos());
            this._next(false);
          }
          if (
            dataOnChangeAddress &&
            dataOnChangeAddress.hasOwnProperty("code")
          ) {
            this.props.dispatch(
              addToast({
                text: t(
                  this.getErrorAPI("changeAddress", dataOnChangeAddress.code)
                ),
                className: "error",
                duration: 6000,
              })
            );
          }
        })
        .catch((_error) => {
          const { dataOnChangeAddress } = this.props;
          if (
            dataOnChangeAddress &&
            dataOnChangeAddress.hasOwnProperty("code")
          ) {
            this.props.dispatch(
              addToast({
                text: t(
                  this.getErrorAPI("changeAddress", dataOnChangeAddress.code)
                ),
                className: "error",
                duration: 6000,
              })
            );
          }
        });
    } else {
      this.setState(
        { isNextWithError: true, errorFields: fieldValid },
        () => {}
      );
      let toastTextError = [];
      let dateOfBirthError = "";
      fieldValid.forEach((elem) => {
        if (elem === "dateofbirth") {
          dateOfBirthError = t(
            "Sorry, you are not eligible to use this service because you do not have the minimum age requirement of {{ age }}",
            {
              age: consts.minimumAgeToRegister,
            }
          );
        } else {
          toastTextError.push(t(this.getErrorField(elem)));
        }
      });
      if (dateOfBirthError) {
        this.props.dispatch(
          addToast({
            text: dateOfBirthError,
            className: "error",
            duration: 6000,
          })
        );
      }
      if (toastTextError.length > 0) {
        this.props.dispatch(
          addToast({
            text:
              t("Please to check the following field(s)") +
              " : " +
              toastTextError.join(", "),
            className: "error",
            duration: 6000,
          })
        );
      }
    }
  }

  redirectToHome = async () => {
    await saveInLocal("languageId", this.state.language);
    await this.props.i18n.changeLanguage(
      this.state.languageIdMapping[this.state.language]
    );
    window.location = consts.routes.home.url;
  };

  ifSubStep(currentStep, currentSubStep, action) {
    const { steps } = this.state;
    let find = false;
    steps.forEach(function (step, i) {
      if (i === currentStep) {
        if (step.hasOwnProperty("subSteps") && step.subSteps.length > 0) {
          let totSubSteps = 0;
          step.subSteps.forEach(function () {
            totSubSteps += 1;
          });

          if (
            action === "next" &&
            currentSubStep >= 0 &&
            currentSubStep < totSubSteps - 1
          ) {
            find = true;
          }
          if (
            action === "back" &&
            currentSubStep > 0 &&
            currentSubStep <= totSubSteps - 1
          ) {
            find = true;
          }
          if (!action && totSubSteps > 0) {
            find = true;
          }
        }
      }
    });
    return find;
  }
  validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  fieldValid(currentStep, currentSubStep) {
    const { steps } = this.state;
    const { t } = this.props;
    let dateFormat = t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }

    let errorField = [];
    if (this.ifSubStep(currentStep)) {
      const step = steps[currentStep].subSteps[currentSubStep];
      const stepNextAction =
        step && step.buttons && step.buttons.next && step.buttons.next.action
          ? step.buttons.next.action
          : undefined;
      const required = this.getRequired(step, stepNextAction);
      const data = step.data;

      for (var key in data) {
        if (required && required[key] === true) {
          if (
            data[key] === "" ||
            data[key] === undefined ||
            data[key] === null ||
            data[key] === false
          ) {
            errorField.push(key);
          }
        }
        if (key === "dateofbirth") {
          let value = data["dateofbirth"];
          if (moment(value, dateFormat.toUpperCase()) > moment()) {
            errorField.push(key);
          } else {
            if (
              moment(value, dateFormat.toUpperCase()) >
              moment().subtract(consts.minimumAgeToRegister, "years")
            ) {
              errorField.push(key);
            }
          }
        }
        if (key.includes("password")) {
          if (
            data["passwordConfirm"] &&
            data[key] !== data["passwordConfirm"]
          ) {
            errorField.push(key);
          }
        }
        if (key.includes("email")) {
          if (data[key] && !this.validateEmail(data[key])) {
            errorField.push(key);
          }
          if (data["emailConfirm"] && data[key] !== data["emailConfirm"]) {
            errorField.push(key);
          }
        }
      }
    } else {
      const step = steps[currentStep];
      const stepNextAction =
        step && step.buttons && step.buttons.next && step.buttons.next.action
          ? step.buttons.next.action
          : undefined;
      const required = this.getRequired(step, stepNextAction);
      const data = step.data;

      for (var i in data) {
        if (required && required[i] === true) {
          if (data[i] === "" || data[i] === undefined || data[i] === null) {
            errorField.push(i);
          }
        }
        if (i === "dateofbirth") {
          let value = data["dateofbirth"];
          if (moment(value, dateFormat.toUpperCase()) > moment()) {
            errorField.push(i);
          } else {
            if (
              moment(value, dateFormat.toUpperCase()) >
              moment().subtract(consts.minimumAgeToRegister, "years")
            ) {
              errorField.push(i);
            }
          }
        }
        if (i.includes("password")) {
          if (data["passwordConfirm"] && data[i] !== data["passwordConfirm"]) {
            errorField.push(t("The passwords are different"));
          }
        }
        if (i.includes("email")) {
          if (data[key] && !this.validateEmail(data[key])) {
            errorField.push(t("email address not valid"));
          }
          if (data["emailConfirm"] && data[i] !== data["emailConfirm"]) {
            errorField.push(t("The email are different"));
          }
        }
      }
    }

    return errorField;
  }

  handleLogin() {
    window.location = consts.routes.home.url + "?login=1";
  }

  handleResendVerificationMail = () => {
    const { steps } = this.state;
    const { dispatch } = this.props;
    const email = steps[0].subSteps[0].data.email;
    dispatch(TucanoActions.resendVerificationMail(email))
      .then((_result) => {
        dispatch(
          addToast({
            text: "Email Verification sent",
            className: "primary",
            duration: 6000,
          })
        );
      })
      .catch();
  };

  handleLogout() {
    this.props.history.push(consts.routes.logout.url);
  }

  render() {
    const {
      availableOffers,
      paymentMethods,
      errorOnAskPayment,
      dataOnExecutePayment,
      errorOnExecutePayment,
      isLoadingAskPayment,
      isLoadingExecutePayment,
      isConnected,
      isPopup,
      t,
      passwordPolicy,
      accountDetail,
    } = this.props;
    const {
      steps,
      currentStep,
      currentSubStep,
      skip,
      errorFields,
      autoComplete,
      showConfirmEmail,
      validPasswordPolicies,
      firstDropDown,
    } = this.state;
    const currentStepNameAlias = steps[currentStep]
      ? steps[currentStep].nameAlias
      : null;
    const stepData = this.findStep(steps, currentStepNameAlias);
    const subscriptionStep = this.findStep(steps, "subscription");
    const step = stepData[0]["subSteps"]
      ? stepData[0]["subSteps"][currentSubStep]
      : stepData[0];
    const stepNextAction =
      step && step.buttons && step.buttons.next && step.buttons.next.action
        ? step.buttons.next.action
        : undefined;
    const stepRequired = this.getRequired(step, stepNextAction);
    const showStepsBar = consts.showStepsBar;
    const isSafari = getFromLocal("isSafari");

    let dateFormat = t("dateFormat");
    if (dateFormat === "dateFormat") {
      dateFormat = consts.dateFormat;
    }

    return (
      <ContentWrapper>
        <div className={isPopup ? style.rootPopup : style.root}>
          {!isPopup && (
            <div className={style.horizontalMenu}>
              <div className={style.headerLeft}>
                {isSafari ? (
                  <NavLink
                    to={consts.routes.home.url}
                    style={{
                      width: 92,
                      height: 46,
                      backgroundImage: "url(/assets/logo_horizontal_nav.png)",
                      backgroundSize: "contain",
                      display: "inline-block",
                      marginTop: 7,
                      borderRight: "1px solid white",
                      paddingRight: "138px",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                ) : (
                  <NavLink to={consts.routes.home.url}>
                    <Logo
                      type={LogoTypes.RECTANGLE}
                      className={style.horizontalLogo}
                    />
                  </NavLink>
                )}
                <div className={style.SwitchLanguage}>
                  <SwitchLanguage />
                </div>
              </div>
              <div className={style.headerRight}>
                {isConnected ? (
                  <div
                    className={style.headerLink}
                    onClick={this.handleLogout.bind(this)}
                  >
                    <Translate t={t}>Log out</Translate>
                  </div>
                ) : (
                  <div
                    className={style.headerLink}
                    onClick={this.handleLogin.bind(this)}
                  >
                    <Translate t={t}>Log in</Translate>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={isPopup ? style.pagePopup : style.page}>
            {currentStepNameAlias !== "confirmation" && (
              <>
                <Header>
                  <Translate t={t}>Sign up</Translate>
                </Header>
                <Fragment>
                  {showStepsBar && (
                    <StepProgressBar
                      currentStep={currentStep}
                      currentSubStep={currentSubStep}
                      steps={this.state.steps}
                    />
                  )}
                  {!showConfirmEmail ? (
                    <>
                      {currentStepNameAlias === "account" && (
                        <div className={style.step}>
                          {Object.entries(step.data).map(([key, value]) => {
                            if (key.includes("_label")) {
                              return (
                                <h2 className={style.categoryTitle} key={key}>
                                  <Translate t={t}>{`${value}`}</Translate>
                                </h2>
                              );
                            } else {
                              if (
                                key.includes("allowNews") ||
                                key.includes("acceptTerms") ||
                                (step.data[key].isExtra &&
                                  step.data[key].type === "checkbox")
                              ) {
                                let label = "";
                                // console.log(key);
                                if (key.includes("allowNews")) {
                                  label = (
                                    <>
                                      <Translate t={t}>
                                        I agree to receive newsletters and
                                        promotional emails
                                      </Translate>{" "}
                                    </>
                                  );
                                }
                                if (key.includes("acceptTerms")) {
                                  if (consts.specificPrivacyPolicy === true) {
                                    label = (
                                      <>
                                        <Translate t={t}>
                                          I have read and agree with the
                                        </Translate>{" "}
                                        <LinkDuo
                                          to={consts.privacyPolicyUrl}
                                          target="_blank"
                                          className={style.link}
                                        >
                                          <Translate t={t}>
                                            Terms of Service
                                          </Translate>
                                        </LinkDuo>{" "}
                                      </>
                                    );
                                  }
                                  if (consts.specificPrivacyPolicy === false) {
                                    label = (
                                      <>
                                        <Translate t={t}>
                                          I have read and agree with the
                                        </Translate>{" "}
                                        <LinkDuo
                                          to={consts.privacyPolicyUrl}
                                          target="_blank"
                                          className={style.link}
                                        >
                                          <Translate t={t}>
                                            Privacy Policy
                                          </Translate>
                                        </LinkDuo>{" "}
                                        <Translate t={t}>and</Translate>{" "}
                                        <LinkDuo
                                          to={consts.termsOfServicesUrl}
                                          target="_blank"
                                          className={style.link}
                                        >
                                          <Translate t={t}>
                                            Terms of services
                                          </Translate>
                                        </LinkDuo>{" "}
                                      </>
                                    );
                                  }
                                }
                                if (step.data[key].isExtra) {
                                  label = (
                                    <>
                                      <Translate t={t}>
                                        {step.data[key].text}
                                      </Translate>{" "}
                                    </>
                                  );
                                }
                                return (
                                  <div
                                    className={style.containerCheckBox}
                                    key={key}
                                  >
                                    <Checkbox
                                      onChange={(valueChanged) =>
                                        this.handleChangeAccount(
                                          key,
                                          valueChanged
                                        )
                                      }
                                      checked={
                                        step.data[key].isExtra
                                          ? step.data[key].value
                                          : step.data[key]
                                      }
                                      label={
                                        <span className={style.terms}>
                                          {label}
                                          {stepRequired[key] && (
                                            <span className={style.asterisk}>
                                              *
                                            </span>
                                          )}
                                          {errorFields &&
                                            errorFields.includes(key) && (
                                              <span className={style.error}>
                                                <Translate t={t}>
                                                  Required
                                                </Translate>
                                              </span>
                                            )}
                                        </span>
                                      }
                                      autoComplete={autoComplete[key]}
                                    />
                                  </div>
                                );
                              } else {
                                if (
                                  key.includes("title") ||
                                  key.includes("languageId") ||
                                  key.includes("addressCountry") ||
                                  (step.data[key].isExtra &&
                                    step.data[key].type === "select")
                                ) {
                                  /*const indexOfKey =
                                    consts.steps[0].subSteps[0].data.findIndex(
                                      (object) => {
                                        return object.key === key;
                                      }
                                    );*/
                                  if (!firstDropDown) {
                                    this.setState({
                                      firstDropDown: key,
                                    });
                                  }
                                  /*let placeholder = "";
                                  if (key.includes("title")) {
                                    placeholder = t("Choose your title");
                                  }
                                  if (key.includes("languageId")) {
                                    placeholder = t(
                                      "Choose your preferred language"
                                    );
                                  }
                                  if (key.includes("addressCountry")) {
                                    placeholder = t("Choose your country");
                                  }
                                  if (value.placeholder) {
                                    placeholder = t(value.placeholder);
                                  }*/
                                  let DropDownValue = consts.enum[key].filter(
                                    (item) => {
                                      return item.value === step.data[key];
                                    }
                                  );
                                  let dropdownSelectedValue =
                                    DropDownValue.length > 0
                                      ? t(DropDownValue[0]?.label)
                                      : t(consts.enum[key][0].label);
                                  steps[currentStep].subSteps[
                                    currentSubStep
                                  ].data[key] =
                                    DropDownValue.length > 0
                                      ? DropDownValue[0]?.value
                                      : consts.enum[key][0].value;
                                  return (
                                    <div className={style.section} key={key}>
                                      <label className={style.label}>
                                        {CapitalizeFirstLetter(t(key))}{" "}
                                        {stepRequired[key] && (
                                          <span className={style.asterisk}>
                                            *
                                          </span>
                                        )}
                                      </label>
                                      <div
                                        className={classNames(
                                          style.list,
                                          { [style.bringToFront]: true },
                                          {
                                            [style.bringToBack]:
                                              firstDropDown === key,
                                          }
                                        )}
                                        id={key}
                                      >
                                        <Dropdown
                                          selfClosing={true}
                                          removeBackgroundColor={true}
                                          label={dropdownSelectedValue}
                                          dropdownIcon={true}
                                          handleClick={(item) =>
                                            this.handleChangeAccount(key, item)
                                          }
                                          data={consts.enum[key]}
                                        />
                                      </div>
                                      {/* <Select
                                        isSearchable={false}
                                        options={consts.enum[key]}
                                        defaultValue={
                                          consts.enum[key]
                                            ? step.data[key].isExtra
                                              ? consts.enum[key].filter(({ value }) => value === step.data[key].value)
                                              : consts.enum[key].filter(({ value }) => value === step.data[key])
                                            : null
                                        }
                                        placeholder={placeholder}
                                        classNamePrefix={"react-select"}
                                        onChange={(valueChanged) => this.handleChangeAccount(key, valueChanged)}
                                        required={stepRequired[key]}
                                        error={errorFields && errorFields.includes(key)}
                                        autoComplete={autoComplete[key]}
                                      /> */}
                                    </div>
                                  );
                                } else {
                                  let patern = "";
                                  let valueInputType = InputType.TEXT;
                                  let placeholderInput = "";
                                  let label = key;
                                  let value = step.data[key].isExtra
                                    ? step.data[key].value
                                    : step.data[key];
                                  // if (key.includes("city"))
                                  if (
                                    consts.specificEmailLabel &&
                                    key === "email"
                                  ) {
                                    valueInputType = InputType.EMAIL;
                                    label = consts.specificEmailLabel;
                                  }
                                  if (key.includes("password")) {
                                    valueInputType = InputType.PASSWORD;
                                    patern = "PASSWORD";
                                  }
                                  if (key.includes("number")) {
                                    valueInputType = InputType.PASSWORD;
                                    patern = "NUMBER";
                                  }
                                  if (key.includes("dateofbirth")) {
                                    valueInputType = InputType.MASK;
                                    patern = "DATEOFBIRTH";
                                    placeholderInput = dateFormat;
                                  }
                                  if (
                                    consts.specificCityLabel &&
                                    key.includes("addressCity")
                                  ) {
                                    label = consts.specificCityLabel;
                                  }
                                  if (
                                    consts.specificStateLabel &&
                                    key.includes("addressState")
                                  ) {
                                    label = consts.specificStateLabel;
                                  }
                                  return (
                                    <div className={style.section} key={key}>
                                      <label className={style.label}>
                                        {CapitalizeFirstLetter(t(label))}{" "}
                                        {stepRequired[key] && (
                                          <span className={style.asterisk}>
                                            *
                                          </span>
                                        )}
                                      </label>
                                      <Input
                                        type={valueInputType}
                                        placeholder={placeholderInput}
                                        rootClassName={style.input}
                                        value={value}
                                        onFocus={() => {
                                          this.handleOnFocus(key, value);
                                        }}
                                        onChange={(valueChanged) => {
                                          this.handleChangeAccount(
                                            key,
                                            valueChanged
                                          );
                                        }}
                                        pattern={patern}
                                        required={stepRequired[key]}
                                        error={
                                          errorFields &&
                                          errorFields.includes(key)
                                        }
                                        autoComplete={autoComplete[key]}
                                      />
                                      {key === "passwordConfirm" &&
                                        step.data["passwordConfirm"] &&
                                        step.data["password"] &&
                                        step.data["password"] !==
                                          step.data["passwordConfirm"] && (
                                          <p className={style.error}>
                                            <Translate t={t}>
                                              The passwords are different
                                            </Translate>
                                          </p>
                                        )}
                                      {key === "passwordConfirm" &&
                                        passwordPolicy && (
                                          <ul
                                            className={style.passwordPolicyList}
                                          >
                                            {passwordPolicy.map(
                                              (item, index) => {
                                                if (
                                                  item.message !== "" &&
                                                  item.value !== false
                                                ) {
                                                  // if (
                                                  //   item.policy ==
                                                  //   "minimumCharacters"
                                                  // ) {
                                                  //   return (
                                                  //     <li
                                                  //       key={index}
                                                  //       className={isValidPolicy(
                                                  //         validPasswordPolicies,
                                                  //         item.policy,
                                                  //         style.isValidPolicy
                                                  //       )}
                                                  //     >
                                                  //       <TranslateDynamic
                                                  //         t={t}
                                                  //         value={item.value}
                                                  //         message={item.message}
                                                  //       />
                                                  //     </li>
                                                  //   );
                                                  // }
                                                  return (
                                                    <li
                                                      key={index}
                                                      className={isValidPolicy(
                                                        validPasswordPolicies,
                                                        item.policy,
                                                        style.isValidPolicy
                                                      )}
                                                    >
                                                      <TranslateDynamic
                                                        t={t}
                                                        value={item.value}
                                                        message={item.message}
                                                      />
                                                    </li>
                                                  );
                                                }
                                              }
                                            )}
                                          </ul>
                                        )}
                                      {key === "emailConfirm" &&
                                        step.data["emailConfirm"] &&
                                        step.data["email"] &&
                                        step.data["email"] !==
                                          step.data["emailConfirm"] && (
                                          <p className={style.error}>
                                            <Translate t={t}>
                                              The email are different
                                            </Translate>
                                          </p>
                                        )}
                                      {key === "email" &&
                                        step.data["email"] &&
                                        !this.validateEmail(
                                          step.data["email"]
                                        ) && (
                                          <p className={style.error}>
                                            <Translate t={t}>
                                              Email address not valid
                                            </Translate>
                                          </p>
                                        )}
                                    </div>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {currentStepNameAlias !== "Subscription" &&
                        !this.props.login.data &&
                        consts.confirmEmail && (
                          <>
                            <h2>
                              <Translate t={t}>Account validation</Translate>
                            </h2>
                            <p className={style.validateEmailContent}>
                              <Translate t={t}>
                                An_email_has_been_sent_to
                              </Translate>{" "}
                              {`${step.data["email"]} `}
                              <Translate t={t}>
                                to validate your account
                              </Translate>
                              <br />
                              <Translate t={t}>
                                Please_check_your_email_to_complete_your_account_creation
                              </Translate>
                            </p>
                            <p>
                              <Translate t={t}>
                                Didn't receive the email ?
                              </Translate>
                              <span
                                onClick={() =>
                                  this.handleResendVerificationMail()
                                }
                                className={style.resendEmail}
                              >
                                <Translate t={t}>Re-send the mail</Translate>
                              </span>
                            </p>
                            <p className={style.clarification}>
                              <Translate t={t}>
                                The_email_can_take_a_few_minute_to_arrive_Don’t_forget_to_check_your_spam
                              </Translate>
                            </p>
                          </>
                        )}
                    </>
                  )}
                  {currentStepNameAlias === "subscription" && (
                    <div className={style.step}>
                      {isSafari && (!!isIOS() || !!isIpadOS()) && (
                        <div className={style.infoMessage}>
                          <About className={style.aboutIcon} />
                          <h3 className={style.message}>
                            <Trans>
                              Before proceeding, Verify that your Safari pop-up
                              settings are disabled,{" "}
                            </Trans>
                            <a
                              style={{ textDecoration: "underline" }}
                              href="App-prefs://prefs:root=SAFARI"
                            >
                              <Trans>SAFARI_SETTINGS_PATH</Trans>
                            </a>
                          </h3>
                        </div>
                      )}
                      {availableOffers && (
                        <>
                          <p className={style.setupFeeDescription}>
                            *
                            <Trans>
                              Setup fees could be applied for some subscriptions
                              if it is mentioned with the offer price, only once
                              at the first subscription or at the subscription
                              renewal after cancellation.
                            </Trans>
                          </p>
                          {availableOffers.map((item, index) => {
                            return (
                              <SubscriptionItem
                                key={index}
                                subscriptionItem={item}
                                type={"offer"}
                                selectionType={"CHECKBOX"}
                                selectedByDefault={
                                  steps[currentStep].data.find(
                                    (option) =>
                                      option.idOption === item.idOption
                                  )
                                    ? true
                                    : false
                                }
                                onActionButtonClick={this.handleSubscription.bind(
                                  this
                                )}
                              />
                            );
                          })}
                        </>
                      )}
                      <VoucherZone
                        subscriptionItems={availableOffers}
                        value={this.state.steps[2].data.voucherCode}
                        callback={this.setVoucherCode.bind(this)}
                        rootClassName={style.voucherZone}
                      />
                    </div>
                  )}
                  {currentStepNameAlias === "payment" && (
                    <div className={style.step}>
                      {isSafari && (!!isIOS() || !!isIpadOS()) && (
                        <div className={style.infoMessage}>
                          <About className={style.aboutIcon} />
                          <h3 className={style.message}>
                            <Trans>
                              Before proceeding, Verify that your Safari pop-up
                              settings are disabled,{" "}
                            </Trans>
                            <a
                              style={{ textDecoration: "underline" }}
                              href="App-prefs://prefs:root=SAFARI"
                            >
                              <Trans>SAFARI_SETTINGS_PATH</Trans>
                            </a>
                          </h3>
                        </div>
                      )}
                      <h2 className={style.categoryTitle}>
                        {consts.specificPaymentMethodLabel ? (
                          <p>{consts.specificPaymentMethodLabel}</p>
                        ) : (
                          <Translate t={t}>Select a payment method</Translate>
                        )}
                      </h2>
                      <RadioCard
                        items={paymentMethods}
                        type={"horizontal"}
                        callback={this.selectPaymentBrand.bind(this)}
                        classNameByKey={"paymentBrand"}
                      />
                      {consts.purchasePin && (
                        <>
                          <div className={style.purchaseCodeArea}>
                            <div>
                              <Checkbox
                                change={this.toggleUseCode}
                                checked={
                                  accountDetail?.hasPurchaseCode || false
                                }
                                label={
                                  <Translate t={t}>
                                    Use a purchase code
                                  </Translate>
                                }
                                rootClassName={style.switch}
                              />
                              <div className={style.purchaseCodeLabel}>
                                <p className={style.space}>
                                  {t("purchaseCodeExplanation")}
                                </p>
                                <p className={style.space}>
                                  <Translate t={t}>
                                    Your default code is 1234
                                  </Translate>
                                </p>
                              </div>
                              {accountDetail?.hasPurchaseCode && (
                                <div
                                  className={style.purchaseCodeElement}
                                  onClick={this.togglePurchaseCodeModal}
                                >
                                  <Translate t={t}>
                                    Click here to change the purchase code
                                  </Translate>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {this.state.showEditPurchaseCode && (
                        <Modal
                          size={"sm"}
                          leftContent={{
                            title: t(
                              `${
                                accountDetail?.hasPurchaseCode
                                  ? "Change"
                                  : "Add"
                              } purchase code`
                            ),
                          }}
                          rightContent={{
                            canDismiss: this.togglePurchaseCodeModal,
                          }}
                          rootClassName={style.modal}
                          overlay={true}
                        >
                          <FormChangePurchaseCode
                            closeModal={this.togglePurchaseCodeModal}
                            rootClassName={style.modal}
                            hasPurchaseCode={accountDetail?.hasPurchaseCode}
                          />
                        </Modal>
                      )}
                      {this.state.showPurchaseControl === true && (
                        <ModalValidatePurchaseCode
                          onCloseClick={this.closeModalPurchaseCode}
                          onConfirmClick={this.confirmModalPurchase}
                        />
                      )}
                    </div>
                  )}
                  <div className={style.actionButton}>
                    {this.previousButton}
                    {!showConfirmEmail ? (
                      this.nextButton
                    ) : (
                      <Button
                        type={ButtonType.NORMAL}
                        rootClassName={style.nextButton}
                        onClick={() => this._next(false)}
                      >
                        <Translate t={t}>
                          {subscriptionStep.length > 0
                            ? "Choose your subscription"
                            : "Sign in"}
                        </Translate>
                      </Button>
                    )}
                    {this.skipButton}
                  </div>
                </Fragment>
              </>
            )}
            {currentStepNameAlias === "confirmation" && (
              <div className={style.successMessage}>
                <h2>
                  <Translate t={t}>Thank you for joining us</Translate>
                </h2>
                <div className={style.successMessage}>
                  <div className={style.message}>
                    <div>
                      <CheckIcon className={style.checkIcon} />
                      <span>
                        <Translate t={t}>
                          Your account has been successfully created
                        </Translate>
                      </span>
                    </div>
                  </div>
                  {!skip && this.props.paymentStatus === "executed" && (
                    <div>
                      <CheckIcon className={style.checkIcon} />
                      <span>
                        <Translate t={t}>
                          You have access to all content from the Premium
                          package and the Unlimited Package
                        </Translate>
                      </span>
                    </div>
                  )}
                  <div className={style.message}>
                    {skip && (
                      <div>
                        <InfoIcon className={style.infoIcon} />
                        <span>
                          <Translate t={t}>
                            No subscription has been activated yet,You can
                            consult the available offers in your subscription
                            page
                          </Translate>{" "}
                          <Link
                            to={consts.routes.subscription.url}
                            className={style.link}
                          >
                            <Translate t={t}>subscriptions page</Translate>
                          </Link>
                        </span>
                      </div>
                    )}
                    {((!skip &&
                      !isLoadingAskPayment &&
                      !isLoadingExecutePayment &&
                      dataOnExecutePayment) ||
                      (this.props.paymentStatus &&
                        this.props.paymentStatus !== "cancelled" &&
                        errorOnAskPayment &&
                        errorOnAskPayment.code &&
                        errorOnAskPayment.code === -500) ||
                      (errorOnExecutePayment &&
                        errorOnExecutePayment.code &&
                        errorOnExecutePayment.code === -500)) && (
                      <div>
                        <CheckIcon className={style.checkIcon} />
                        <span>
                          <Translate t={t}>
                            Your subscription has been successfully activated
                          </Translate>
                        </span>
                      </div>
                    )}
                    {!skip &&
                      !isLoadingAskPayment &&
                      !isLoadingExecutePayment &&
                      !dataOnExecutePayment &&
                      errorOnExecutePayment &&
                      errorOnExecutePayment.code &&
                      errorOnExecutePayment.code !== -500 &&
                      !errorOnAskPayment && (
                        <div>
                          <CloseIcon className={style.closeIcon} />
                          <span>
                            <Translate t={t}>
                              Payment has failed,You can update your payment
                              method by clicking
                            </Translate>{" "}
                            <Link
                              to={consts.routes.subscription.url}
                              className={style.link}
                            >
                              <Translate t={t}>here</Translate>
                            </Link>
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className={style.backToHomeButton}>
                  <Button
                    type={ButtonType.NORMAL}
                    rootClassName={style.backToHomeButton}
                    onClick={this.redirectToHome.bind(this)}
                  >
                    <Translate t={t}>Back to home</Translate>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </ContentWrapper>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      isLoadingCreateAccount: state.subscription.createAccount.loading,
      dataOnCreateAccount: state.subscription.createAccount.data,
      dataOnChangeAddress: state.account.changeAddress.data,
      availableOffers: TucanoSelectors.getAvailableOffers(state),
      passwordPolicy: TucanoSelectors.getPasswordPolicy(state),
      paymentMethods: TucanoSelectors.getListPaymentMethods(state),
      isLoadingAddOptions: state.subscription.addOptions.loading,
      dataOnAddOptions: state.subscription.addOptions.data || undefined,
      isLoadingAskPayment: state.subscription.askPayment.loading,
      dataOnAskPayment: state.subscription.askPayment.data || undefined,
      errorOnAskPayment: state.subscription.askPayment.error || undefined,
      isLoadingExecutePayment: state.subscription.executePayment.loading,
      dataOnExecutePayment: state.subscription.executePayment.data || undefined,
      errorOnExecutePayment:
        state.subscription.executePayment.error || undefined,
      accountDetail: state.account.user.data,
      loginError: state.account.login.error,
      paymentStatus: state.subscription.checkOrderStatus.data?.orderStatus,
      login: state.account.login,
    };
  }),
  withTranslation()
)(PageSignUp);
