import * as AccountActions from "./actions/account";
import * as AnalyticsActions from "./actions/analytics";
import * as ContentActions from "./actions/content";
import * as ApiActions from "./actions/interface";
import * as PlayerActions from "./actions/player";
import * as ReferenceActions from "./actions/reference";
import * as SearchActions from "./actions/search";
import * as SubscriptionActions from "./actions/subscription";
import actionTypes from "./consts/actionTypes";
import * as tucanoConsts from "./consts/tucano";
import requestsMiddleware from "./middlewares/request";
import AssetModel from "./models/Asset";
import ChannelModel from "./models/Channel";
import DeviceModel from "./models/Device";
import EPGModel from "./models/EPG";
import EPGEventAsset from "./models/EPGEventAsset";
import FaqModel from "./models/Faq";
import InvoiceModel from "./models/Invoice";
import OptionModel from "./models/Option";
import OptionAvailabilityModel from "./models/OptionAvailability";
import PaymentMethodModel from "./models/PaymentMethod";
import QuotaModel from "./models/Quota";
import RecordModel from "./models/Record";
import SeasonModel from "./models/Season";
import StrateModel from "./models/Strate";
import ThumbnailModel from "./models/Thumbnail";
import TVShowModel from "./models/TVShow";
import accountReducer from "./reducers/account";
import contentReducer from "./reducers/content";
import playerReducer from "./reducers/player";
import referenceReducer from "./reducers/reference";
import searchReducer from "./reducers/search";
import subscriptionReducer from "./reducers/subscription";
import * as AccountSelectors from "./selectors/account";
import * as ContentSelectors from "./selectors/content";
import * as PlayerSelectors from "./selectors/player";
import * as ReferenceSelectors from "./selectors/reference";
import * as SearchSelectors from "./selectors/search.js";
import * as SubscriptionSelectors from "./selectors/subscription";

export const TucanoActionTypes = actionTypes;
export const TucanoConsts = tucanoConsts;

export const TucanoMiddlewares = {
  requestsMiddleware: requestsMiddleware,
};

export const TucanoActions = {
  ...SubscriptionActions,
  authenticateByApple: ApiActions.authenticateByApple,
  authenticateByFacebook: ApiActions.authenticateByFacebook,
  authenticateByGoogle: ApiActions.authenticateByGoogle,
  login: ApiActions.login,
  loginWebPlayer: ApiActions.loginWebPlayer,
  logout: ApiActions.logout,
  refreshToken: ApiActions.refreshToken,
  getAccountInfos: ApiActions.getAccountInfos,
  changeDetails: ApiActions.changeAccountDetails,
  changeAddress: ApiActions.changeAccountAddress,
  updatePassword: ApiActions.changePassword,
  askResetPassword: ApiActions.askForResetPassword,
  getPasswordPolicy: ApiActions.fetchPasswordPolicy,
  resetPassword: ApiActions.resetPersonalPassword,
  getParentalCode: ApiActions.parentalCode,
  setParentalCode: ApiActions.setParentalCode,
  getReferences: ApiActions.getReferences,
  getTrailerUrl: ApiActions.getTrailerUrl,
  resetTrailerUrl: ApiActions.resetTrailerUrl,
  getPlayerInfos: ApiActions.getPlayerInfos,
  getTvShowAsset: ApiActions.getTvShowAsset,
  getPlayerLiveInfos: ApiActions.getPlayerLiveInfos,
  resetPlayerInfos: ApiActions.resetPlayer,
  getFavorites: ApiActions.getFavorites,
  addFavorite: ApiActions.addFavorite,
  deleteFavorite: ApiActions.deleteFavorite,
  changeScoreAsset: ApiActions.changeScore,
  addDevice: ApiActions.addDevice,
  authDevice: ApiActions.authDevice,
  casRemoveDevice: ApiActions.removeDevice,
  getDevices: ApiActions.getDevices,
  getListInvoices: ApiActions.getListInvoices,
  postVideoProgress: ApiActions.postVideoProgress,
  getAvailableOffers: ApiActions.getAvailableOffers,
  getListOptions: ApiActions.getListOptions,
  getActiveProducts: ApiActions.getActiveProducts,
  getOptionValidity: ApiActions.getOptionValidity,
  createAccount: ApiActions.createNewAccount,
  addOptions: ApiActions.subscribeOptions,
  removeOptions: ApiActions.removeSubscribedOptions,
  getListPaymentMethods: ApiActions.getListPaymentMethods,
  callAskPayment: ApiActions.callAskPayment,
  callAskChangeBankInformation: ApiActions.callAskChangeBankInformation,
  callExecutePayment: ApiActions.callExecutePayment,
  checkOrderStatus: ApiActions.callCheckOrderStatus,
  callValidateVoucher: ApiActions.callValidateVoucher,
  getListCountries: ApiActions.getListCountries,
  callDocument: ApiActions.callDocument,
  postAnalytics: ApiActions.postAnalytics,
  getFavoritesChannels: ApiActions.getFavoritesChannels,
  addFavoriteChannel: ApiActions.addFavoriteChannels,
  getSlideTrailerUrl: ApiActions.getSlideTrailerUrl,
  showSliderTrailer: ApiActions.showSliderTrailer,
  resetSlideTrailerUrl: ApiActions.resetSlideTrailerUrl,
  setCurrentTrailerIdSlider: ApiActions.setCurrentTrailerIdSlider,
  resetCurrentTrailerIdSlider: ApiActions.resetCurrentTrailerIdSlider,
  resetAssetTrailerObject: ApiActions.resetAssetTrailerObject,
  setServerError: ApiActions.setServerError,
  resetServerError: ApiActions.resetServerError,
  setAuthToken: ApiActions.setAuthToken,
  loginByPairing: ApiActions.loginPairing,
  syncFromTveRequest: ApiActions.syncFromTveRequest,
  resetGlobalState: ApiActions.resetGlobalState,
  createNPVRRecord: ApiActions.createNewNPVRRecord,
  getNPVRRecords: ApiActions.getAllNPVRRecords,
  getNpvrQuota: ApiActions.getNpvrQuota,
  deleteRecord: ApiActions.deleteRecord,
  getNpvrPlayerInfos: ApiActions.getNpvrPlayerInfos,
  ...AccountActions,
  ...ContentActions,
  ...ReferenceActions,
  ...AnalyticsActions,
  ...SearchActions,
  ...PlayerActions,
};

export const TucanoReducers = {
  account: accountReducer,
  content: contentReducer,
  reference: referenceReducer,
  player: playerReducer,
  subscription: subscriptionReducer,
  search: searchReducer,
};

export const TucanoSelectors = {
  // ACCOUNT
  getUserInfo: AccountSelectors.getUserInfo,
  getParentalCode: AccountSelectors.getParentalCode,
  getDevices: AccountSelectors.getDevices,
  getDeviceCounter: AccountSelectors.getDeviceCounter,
  getListInvoices: AccountSelectors.getListInvoices,
  getProfile: AccountSelectors.getProfile,
  getActiveProfile: AccountSelectors.getActiveProfile,
  getAvatars: AccountSelectors.getAvatars,
  getPasswordPolicy: AccountSelectors.getPasswordPolicy,
  // HOME
  getHomeSlideshow: ContentSelectors.getHomeSlideshow,
  getHomeStrates: ContentSelectors.getHomeStrates,
  // LIVE
  getLive: ContentSelectors.getLive,
  // EPG
  getEPG: ContentSelectors.getEPG,
  getFilteredEpg: ContentSelectors.getFilteredEpg,
  // VOD
  getVODSlideshow: ContentSelectors.getVODSlideshow,
  getVODStrates: ContentSelectors.getVODStrates,
  // CATCHUP
  getCatchupChannels: ReferenceSelectors.getCatchupChannels,
  getCatchupChannelStrates: ContentSelectors.getCatchupChannelStrates,
  getCatchupChannelSlideshow: ContentSelectors.getCatchupChannelSlideshow,
  getCatchupDetail: ContentSelectors.getCatchupDetailStrate,
  // CONTINUE WATCHING
  getActiveAssets: ContentSelectors.getActiveAssets,
  getActiveAssetsStrate: ContentSelectors.getActiveAssetsStrate,
  getVODActiveAssetsStrate: ContentSelectors.getVODActiveAssetsStrate,
  // ASSET
  getAsset: ContentSelectors.getAsset,
  getAssetTrailer: ContentSelectors.getAssetTrailer,
  getAssetSlideTrailer: ContentSelectors.getAssetSlideTrailer,
  getShowSliderTrailer: ContentSelectors.getShowSliderTrailer,
  getCurrentSliderTrailer: ContentSelectors.getCurrentSliderTrailer,
  getAssetBreadcrumb: ContentSelectors.getAssetBreadcrumb,
  getAssetNextEpisode: ContentSelectors.getAssetNextEpisode,
  getTvShow: ReferenceSelectors.getTvShow,
  getTvShowSeasons: ContentSelectors.getTvShowSeasons,
  getCategoryBreadcrumb: ContentSelectors.getCategoryBreadcrumb,
  getEPGDetail: ContentSelectors.getEPGDetail,
  getGroups: ContentSelectors.getGroups,
  // PLAYER
  getPlayerInfos: PlayerSelectors.getPlayerInfos,
  getNextEPGForChannelId: ContentSelectors.getNextEPGForChannelId,
  selectMenuOrdre: ReferenceSelectors.selectMenuOrdre,

  // FAVORITES
  getFavoritesChannels: ContentSelectors.getFavoritesChannels,
  getFavorites: ContentSelectors.getFavorites,
  getTVshowAssetsFavorite: ContentSelectors.getTVshowAssetsFavorite,
  isAssetFavorite: ContentSelectors.isAssetFavorite,
  getCategoryBySlugAndDate: ReferenceSelectors.getCategoryBySlugAndDate,
  // FAQ
  getFaqContent: ReferenceSelectors.getFaqContent,
  // Privacy
  getPrivacyContent: ReferenceSelectors.getPrivacyContent,
  // CGU
  getCguContent: ReferenceSelectors.getCguContent,
  // MISC
  getCategoryById: ReferenceSelectors.getCategoryById,
  getCategoryBySlug: ReferenceSelectors.getCategoryBySlug,
  getCatalog: ReferenceSelectors.getCatalog,
  getChannels: ReferenceSelectors.getChannels,
  getChannelById: ReferenceSelectors.getChannelById,
  //SUBSCRIPTION
  getAvailableOffers: SubscriptionSelectors.getAvailableOffers,
  selectAvailableOffersByChannelId: SubscriptionSelectors.selectAvailableOffersByChannelId,
  selectAvailableOffersByPackageId: SubscriptionSelectors.selectAvailableOffersByPackageId,
  getAvailableOffersVoucher: SubscriptionSelectors.getAvailableOffersVoucher,
  getListOptions: SubscriptionSelectors.getListOptions,
  getOptionValidity: SubscriptionSelectors.getOptionValidity,
  getListPaymentMethods: SubscriptionSelectors.getListPaymentMethods,
  getLastUsedPaymentMethod: SubscriptionSelectors.getLastUsedPaymentMethod,
  //TVOD
  getActiveAssetsTVOD: ContentSelectors.getActiveAssetsTVOD,
  // SEARCH
  getSearchResults: SearchSelectors.getSearchResults,
  getTotalSearchResults: SearchSelectors.getTotalSearchResults,
  //NPVR
  getAllNPVRRecords: ContentSelectors.getAllNPVRRecords,
  //QUOTA
  getQuotaNPVR: ContentSelectors.getQuotaNPVR,
};

export const TucanoModels = {
  Asset: AssetModel,
  TVShow: TVShowModel,
  Strate: StrateModel,
  Thumbnail: ThumbnailModel,
  Channel: ChannelModel,
  EPG: EPGModel,
  EPGEventAsset: EPGEventAsset,
  Faq: FaqModel,
  Season: SeasonModel,
  Device: DeviceModel,
  Option: OptionModel,
  OptionAvailability: OptionAvailabilityModel,
  PaymentMethod: PaymentMethodModel,
  Invoice: InvoiceModel,
  Record: RecordModel,
  Quota: QuotaModel,
};
