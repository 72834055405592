import moment from "moment-timezone";

import consts from "../consts/consts";

export function msToTime(duration) {
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  let formatted = `${minutes}MN`;
  if (hours > 0 && minutes < 9) {
    formatted = `0${formatted}`;
  }
  if (hours > 0) {
    formatted = `${hours}H${formatted}`;
  }
  return formatted;
}

export function convertDateToTimezone(date, lang) {
  let dateTimezone = moment(date, "YYYY-MM-DD hh:mm:ss");
  return dateTimezone
    .clone()
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess())
    ?.locale(lang == "ar" ? "ar-MA" : lang)
    ?.format(consts.timeCatchupFormat);
}

export function isLiveOrUpComing(startTime, endTime) {
  const currentTime = new Date();
  return (
    (startTime <= currentTime.getTime() && currentTime.getTime() <= endTime) ||
    currentTime.getTime() < endTime
  );
}

export function secondsToHoursAndMinutes(seconds) {
  if (typeof seconds !== "number" || isNaN(seconds)) {
    return { hours: 0, minutes: 0 };
  }

  // Calculate the total minutes
  const totalMinutes = Math.floor(seconds / 60);

  // Calculate the hours and minutes
  const hours =
    totalMinutes < 0
      ? Math.ceil(totalMinutes / 60)
      : Math.floor(totalMinutes / 60);
  const minutes = Math.abs(totalMinutes % 60);

  return { hours, minutes };
}
