import classnames from "classnames";
import Button from "components/Button";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class RadioCard extends Component {
  state = {
    imageError: [],
  };
  onChange(item) {
    if (this.props.callback) {
      this.props.callback(item);
    }
  }
  onImageError = async (paymentType) => {
    let imageError = [...this.state.imageError];
    imageError.push(paymentType);
    await this.setState({ imageError: imageError });
  };
  render() {
    const {
      type,
      items,
      selected,
      classNameByKey,
      isOld,
      buttonType,
      handleOnUpdatePayment,
    } = this.props;
    const { imageError } = this.state;
    const RadioCardClassname = classnames({
      [style.horizontal]: type === RadioCardTypes.HORIZONTAL,
      [style.vertical]: type === RadioCardTypes.VERTICAL,
    });
    return (
      <div className={style.container}>
        <ul className={RadioCardClassname}>
          {items &&
            items.map((item, index) => {
              if (style[item[classNameByKey]] || style["oldMethod"]) {
                return (
                  <li key={index} onClick={this.onChange.bind(this, item)}>
                    {!isOld && (
                      <input
                        type="radio"
                        id={item.paymentBrand}
                        name={"selector"}
                        defaultChecked={
                          selected &&
                          item.hasOwnProperty(classNameByKey) &&
                          item[classNameByKey] &&
                          item[classNameByKey].includes(selected)
                            ? item[classNameByKey].includes(selected)
                            : false
                        }
                      />
                    )}
                    {consts.freePaymentOption === false ? (
                      <>
                        {item.paymentMethod?.includes("Old") && (
                          <>
                            <label
                              className={style.oldMethod}
                              htmlFor={item.paymentMethod}
                              onClick={this.onChange.bind(this, item)}
                            >
                              <p className={style.topLabel}>
                                <Trans>Last used</Trans>
                              </p>
                              <div className={style.cardInfo}>
                                <p>{item.paymentHolder.substring(0, 30)}</p>
                                <div
                                  className={style.space}
                                >{`****${item.paymentCardNumber}`}</div>
                                <div>{`EXP : ${item.paymentExpirationDate}`}</div>
                              </div>
                              <div className={style.paymentContainer}>
                                <div
                                  className={
                                    item[classNameByKey]
                                      ? classnames(style[item[classNameByKey]])
                                      : ""
                                  }
                                >
                                  {imageError.length > 0 &&
                                  imageError.includes(item.paymentType) ? (
                                    <div className={style.placeholdertextOld}>
                                      {item.paymentType}
                                    </div>
                                  ) : (
                                    <img
                                      src={`${consts.image_hostname}${
                                        routes.imgdata
                                      }?type=global&objectId=pg_${
                                        item.paymentType
                                      }&format_w=110&format_h=110&languageId=${getFromLocal(
                                        "languageId"
                                      )}`}
                                      height={110}
                                      width={110}
                                      onError={() =>
                                        this.onImageError(item.paymentType)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </label>
                          </>
                        )}
                        {isOld && (
                          <label
                            className={style.oldMethodChoose}
                            htmlFor={item.paymentMethod}
                            onClick={this.onChange.bind(this, item)}
                          >
                            <div className={style.cardInfoContainer}>
                              <div className={style.paymentContainerChoose}>
                                <div
                                  className={
                                    item[classNameByKey]
                                      ? classnames(style[item[classNameByKey]])
                                      : ""
                                  }
                                >
                                  {imageError.length > 0 &&
                                  imageError.includes(item.paymentType) ? (
                                    <div className={style.placeholderOld}>
                                      {item.paymentType}
                                    </div>
                                  ) : (
                                    <img
                                      src={`${consts.image_hostname}${
                                        routes.imgdata
                                      }?type=global&objectId=pg_${
                                        item.paymentAlias
                                      }&format_w=110&format_h=110&languageId=${getFromLocal(
                                        "languageId"
                                      )}`}
                                      height={110}
                                      width={110}
                                      onError={() =>
                                        this.onImageError(item.paymentAlias)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className={style.cardInfoChoose}>
                                <p className={style.boldName}>
                                  {item.paymentHolder.substring(0, 30)}
                                </p>
                                <div>
                                  <span
                                    style={{ marginRight: "60px" }}
                                  >{`****${item.paymentCardNumber}`}</span>{" "}
                                  <span>{`EXP : ${item.paymentExpirationDate} `}</span>
                                </div>
                              </div>
                            </div>
                            <div className={style.action}>
                              <Button
                                rootClassName={style.confirmButton}
                                type={buttonType}
                                onClick={handleOnUpdatePayment}
                              >
                                <Trans>Update payment</Trans>
                              </Button>
                            </div>
                          </label>
                        )}
                        {!item.paymentMethod?.includes("Old") && !isOld && (
                          <label
                            htmlFor={item.paymentBrand}
                            className={
                              item.hasOwnProperty(classNameByKey) &&
                              style[item[classNameByKey]]
                                ? classnames(style[item[classNameByKey]])
                                : ""
                            }
                          >
                            <div
                              className={
                                !imageError
                                  ? style.imagePayment
                                  : style.placeholderContainer
                              }
                            >
                              {imageError.length > 0 &&
                              imageError.includes(item.paymentAlias) ? (
                                <div className={style.placeholdertext}>
                                  {item.paymentAlias}
                                </div>
                              ) : (
                                <img
                                  src={`${consts.image_hostname}${
                                    routes.imgdata
                                  }?type=global&objectId=pg_${
                                    item.paymentAlias
                                  }&format_w=110&format_h=110&languageId=${getFromLocal(
                                    "languageId"
                                  )}`}
                                  height={110}
                                  width={110}
                                  onError={() =>
                                    this.onImageError(item.paymentAlias)
                                  }
                                />
                              )}
                            </div>
                          </label>
                        )}
                      </>
                    ) : (
                      <label
                        htmlFor={item.paymentBrand}
                        className={style.Free}
                      />
                    )}
                    {!isOld && (
                      <label
                        htmlFor={item.paymentBrand}
                        className={style.check}
                      />
                    )}
                  </li>
                );
              } else {
                return null;
              }
            })}
        </ul>
      </div>
    );
  }
}

export const RadioCardTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

RadioCard.defaultProps = {
  type: RadioCardTypes.HORIZONTAL,
  items: [],
  selected: undefined,
};

RadioCard.propTypes = {
  type: PropTypes.oneOf(Object.values(RadioCardTypes)),
  items: PropTypes.any,
  callback: PropTypes.func,
  selected: PropTypes.string,
};
export default withTranslation()(RadioCard);
