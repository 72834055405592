import "cross-fetch/polyfill";

import consts from "../../consts/consts";
import routes from "../consts/routes";

let config = {
  base: "",
  defaultHeaders: {
    Accept: "application/json",
  },
  hooks: {
    onRequest: (store, action, request) => {},
    onError: (store, action, request, response) => {},
    onComplete: (store, action, request, response) => {},
  },
};

function goTo(action, store, next, payload, meta) {
  if (typeof action !== "function") {
    //console.error("requestMiddleware error : onStart, onSuccess, onError must be functions");
    return;
  }
  const res = action(payload, meta, store.dispatch, store.getState);
  res && next(res);
}

function responseHandler(response) {
  if (!response || response.message) {
    return {
      error: true,
      meta: {
        text: response.message,
        requestFailed: true,
      },
    };
  }

  const res = {
    error: true,
    meta: {
      text: response.statusText,
      httpCode: response.status,
    },
  };

  if (response.body) {
    if (!("result" in response.body) && response.status === 200) {
      res.payload = response.body;
      res.error = false;
      return res;
    }
    if (response.body.status && response.body.status === true) {
      res.payload = response.body.result;
      res.error = false;
    } else if (response.body.access_token) {
      res.payload = response.body;
      res.error = false;
    } else if (response.body.error || (response.body.code === 1 && response.body.message === "Invalid auth")) {
      res.payload = response.body.error || response.body;
    }
  }

  return res;
}

const requestsMiddleware = (store) => (next) => (action) => {
  if (!action.request) {
    return next(action);
  }

  // Requests configuration
  const requestActions = action.request instanceof Array ? action.request : [action.request];
  const requests = requestActions.map((requestAction) => {
    let request = {
      base: requestAction.base !== undefined ? requestAction.base : config.base,
      url: requestAction.url,
      method: requestAction.method || "GET",
      headers: { ...config.defaultHeaders, ...(requestAction.headers || {}) },
      responseType: requestAction.responseType || "",
      params: { ...config.defaultParams, ...(requestAction.params || {}) },
    };

    // FormData Patch Header & Body
    if (
      requestAction.params &&
      requestAction.headers &&
      requestAction.headers["Content-type"] &&
      requestAction.headers["Content-type"] === "multipart/form-data"
    ) {
      request.headers = {};
      request.body = requestAction.params;
    }

    if (config.hooks.onRequest) {
      request = config.hooks.onRequest(store, action, request) || request;
    }

    return request;
  });

  // Requests execution
  action.onStart && goTo(action.onStart, store, next);
  const promiseList = [];
  // eslint-disable-next-line
  requests.map((request) => {
    let url = `${request.base}${request.url}`;
    if (Object.keys(request.params).length > 0) {
      if (request.method === "GET") {
        url +=
          "?" +
          Object.keys(request.params)
            .map((k) => {
              return `${encodeURIComponent(k)}=${encodeURIComponent(request.params[k])}`;
            })
            .join("&");
      } else {
        request.body = Object.keys(request.params)
          .map((k) => {
            return `${encodeURIComponent(k)}=${encodeURIComponent(request.params[k])}`;
          })
          .join("&");
      }
    }

    promiseList.push(
      fetch(url, {
        method: request.method,
        headers: request.headers,
        responseType: request.responseType,
        body: request.body,
      })
        .then((response) => {
          if (action.responseType === "arraybuffer") {
            return response;
          } else {
            return response.json().then((body) => {
              // This allows to have both content and statusCode in response
              return { body: body, status: response.status, statusText: response.statusText };
            });
          }
        })
        .catch((error) => {
          return error;
        })
    );
  });

  // Handling responses
  Promise.all(promiseList).then(async (responses) => {
    let interrupted = false;
    let hadError = false;
    let finalPayload = {};
    let finalMeta = {};
    /*
     * Check if the request action is a sign-in (/oauth/login) request and its return scceeded then do a sleep for "sleepForSync"
       (depends on config, it can be 0 or greater) time to let the backend the time to sync user rights
     */
    if (action?.request?.url === routes.graphQlLogin && responses[0]?.body?.access_token) {
      await new Promise((r) => setTimeout(r, (consts?.sleepForSync || 0) * 1000));
    }
    // eslint-disable-next-line
    responses.map((response, index) => {
      if (action.responseType === "arraybuffer" && response.status === 200) {
        finalPayload = response;
        finalMeta = response.status;
      } else {
        const result = responseHandler(response);
        if (
          (result.meta.requestFailed &&
            config.hooks.onFailure &&
            config.hooks.onFailure(store, action, requestActions[index], response) === false) ||
          (result.error &&
            config.hooks.onError &&
            config.hooks.onError(store, action, requestActions[index], response) === false)
        ) {
          interrupted = true;
        }
        if (result.error === true) {
          hadError = true;
        }

        if (!(action.request instanceof Array)) {
          finalPayload = result.payload;
          finalMeta = result.meta;
        } else {
          const name = requestActions[index].name || "request_" + index;
          finalPayload[name] = result.payload;
          finalMeta[name] = result.meta;
        }
      }
      if (config.hooks.onComplete) {
        config.hooks.onComplete(store, action, requestActions[index], response);
      }
    });

    if (!interrupted) {
      if (!hadError && action.onSuccess) {
        goTo(action.onSuccess, store, next, finalPayload, finalMeta);
      }
      if (hadError && action.onError) {
        goTo(action.onError, store, next, finalPayload, finalMeta);
      }
    }
  });
};

export default function (customConfig) {
  config = { ...config, ...customConfig };
  return requestsMiddleware;
}
