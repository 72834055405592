import { setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import AutoClassification from "components/AutoClassificationImg";
import PadlockLock from "components/Icons/PadlockLock";
import PlayIcon from "components/Icons/Play";
import PlayLock from "components/Icons/PlayLock";
import PlayUnlock from "components/Icons/PlayUnlock";
import Image, { PlaceholderType } from "components/Image";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import Rating from "components/Rating";
import consts from "consts/consts";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoModels, TucanoSelectors } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class HeaderAsset extends Component {
  constructor(props) {
    super(props);
    this.state = { metaItem: null };
  }

  async componentDidMount() {
    if (consts.displayVodData) {
      const { item } = this.props;
      let metadata;
      if (item instanceof TucanoModels.Asset && item.getVodData()) {
        metadata = item.getVodData();
      }
      await this.setState({ metaItem: metadata });
    }
  }
  subscriptionModal(asset) {
    const { dispatch, accountStatus } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  hidePosterOnAssetPage() {
    const { isPhoneSize, item } = this.props;

    if (consts.hidePosterOnAssetPage === true) return true;
    if (isPhoneSize) return true;
    if (item instanceof TucanoModels.Asset) {
      if (item.isCatchup()) return true;
    }
    if (item instanceof TucanoModels.EPG) return true;
    return false;
  }

  renderMoralityLevel() {
    const { item } = this.props;
    if (item.getAutoClassification) {
      return (
        <>
          {" | "}
          <AutoClassification morality={item.getAutoClassification} />
        </>
      );
    } else if (item.getMoralityLevel && !!item.getMoralityLevel()) {
      return (
        <span className={style.moralityLevel}>
          {Number.isInteger(Number(item.getMoralityLevel())) &&
          Number(item.getMoralityLevel()) > 0 ? (
            <span className={style.moralityLevel}>
              | {item.getMoralityLevel()}+
            </span>
          ) : (
            <span className={style.moralityLevel}>
              | {item.getMoralityLevel()}
            </span>
          )}
        </span>
      );
    }
  }

  render() {
    const {
      thumbnailSize,
      item,
      activeAssets,
      seasons,
      isConnected,
      link,
      t,
      accountStatus,
    } = this.props;
    const { metaItem } = this.state;

    let validDay = "";
    if (item && item.isCatchupAsset) {
      let now = new Date();
      let assetDate = new Date(item.validityStartDate * 1000);
      let days = Math.floor(
        (Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) -
          Date.UTC(
            assetDate.getFullYear(),
            assetDate.getMonth(),
            assetDate.getDate()
          )) /
          (1000 * 60 * 60 * 24)
      );
      validDay = days >= 1 ? "-" + days + t("D") : t("Today");
    }

    let TVShowLastWatching = undefined;
    if (item instanceof TucanoModels.TVShow) {
      if (activeAssets && activeAssets.length > 0) {
        const id = item.id;
        activeAssets.forEach((item) => {
          if (
            item.getParentId() &&
            item.isTVShow() &&
            id === parseInt(item.getParentId())
          ) {
            TVShowLastWatching = item;
          }
        });
      }
      if (TVShowLastWatching === undefined) {
        if (seasons && seasons.length > 0) {
          const assets = seasons[0].getAssets();
          if (assets && assets.length > 0) {
            TVShowLastWatching = assets[0];
          }
        }
      }
    }
    const logoWidth = 50;
    const logoHeight = 50;
    /*let catalog;
    if (item instanceof TucanoModels.TVShow) {
      catalog = item.getCatalogue();
    }
    if (item.catalogs) {
      catalog = item.catalogs;
    }*/

    if (item instanceof TucanoModels.TVShow) {
      saveInLocal("tvshowId", item.id);
    }
    const direction = getFromLocal("layoutDirection");

    const isLocked =
      (consts.padlocks === true &&
        isConnected === true &&
        (item instanceof TucanoModels.TVShow && TVShowLastWatching
          ? !TVShowLastWatching.userHasAccess
          : !item.userHasAccess)) ||
      (accountStatus && accountStatus.toLowerCase() === "suspended");

    return (
      <div
        className={classnames(style.rootContainer, {
          [style.rootContainerHidePoster]: !this.hidePosterOnAssetPage(),
        })}
      >
        {!this.hidePosterOnAssetPage() ? (
          <div
            className={classnames(style.thumbnail, {
              [style[direction.toUpperCase()]]: true,
            })}
            style={{
              width: thumbnailSize.poster.width,
              height: thumbnailSize.poster.height,
            }}
          >
            <Image
              src={item.getImage(consts.image_hostname, {
                width: 266,
                height: 399,
                languageId: getFromLocal("languageId"),
              })}
              width={thumbnailSize.poster.width}
              height={thumbnailSize.poster.height}
              placeholder={PlaceholderType.PRIMARY}
            />
            {item instanceof TucanoModels.Asset && (
              <Fragment>
                {isLocked ? (
                  <div onClick={this.subscriptionModal.bind(this, item)}>
                    <PlayLock
                      className={classnames(style.playIcon, style.playLockIcon)}
                    />
                    <PlayUnlock
                      className={classnames(
                        style.playIcon,
                        style.playUnlockIcon
                      )}
                    />
                    <div className={style.padlockContainer}>
                      <div className={style.padlockIconContainer}>
                        <PadlockLock className={style.padlockLockIcon} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link
                    className={style.playIconContainer}
                    to={consts.routes.player.url.replace(
                      ":assetId",
                      item.getId()
                    )}
                  >
                    <PlayIcon className={style.playIcon} />
                    {item.getSeekTime() > 0 ? (
                      <Fragment>
                        <p className={style.label}>
                          <Trans>Continue Watching</Trans>
                        </p>
                        <ProgressBar
                          position={item.getProgress()}
                          rootClassName={style.progressBar}
                        />
                      </Fragment>
                    ) : (
                      <p className={style.label}>
                        <Trans>Watch now</Trans>
                      </p>
                    )}
                  </Link>
                )}
              </Fragment>
            )}
            {item instanceof TucanoModels.EPG && item.isLive() && (
              <Fragment>
                {isLocked ? (
                  <div onClick={this.subscriptionModal.bind(this, item)}>
                    <PlayLock
                      className={classnames(style.playIcon, style.playLockIcon)}
                    />
                    <PlayUnlock
                      className={classnames(
                        style.playIcon,
                        style.playUnlockIcon
                      )}
                    />
                    <div className={style.padlockContainer}>
                      <div className={style.padlockIconContainer}>
                        <PadlockLock className={style.padlockLockIcon} />
                      </div>
                    </div>
                    {item.isLive() && (
                      <ProgressBar
                        position={item.getProgress()}
                        rootClassName={style.progressBar}
                      />
                    )}
                  </div>
                ) : (
                  <Link
                    to={consts.routes.playerlive.url.replace(
                      ":channelId",
                      item.getChannelId()
                    )}
                    className={style.playIconContainer}
                  >
                    {(item.isLive() ||
                      item.getEndTime().getTime() < new Date().getTime()) && (
                      <Fragment>
                        <PlayIcon className={style.playIcon} />
                        {item.isLive() && (
                          <ProgressBar
                            position={item.getProgress()}
                            rootClassName={style.progressBar}
                          />
                        )}
                      </Fragment>
                    )}
                  </Link>
                )}
              </Fragment>
            )}
            {item instanceof TucanoModels.EPG &&
              item.getRecordStatus() === "Recorded" &&
              accountStatus &&
              accountStatus?.toLowerCase() !== "suspended" && (
                <Link
                  to={
                    consts.routes.player.url.replace(
                      ":assetId",
                      item?.getRecord()?.getIdRecord()
                    ) + "?source_type=npvr"
                  }
                  className={style.playIconContainer}
                >
                  <PlayIcon className={style.playIcon} />
                </Link>
              )}
          </div>
        ) : (
          <Fragment>
            {item instanceof TucanoModels.Asset && item.getSeekTime() > 0 && (
              <div className={style.hidePosterProgressContainer}>
                {isLocked ? (
                  <div
                    className={style.link}
                    onClick={this.subscriptionModal.bind(this, true)}
                  >
                    <PlayLock
                      className={classnames(
                        style.playIconNoPoster,
                        style.playLockIcon
                      )}
                    />
                    <PlayUnlock
                      className={classnames(
                        style.playIconNoPoster,
                        style.playUnlockIcon
                      )}
                    />
                  </div>
                ) : (
                  <Link className={style.link} to={link}>
                    <PlayIcon className={style.playIconNoPoster} />
                  </Link>
                )}
                <div
                  className={classnames(
                    style.hidePosterProgressLabelContainer,
                    {
                      [style.hidePosterProgressLabelContainerRTL]:
                        direction.toLocaleLowerCase() === "rtl",
                    }
                  )}
                >
                  <p className={style.hidePosterProgressLabel}>
                    <Trans>Continue Watching</Trans>
                  </p>
                  <ProgressBar
                    position={item.getProgress()}
                    rootClassName={classnames(
                      style.progressBar,
                      style.hidePosterProgress
                    )}
                  />
                </div>
              </div>
            )}
            {/* {item instanceof TucanoModels.TVShow && TVShowLastWatching && (
              <Fragment>
                {TVShowLastWatching.getId() && (
                  <div className={style.hidePosterProgressContainer}>
                    {consts.padlocks === true && isConnected === true && !TVShowLastWatching.userHasAccess ? (
                      <div className={style.link} onClick={this.subscriptionModal.bind(this, true)}>
                        <PlayLock className={classnames(style.playIconNoPoster, style.lockIconForSerie)} />
                        <PlayUnlock className={classnames(style.playIconNoPoster, style.unlockIconForSerie)} />
                      </div>
                    ) : (
                      <Link
                        className={style.link}
                        to={consts.routes.player.url.replace(":assetId", TVShowLastWatching.getId())}
                      >
                        <PlayIcon className={style.playIconNoPoster} />
                        <div className={style.hidePosterProgressLabelContainer}>
                          <p className={style.hidePosterProgressLabel}>
                            S{TVShowLastWatching.getSeasonNumber()} - EP{TVShowLastWatching.getEpisodeNumber()}
                          </p>
                          <ProgressBar
                            position={!isNaN(TVShowLastWatching.getProgress()) ? TVShowLastWatching.getProgress() : 0}
                            rootClassName={classnames(style.progressBar, style.hidePosterProgress)}
                          />
                        </div>
                      </Link>
                    )}
                  </div>
                )}
              </Fragment>
            )} */}
            {item instanceof TucanoModels.Asset &&
              item.getSeekTime() === undefined && (
                <div
                  className={classnames(style.hideWatchNowContainer, {
                    [style.hideWatchNowContainerRTL]:
                      direction.toLocaleLowerCase() === "rtl",
                  })}
                >
                  {isLocked ? (
                    <div
                      className={style.link}
                      onClick={this.subscriptionModal.bind(this, true)}
                    >
                      <PlayLock
                        className={classnames(
                          style.playIconNoPoster,
                          style.playLockIcon
                        )}
                      />
                      <PlayUnlock
                        className={classnames(
                          style.playIconNoPoster,
                          style.playUnlockIcon
                        )}
                      />
                    </div>
                  ) : (
                    <Link className={style.link} to={link}>
                      <PlayIcon className={style.playIconNoPoster} />
                      <span
                        className={classnames(style.watchNow, {
                          [style.watchNowRTL]:
                            direction.toLocaleLowerCase() === "rtl",
                        })}
                      >
                        <Trans>Watch now</Trans>
                      </span>
                    </Link>
                  )}
                </div>
              )}
            {item instanceof TucanoModels.EPG && item.isLive() && (
              <div className={style.hidePosterProgressContainer}>
                {isLocked ? (
                  <div
                    className={style.link}
                    onClick={this.subscriptionModal.bind(this, item)}
                  >
                    <PlayLock
                      className={classnames(
                        style.playIconNoPoster,
                        style.playLockIcon
                      )}
                    />
                    <PlayUnlock
                      className={classnames(
                        style.playIconNoPoster,
                        style.playUnlockIcon
                      )}
                    />
                    {item.isLive() && (
                      <ProgressBar
                        position={item.getProgress()}
                        rootClassName={style.progressBarWithLock}
                      />
                    )}
                  </div>
                ) : (
                  <Link
                    to={consts.routes.playerlive.url.replace(
                      ":channelId",
                      item.getChannelId()
                    )}
                    className={style.playIconContainer}
                  >
                    {(item.isLive() ||
                      item.getEndTime().getTime() < new Date().getTime()) && (
                      <div className={style.link}>
                        <PlayIcon className={style.playIconNoPoster} />
                        {item.isLive() && (
                          <div
                            className={classnames(
                              style.hidePosterProgressLabelContainer,
                              {
                                [style.hidePosterProgressLabelContainerRTL]:
                                  direction.toLocaleLowerCase() === "rtl",
                              }
                            )}
                          >
                            <p className={style.hidePosterProgressLabel}>
                              <Trans>Watch now</Trans>
                            </p>
                            <ProgressBar
                              position={item.getProgress()}
                              rootClassName={classnames(
                                style.progressBar,
                                style.hidePosterProgress
                              )}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Link>
                )}
              </div>
            )}
            {item instanceof TucanoModels.EPG &&
              item.getRecordStatus() === "Recorded" &&
              accountStatus &&
              accountStatus?.toLowerCase() !== "suspended" && (
                <div
                  className={classnames(style.hideWatchNowContainer, {
                    [style.hideWatchNowContainerRTL]:
                      direction.toLocaleLowerCase() === "rtl",
                  })}
                >
                  <Link
                    className={style.link}
                    to={
                      consts.routes.player.url.replace(
                        ":assetId",
                        item?.getRecord()?.getIdRecord()
                      ) + "?source_type=npvr"
                    }
                  >
                    <PlayIcon className={style.playIconNoPoster} />
                    <span
                      className={classnames(style.watchNow, {
                        [style.watchNowRTL]:
                          direction.toLocaleLowerCase() === "rtl",
                      })}
                    >
                      <Trans>Watch now</Trans>
                    </span>
                  </Link>
                </div>
              )}
          </Fragment>
        )}
        <div className={style.metadata}>
          {item instanceof TucanoModels.TVShow && TVShowLastWatching && (
            <Fragment>
              {TVShowLastWatching.getId() && (
                <div className={style.currentEpContainer}>
                  {isLocked ? (
                    <div
                      className={style.link}
                      onClick={this.subscriptionModal.bind(this, true)}
                    >
                      <PlayLock
                        className={classnames(
                          style.playIconCover,
                          style.lockIconForSerie
                        )}
                      />
                      <PlayUnlock
                        className={classnames(
                          style.playIconCover,
                          style.unlockIconForSerie
                        )}
                      />
                    </div>
                  ) : (
                    <>
                      <Link
                        className={style.link}
                        to={consts.routes.player.url.replace(
                          ":assetId",
                          TVShowLastWatching.getId()
                        )}
                      >
                        <PlayIcon className={style.playIconCover} />
                      </Link>
                      <div className={style.CurrentEpisodeInfo}>
                        <p>
                          {isNaN(TVShowLastWatching.getProgress()) ? (
                            <Trans>Watch now</Trans>
                          ) : (
                            `${t(
                              "S"
                            )}${TVShowLastWatching.getSeasonNumber()} - ${t(
                              "EP"
                            )}${TVShowLastWatching.getEpisodeNumber()}`
                          )}
                        </p>
                        <ProgressBar
                          position={
                            !isNaN(TVShowLastWatching.getProgress())
                              ? TVShowLastWatching.getProgress()
                              : 0
                          }
                          rootClassName={style.progressBar}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </Fragment>
          )}

          <div className={style.title}>{this.props.title}</div>
          <div className={style.subtitle}>
            {this.props.subtitle} {this.renderMoralityLevel()}
          </div>
          {item &&
            item.flyImage &&
            (metaItem ||
              item instanceof TucanoModels.TVShow ||
              item instanceof TucanoModels.Asset) && (
              <div className={classnames(style.VODLogo)}>
                <LogoVod
                  className={classnames(style.vodData)}
                  type={item.flyImage}
                  size={40}
                />
              </div>
            )}

          {item.isCatchupAsset && (
            <div className={style.broadcastInfo}>
              {!isNaN(item.getProviderId()) && (
                <Image
                  rootClassName={style.channel}
                  height={logoHeight}
                  width={logoWidth}
                  src={`${consts.image_hostname}${routes.imgdata}?type=${
                    consts.imageTypes.tvChannel
                  }&objectId=${item.getProviderId()}&format_w=80&format_h=80&languageId=${getFromLocal(
                    "languageId"
                  )}`}
                  placeholder={PlaceholderType.TRANSPARENT}
                />
              )}
              <span className={style.date}>
                <Trans>Broadcasted</Trans>{" "}
                {`${moment(item.getValidityStartDate(), "DD/MM/YYYY").format(
                  consts.broadcastDateFormat
                )}`}
                {" | "}
                {`${moment(
                  new Date(item.validityStartDate * 1000),
                  "DD/MM/YYYY HH:mm"
                ).format(consts.broadcastTimeFormat)}`}
                {" | "}
              </span>
              <span className={style.validDay}>{validDay}</span>
            </div>
          )}
          {item instanceof TucanoModels.EPG && (
            <Fragment>
              <div className={style.channelDate}>
                <Image
                  rootClassName={style.channel}
                  height={logoHeight}
                  width={logoWidth}
                  src={`${consts.image_hostname}${routes.imgdata}?type=${
                    consts.imageTypes.tvChannel
                  }&objectId=${item.getChannelId()}&format_w=80&format_h=80&languageId=${getFromLocal(
                    "languageId"
                  )}`}
                  placeholder={PlaceholderType.TRANSPARENT}
                />
                <p className={style.date}>{item.getBroadcastTime()}</p>
              </div>
            </Fragment>
          )}
          {this.props.onRatingClick && (
            <Rating
              rootClassName={style.rating}
              asset={item}
              onStarClick={this.props.onRatingClick.bind(this)}
            />
          )}
          <div className={style.buttons}>{this.props.buttons}</div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect((state, props) => {
    const tvShowId = props.item.id;
    const getActiveAssetsParam = { seekTimeFilter: false };
    return {
      accountStatus: state.account.user?.data?.status,
      isConnected: state.session.customerAuthToken !== undefined,
      seasons: TucanoSelectors.getTvShowSeasons(state, tvShowId),
      activeAssets: TucanoSelectors.getActiveAssets(
        state,
        getActiveAssetsParam
      ),
    };
  }),
  withTranslation()
)(HeaderAsset);
